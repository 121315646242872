import React, { useContext, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import {Button ,Avatar, List, ListItem, ListItemAvatar, ListItemText, DialogTitle, Dialog, Typography} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ImagesDBDataContext from '../Home/PaintMyWallDBData.context';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const shapes = ['wall', 'object', 'window', 'painting', 'door', 'TV', 'AC', 'plant', 'bed' , 'couch', 'others']
const useStyles = makeStyles((theme: Theme) => ({

  }));

function RenameShape( props : {wallId: string, objectId: string, open : boolean, onClose : () => void, shapeName :string}) {
    const classes = useStyles();
    const imageContext = useContext(ImagesDBDataContext)
    const { wallId, objectId, open , onClose, shapeName } = props;
    const [selectedShape, setSelectedShape] = useState<string>(shapeName)
  
    const handleClose = () => {
      onClose();
    };
  
    const handleListItemClick = (value: string) => {
        console.log('value', value)
        let imageToUpdate = imageContext.images.map((image) => {
            if(image.url === imageContext.selectedImage){
                if(image.data && image.data.wall && image.data.wall.length > 0){
                    image.data.wall.map(wall => {
                        if(wall.id === wallId){
                            if(wall.imageAnnotations && wall.imageAnnotations.length > 0){
                                wall.imageAnnotations.map(annotation => {
                                    if(annotation.id === objectId){
                                        annotation.displayName = value
                                    }
                                    return annotation
                                })
                            }
                        }
                        return wall
                    }) 
                }
            }
            return image
        })
        console.log('imagetoupate : ', imageToUpdate)
        imageContext.setImages(imageToUpdate)
      onClose();
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} style={{padding : '10px'}}>
        <DialogTitle id="simple-dialog-title">Choose from the options to rename</DialogTitle>
        <div style={{display : 'flex', justifyContent : 'space-between', padding : '0px 20px'}}>{shapeName} <ExpandMoreIcon/></div>
        <hr style={{color : 'black', border: 'solid 1px #00000033', margin : '5px 15px'}}/>
        <List style={{height : '200px', overflow : 'auto', margin : '0px 15px'}}>
          {shapes.map((shape) => (
            <ListItem button onClick={() => setSelectedShape(shape)} key={shape} selected={shape === selectedShape}>
              <ListItemText primary={shape} />
            </ListItem>
          ))}
        </List>
        <Button variant='contained' color='primary' style={{margin : '5px 20px'}} onClick={() => handleListItemClick(selectedShape)}>Rename </Button>
      </Dialog>
    );
  }
  

export default RenameShape