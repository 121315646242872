import React, { useContext, useEffect, useRef, useState } from 'react'
import ImagesDBDataContext from '../Home/PaintMyWallDBData.context';
import ImageService from '../../../services/image.service';
import { Button, Paper, Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    ImageArea : {
        height : '65%',
        width :'calc(100% - 40px)',
        padding : '20px',
        margin : '0px 20px',
        display: 'flex',
        justifyContent : 'center',
        alignItems : 'center',
    },
    downSection : {
        height : '30%',
        width : '100%',
        position : 'relative'
    },
    paper: {
        height : 'calc(100% - 10px)',
        // filter : 'drop-shadow(4px 4px 4px black)',
        borderRadius : '30px 30px 0px 0px',
        padding : '10px 2% 0 2%',
        overflow : 'auto',
        position : 'absolute',
        bottom : 0,
        left : 0,
        right : 0,
        width : '100%',
        transition : 'height 1s'
        // height : ''
    },
    buttons : {
        fontSize : '12px',
        width : '50%',
        cursor : 'pointer',
        padding:"4px 0px"
    },
    '@media (min-height: 1000px)':{
      ImageArea : {
        height : '76%',
      }
    }
    
}))

function VisualiseMyFurnitureOptions() {
    const classes = useStyles()
    const imageContext = useContext(ImagesDBDataContext)
    const [ imageWidthToSet , setImageWidthToSet ] = useState<number>(0);
    const [ imageHeightToSet , setImageHeightToSet ] = useState<number>(0);
    const canvasElementRef = useRef<HTMLCanvasElement>();
    const imageContainerRef = useRef<HTMLDivElement>();
    const [windowWidth, setwindowWidth] = useState(0)
    const [ imageWidthAndHeight , setImageWidthAndHeight ] = useState<{width : number , height : number}>({width : 0, height : 0})


    function calculateWidthAndHeight () {
        let base_image = new Image();
        base_image.src = imageContext.selectedImage;
        setwindowWidth(window.innerWidth)
        
        base_image.onload = () => {
            const imageWidth = base_image.width || 0;
          const imageHeight = base_image.height || 0;
    
          const imageAspectRatio = imageWidth / imageHeight;
          setImageWidthAndHeight({width: imageWidth, height: imageHeight})

          let imageWidthToSet: number = 0;
          let imageHeightToSet: number = 0;
          const imageMaxWidthAllowed = imageContainerRef.current?.offsetWidth || 0;
          // 0.81*(window.innerWidth - (window.innerWidth > 900 ?  200 : 50));
          const imageMaxHeightAllowed = (imageContainerRef.current?.offsetHeight && imageContainerRef.current?.offsetHeight ) || 0;
          // 0.81*(window.innerHeight - (window.innerWidth > 900 ? 300 : 150));
          console.log("max width allowed and max height allowed", imageMaxWidthAllowed, imageMaxHeightAllowed, imageWidth, imageHeight);
    
          if (imageWidth >= imageHeight) {
            imageWidthToSet = imageMaxWidthAllowed 
            imageHeightToSet = imageWidthToSet / imageAspectRatio;
            imageHeightToSet = ImageService.getImageHeight(
              imageWidthToSet,
              imageAspectRatio
            );
    
            if (imageHeightToSet > imageMaxHeightAllowed) {
              imageHeightToSet = imageMaxHeightAllowed;
              imageWidthToSet = ImageService.getImageWidth(
                imageHeightToSet,
                imageAspectRatio
              );
            }
          } else if (imageHeight > imageWidth) {
            imageHeightToSet = imageMaxHeightAllowed;
            imageWidthToSet = imageAspectRatio * imageHeightToSet;
            imageWidthToSet = ImageService.getImageWidth(
              imageHeightToSet,
              imageAspectRatio
            );
    
            if (imageWidthToSet > imageMaxHeightAllowed) {
              imageWidthToSet = imageMaxWidthAllowed; 
              imageHeightToSet = ImageService.getImageHeight(
                imageWidthToSet,
                imageAspectRatio
              );
            }
          }
          setImageWidthToSet(imageWidthToSet)
          setImageHeightToSet(imageHeightToSet)
        }
    }
    // executeFunction()
    // },[selectedImageUrl])

    const calculateWidthAndHeightRef = useRef(calculateWidthAndHeight);
    calculateWidthAndHeightRef.current = calculateWidthAndHeight;
    
    useEffect(() => {
        calculateWidthAndHeightRef.current()
    },[imageContext.selectedImage])

    useEffect(() => {
        window.addEventListener('resize', calculateWidthAndHeightRef.current, false)
    },[])

 
  return (
    <div style={{height : '100%', width : '100%',display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
        <div 
        //@ts-ignore
        ref={imageContainerRef}
        className={classes.ImageArea}>
        <img 
                src={imageContext.selectedImage}
                alt="wall"
                // className={classes.Image}
                crossOrigin="anonymous"
                style={{width :imageWidthToSet ,height : imageHeightToSet,
                    borderRadius : '10px',}} />
        </div>
        <div className={classes.downSection} style={{height : '200px', transition : 'height 2s',borderRadius : '30px 30px 0px 0px', borderTop:"1px solid #C8D2F7",boxShadow: "0px -1px 5px #C8D2F7"}}>
            <Paper elevation={0} className={classes.paper} style={{height : '100%', transition : 'height 2s'}}>
                <div style={{height : 'calc(100%)', width : '100%', overflow: 'auto', display : 'flex', flexDirection:"column",justifyContent:"center",gap:"0px", alignItems : 'center'}}>
                <div style={{display:"flex",width :'100%',gap:"2px",textAlign:"center",alignItems:"center",flexDirection:"column"}}>
                  <Button variant='contained' color='primary' className={classes.buttons} style={{color :'#ffffff',padding:"5.5px 0px" ,backgroundColor : '#5675E7'}} onClick={()=>{
                     imageContext.setFurnitureOptions("addingObject");
                     imageContext.setScanario('uploadObject')
                  }}>ADD FURNITURE</Button>
                  <p style={{fontSize:'10px',color:"#a7a7a7"}}>Lets visualise the funiture</p>
                </div>
                <div style={{display:"flex",width :'100%',gap:"2px",textAlign:"center",alignItems:"center",flexDirection:"column"}}>
                    <Button onClick={()=>{
                       imageContext.setFurnitureOptions("brushEraser");
                       imageContext.setObjectImages([]);
                       imageContext.setScanario('reimagination')
                       
                    }}  className={classes.buttons} style={{ fontWeight:"600",fontSize:"12px",color : '#2941B2',}}>

                    REMOVE FURNITURE
                    </Button>
                  <p style={{fontSize:'10px',color:"#a7a7a7"}}>Let AI help you remove the funiture</p>
                </div>   
                </div>
            </Paper>
        </div>
    </div>
  )
}

export default VisualiseMyFurnitureOptions