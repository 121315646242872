import css from "./twoDimensionalImage.module.css"
import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import { normalize, denormalize, schema } from 'normalizr';
import {
	Button,
	ButtonGroup,
} from 'reactstrap';
// import 'bootstrap/dist/css/bootstrap.css';
import RedoIcon from '@material-ui/icons/Redo';
import UndoIcon from '@material-ui/icons/Undo';
import AddIcon from '@material-ui/icons/Add';
import '../../../../../../../../assets/css/twoDimensionalImageBoostrap.css';
import './twoDimensionalImage.scss';
import { MdAdd, MdAutorenew } from 'react-icons/md';
import { FaCommentAlt } from 'react-icons/fa';
import { UndoRedo } from '../../../../models/UndoRedo';
import { highContrastingColors as colors } from '../../../../shared/utils/colorUtils.js';
import { getRandomInt } from '../../../../shared/utils/mathUtils.js';
import { Polygon } from '../../models/polygon';
import { Vertex } from '../../models/vertex';
import CheckIcon from '@material-ui/icons/Check';
import { getUniqueKey } from '../../utils/utils';
import MagnifierDropdown from '../MagnifierDropdown/MagnifierDropdown.jsx';
import TwoDimensionalImageContext from './twoDimensionalImageContext';
import AnnotationList from '../AnnotationList/AnnotationList.jsx';
import UndoRedoButton from '../UndoRedoButton/UndoRedoButton.jsx';
import Canvas, { annotationcanvasId } from '../Canvas/Canvas.jsx';
import i18nextInstance from './i18n';
import InfoIcon from '@material-ui/icons/Info';
import { generateUniqueId } from '../../../../../../../../services/idHelperService';
import { removeDuplicateElementsFromArrayByElementId } from '../../../../../../../../services/arrayHelperService';
import { setImageAnnotationsInAnnotationComponentForSavingInDBIfModelTypeImageAnnotation, setImageGroupAnnotationsInAnnotationComponentForSavingInDBIfModelTypeImageGroupAnnotation } from '../../../../../../../../services/imageAnnotationHelperService';
import { copyByValue } from '../../../../../../../../services/variableHelperService';
import { ClickAwayListener, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { themePrimaryMainColor } from '../../../../../../../../services/themeService';
// import { prototype } from 'events';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {Button as MuiButton} from '@material-ui/core';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Chip, CircularProgress, Paper, Table, TableBody, TableCell, TableRow, Theme, Typography, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {generateRandomLightColor} from '../../../../../../../../../src/services/UniqueColorPicker'


const SHORTCUTS = {
	MAGNIFIER: {
		'1X': { key: '1', code: 49 },
		'2X': { key: '2', code: 50 },
		'3X': { key: '3', code: 51 },
		'4X': { key: '4', code: 52 },
	},
	BUTTON: {
		ADD: { key: 'c', code: 67 },
		PREVIOUS: { key: 's', code: 83 },
		NEXT: { key: 'd', code: 68 },
		SKIP: { key: 'a', code: 65 },
		RIGHTARROW:{key: 'rightArrow', code: 39 },
		LEFTARROW:{key: 'leftArrow', code: 37 },
		TOGGLE_LABEL: { key: 'shift', code: 16 },
	},
	UNDO_REDO: {
		UNDO: { key: 'z', code: 90 },
		REDO: { key: 'x', code: 88 },
	},
};

const InfoUI = () => {
	const [isTooltipShowing, setIsTooltipShowing] = useState(false);
	return (
		<ClickAwayListener onClickAway={()=>setIsTooltipShowing(false)}>
			<div>
				<Tooltip title="Right click on point for options"
					open={isTooltipShowing}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					PopperProps={{
						disablePortal: true,
					}}		
					onClose={()=>setIsTooltipShowing(false)}				
				>
					{/* <ClickAwayListener
						onClickAway={()=>setIsTooltipShowing(false)}
					> */}
						<div>
							<InfoOutlinedIcon style={{color:'#999999', cursor: "pointer"}} 
								onClick={()=>setIsTooltipShowing(true)}
							/>
						</div>
					{/* </ClickAwayListener> */}
				{/* <div onClick={()=>setIsTooltipShowing(true)}>Right click on point for options</div> */}
				</Tooltip>	
			</div>

		</ClickAwayListener>
	)
}

const CanvasContextMenu = ({
	open=false,
	handleRemovePointClick,
	handleModifyAnnotationFromThisPointClick,
	handleViewAnnotation,
	xCoordinate,
	yCoordinate,
	handleOnClose
}) => {
	return (
		<Menu
		open={open}
		onClose={()=>{handleOnClose()}}
		anchorReference="anchorPosition"
		anchorPosition={
			yCoordinate !== null && xCoordinate !== null
				? { top: yCoordinate, left: xCoordinate }
				: undefined
		}
	>
		{/* <MenuItem onClick={()=>handleViewAnnotation()}>View this annotation</MenuItem> */}
		<MenuItem onClick={()=>handleRemovePointClick()}>Remove point</MenuItem>
		<MenuItem onClick={()=>handleModifyAnnotationFromThisPointClick()}>Modify Annotation from this point</MenuItem>
	</Menu>	
	);
}

class TwoDimensionalImage extends Component {
	constructor(props) {
		super(props);
		const {
			defaultAnnotations,
			defaultAnnotationGroups,
			isLabelOn,
			imageWidth,
			fullScreenMode,
			width
		} = props;


		console.log("width and imageWidth are :: ", width, imageWidth, isLabelOn)
		const entities = { options: {}, annotations: {}, annotationGroups: [] };
		let rootOptionId = '';
		let annotations = [];
		// const imageWidth = fullScreenMode ? window.innerWidth - 100 : imageWidthProp
		// normalize
		if (props.options && Object.keys(props.options).length !== 0) {
			const option = new schema.Entity('options');
			const children = new schema.Array(option);
			option.define({ children });
			const normalizedOptions = normalize(props.options, option);
			entities.options = normalizedOptions.entities.options;
			rootOptionId = normalizedOptions.result;
		} else {
			rootOptionId = '0';
			entities.options['0'] = { id: '0', value: 'root', children: [] };
		}

		if (defaultAnnotations && defaultAnnotations.length !== 0) {
			const annotation = new schema.Entity('annotations');
			const normalizedAnn = normalize(defaultAnnotations, [annotation]);
			entities.annotations = normalizedAnn.entities.annotations;
			annotations = normalizedAnn.result;
			console.log('default annotations are::', defaultAnnotations)
		}

		if (defaultAnnotationGroups && defaultAnnotationGroups.length !== 0) {
			entities.annotationGroups = defaultAnnotationGroups;
			console.log('default annotation groups are::', defaultAnnotationGroups)
		}

		this.state = {
			isAdding: false,
			focusedName: '',
			focusedGroupAnnotationId: "",
			magnifyingPower: 1,
			isLabelOn,
			entities,
			customizedOptionInputFocused: false,
			rootOptionId,
			imageScaleFactor: 1,
			imageHeight: 0,
			imageWidth : width,
			fullScreenMode,
			annotations,
			isVertexBeingRemoved: false,
			canvasContextMenu: {
				isOpen: false,
				xCoordinate: 0,
				yCoordinate: 0,
				event: null				
			},
			stageScale: 1,
			naturalWidth:0,
			naturalHeight:0,
			expandAccordian : false,
			isShowMangnifier:false
		};
		this.UndoRedoState = new UndoRedo();
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this.handleKeydown, false);
	}

	triggerAnnotationsChangedByUserEvent() {
		this.handleSubmit("onAnnotationsChange")
	}

	componentDidUpdate(prevProps, prevState) {
		// Object.entries(this.props).forEach(([key, val]) =>
		// 	prevProps[key] !== val && console.log(`Prop '${key}' changed`)
		// );
		// if (this.state) {
		// 	Object.entries(this.state).forEach(([key, val]) =>
		// 		prevState[key] !== val && console.log(`State '${key}' changed`)
		// 	);
		// }

		if (prevProps.resourceId !== this.props.resourceId) {
			this.setStageScale(1);
		}

		if (prevState.imageWidth !== this.state.imageWidth) {
			console.log("imageScalefactor in twodimensional component , ",this.state.imageWidth / this.state.naturalWidth )
			this.setState({imageScaleFactor: this.state.imageWidth / this.state.naturalWidth})
		}

		if (
			JSON.stringify(prevProps.options || {}) !== JSON.stringify(this.props.options || {}) ||
			JSON.stringify(prevProps.defaultAnnotations || []) !== JSON.stringify(this.props.defaultAnnotations || []) ||
			JSON.stringify(prevProps.defaultAnnotationGroups || []) !== JSON.stringify(this.props.defaultAnnotationGroups || [])
		) {
			this.setLatestAnnotationsFromResource();
		}


		if (prevProps.fullScreenMode !== this.props.fullScreenMode) {
			this.setState({fullScreenMode: this.props.fullScreenMode})
		}

		if (prevProps.imageWidth !== this.props.imageWidth) {
			console.log("prevProps. image width and image widths are : ", prevProps.imageWidth, this.props.imageWidth)
			this.setState({imageWidth: this.props.imageWidth})
		}

		if (prevProps.isLabelOn !== this.props.isLabelOn) {
			this.setState({isLabelOn: this.props.isLabelOn})
		}

		if (prevProps.fullScreenMode !== this.props.fullScreenMode) {
			this.setState({fullScreenMode: this.props.fullScreenMode})
		}

	}

	setLatestAnnotationsFromResource() {
		const {
			defaultAnnotations,
			defaultAnnotationGroups,
			options
		} = this.props;

		const entities = { options: {}, annotations: {}, annotationGroups: [] };
		let rootOptionId = '';
		let annotations = [];

		// normalize
		if (options && Object.keys(options).length !== 0) {
			const option = new schema.Entity('options');
			const children = new schema.Array(option);
			option.define({ children });
			const normalizedOptions = normalize(options, option);
			entities.options = normalizedOptions.entities.options;
			rootOptionId = normalizedOptions.result;
		} else {
			rootOptionId = '0';
			entities.options['0'] = { id: '0', value: 'root', children: [] };
		}	
		
		if (defaultAnnotations && defaultAnnotations.length !== 0) {
			const annotation = new schema.Entity('annotations');
			const normalizedAnn = normalize(defaultAnnotations, [annotation]);
			entities.annotations = normalizedAnn.entities.annotations;
			annotations = normalizedAnn.result;
			console.log('default annotations are::', defaultAnnotations)
		}	
		
		if (defaultAnnotationGroups && defaultAnnotationGroups.length !== 0) {
			entities.annotationGroups = defaultAnnotationGroups;
			console.log('default annotation groups are::', defaultAnnotationGroups)
		}
		
		this.setState({
			entities: {...entities},
			rootOptionId,
			annotations: [...annotations]
		})

		this.UndoRedoState = new UndoRedo();
	}

	componentWillUnmount = () => {

		const canvasElement = document.getElementById(annotationcanvasId);

		if (canvasElement && canvasElement.style?.cursor === "crosshair") {
			canvasElement.style.cursor="default";
		}
		document.removeEventListener('keydown', this.handleKeydown, false);
	}

	componentWillReceiveProps(nextProps) {
		console.log("nextProps are :: ", nextProps)
		const isAddingPrevValue = this.state.isAdding;
		this.setState({ imageWidth: nextProps.width , fullScreenMode: nextProps.fullScreenMode , addNewWall : nextProps.addNewWall}); 
		if(nextProps.addNewWall !== isAddingPrevValue){
			this.handleAddClick()
		}
	  }

	/**
	 * @param {number} scaleToSet 
	 */
	setStageScale = (scaleToSet) => {
		if (scaleToSet !== 1) {
			/**
			 * Resetting magnifier property to default as magnifier doesn't work properly
			 * on zoomed image
			 */
			// this.handleMagnifierChange(1);
		}
		this.setState({stageScale: scaleToSet})
	}

	/* ==================== shortkey ==================== */
	handleKeydown = (e) => {
		const { onPreviousClick, onSkipClick, onNextClick } = this.props;
		const { customizedOptionInputFocused } = this.state;
		if (customizedOptionInputFocused) return;
		// console.log("key down on Two D",e.keyCode);
		const focusedElement = document.activeElement;
		if (
			focusedElement.tagName.toLowerCase() === "textarea" ||
			(focusedElement.tagName.toLowerCase() === "input" &&
			(focusedElement.type.toLowerCase() === "number" || focusedElement.type.toLowerCase() === "text")))
		{
			return;
		}

		switch (e.keyCode) {
		case SHORTCUTS.UNDO_REDO.UNDO.code:
			break;
			this.handleUndoClick();
			break;
		case SHORTCUTS.UNDO_REDO.REDO.code:
			break;
			this.handleRedoClick();
			break;
		case SHORTCUTS.BUTTON.TOGGLE_LABEL.code:
			break;
			this.handleToggleLabel();
			break;
		case SHORTCUTS.BUTTON.ADD.code:
			break;
			this.handleAddClick();
			break;
		case SHORTCUTS.BUTTON.PREVIOUS.code:
			break;
			if (onPreviousClick) this.handleSubmit('Previous');
			break;
		case SHORTCUTS.BUTTON.SKIP.code:
			if (onSkipClick) this.handleSubmit('Skip');
			break;
		case SHORTCUTS.BUTTON.NEXT.code:
			if (onNextClick) this.handleSubmit('Next');
			break;
		case SHORTCUTS.BUTTON.RIGHTARROW.code:
			break;
			if (onNextClick && this.props.scenario==="copilotImageAnnotationPopUp") this.handleSubmit('Next');
			break;
		case SHORTCUTS.BUTTON.LEFTARROW.code:
			break;
			if (onPreviousClick  && this.props.scenario==="copilotImageAnnotationPopUp") this.handleSubmit('Previous');
			break;
		case SHORTCUTS.MAGNIFIER['1X'].code:
			this.handleMagnifierChange(1);
			break;
		case SHORTCUTS.MAGNIFIER['2X'].code:
			this.handleMagnifierChange(2);
			break;
		case SHORTCUTS.MAGNIFIER['3X'].code:
			this.handleMagnifierChange(3);
			break;
		case SHORTCUTS.MAGNIFIER['4X'].code:
			this.handleMagnifierChange(4);
			break;
		default:
		}
	}

	updateCanvasContextMenuState = (canvasContextMenuState) => {
		this.setState(prevState=>{
			return {
				...prevState,
				canvasContextMenu: {
					...prevState.canvasContextMenu,
					...canvasContextMenuState
				}
			}
		})
	}

	/* ==================== control ==================== */
	// handleMagnifierChange = (power) => {
	// 	this.setState({ magnifyingPower: 4 });
	// }

	handleToggleLabel = () => {
		this.setState(prevState => ({ isLabelOn: !prevState.isLabelOn }));
	}

	handleAddClick = () => {
		this.setState(prevState => ({ isAdding: !prevState.isAdding, focusedName: '', focusedGroupAnnotationId: '' }));
	}

	/**
	 * @param {string} annotationGroupId 
	 */
	handleAddImageAnnotationInExistingGroupButtonClick = (annotationGroupId) => {
		this.setState(prevState => ({ isAdding: !prevState.isAdding, focusedName: '', focusedGroupAnnotationId: annotationGroupId }));
	}

	/* ==================== undo/redo ==================== */
	handleUndoClick = () => {
		if (this.UndoRedoState.previous.length === 0) return;
		this.setState((prevState) => {
			const state = this.UndoRedoState.undo(prevState);
			return { ...state };
		});
	}

	handleRedoClick = () => {
		if (this.UndoRedoState.next.length === 0) return;
		this.setState((prevState) => {
			const state = this.UndoRedoState.redo(prevState);
			return { ...state };
		});
	}

	/* ==================== canvas ==================== */
	handleCanvasImgLoad = (e) => {
		const { imageWidth } = this.state;
		const { target } = e;
		console.log(imageWidth, target.naturalWidth, target.naturalHeight, target.height, "imageScalarFactor :: ", imageWidth / target.naturalWidth)
		this.setState({ imageScaleFactor: imageWidth / target.naturalWidth, imageHeight: target.height, naturalWidth: target.naturalWidth, naturalHeight: target.naturalHeight });
	}

	handleCanvasStageMouseDown = (e) => {

		const modelType = this.props.modelType;
		console.log('event triggered is :: ', e, e.target, e.target.touches)
		const stage = e.target.getStage();
		const uniqueKey = generateUniqueId();
		const uniqueAnnotationGroupId = generateUniqueId();
		const color = colors[getRandomInt(colors.length)];
		// let { x, y } = stage.getPointerPosition();
		let { x, y } = stage.getRelativePointerPosition();

		let vertices;
		this.setState((prevState) => {
			const {
				isAdding, focusedName, annotations, entities, imageWidth, imageHeight, focusedGroupAnnotationId, imageScaleFactor
			} = prevState;
			if (!isAdding) return {};
			// prevent x, y exceeding boundary
			x = x < 0 ? 0 : x; x = (x > imageWidth ? imageWidth : x) | 0;
			y = y < 0 ? 0 : y; y = (y > imageHeight ? imageHeight : y) | 0;
			this.UndoRedoState.save(prevState);
			console.log('state variables are :: ', isAdding, ' : ' , focusedName, ' : ' ,annotations, ' : ' ,entities, ' : ' ,imageWidth, ' : ' ,imageHeight, ' : ' ,focusedGroupAnnotationId)
			// first time adding
			if (!focusedName ) {
				vertices = [];
				vertices.push(Vertex({
					id: `${uniqueKey}`, name: `${uniqueKey}`, x, y,
				}));
				entities.annotations[`${uniqueKey}`] = Polygon({
					id: `${uniqueKey}`, name: `${uniqueKey}`, color, vertices,
				});
				if (modelType === 'imageAnnotationGroup') {
					entities.annotations[`${uniqueKey}`].groupAnnotationId = focusedGroupAnnotationId || uniqueAnnotationGroupId;
					console.log("entities are  :: ",entities)
					entities.annotations[`${uniqueKey}`].displayName =  `New Shape ${entities.annotations.length}`
				}
				// const entitiesTesting = removeDuplicateElementsFromArrayByElementId(entities.annotations[`${uniqueKey}`].vertices)
				// console.log('entitiesTesting ',entitiesTesting, entities.annotations)
				return {
					focusedName: `${uniqueKey}`,
					focusedGroupAnnotationId: 
						modelType === 'imageAnnotationGroup'
							?	focusedGroupAnnotationId || uniqueAnnotationGroupId
							: "",
					annotations: [...annotations, `${uniqueKey}`],
					entities: { 
						...entities, 
						annotations: entities.annotations,
						annotationGroups: 
							modelType === 'imageAnnotationGroup'
							?	removeDuplicateElementsFromArrayByElementId([...entities.annotationGroups, {id: focusedGroupAnnotationId || uniqueAnnotationGroupId, name: "Wall", choords : {xChord: x / imageScaleFactor , yChord :  y / imageScaleFactor, choordColorSelected : generateRandomLightColor()}}])
							:	""
					},
				};
			}
			
			const allVertices = entities.annotations[focusedName].vertices.map(vertex => vertex.id);
			const allUnique = arr => arr.length === new Set(arr).size;
			console.log('allVertices',allVertices.push(uniqueKey))
			if(allUnique(allVertices)){
				// continuing adding
				entities.annotations[focusedName].vertices.push(Vertex({
					id: `${uniqueKey}`, name: `${uniqueKey}`, x, y,
				}));
				return { entities: { ...entities, annotations: entities.annotations } };
			}
			else{
				return{}
			}
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	handleCanvasVertexRemove = (e) => {
		/**
		 * This function will remove the activeVertex
		 */

		if (this.props.isViewOnlyMode) {
			return;
		}

		const activeVertex = e.target;
		const group = activeVertex.getParent();
		this.setState((prevState) => {
			const { focusedName, entities } = prevState;
				const { annotations } = entities;
				if (group.name() === focusedName) {
					for (let vertexIndex = 0; vertexIndex < annotations[focusedName].vertices.length; vertexIndex++) {
						const vertex = annotations[focusedName].vertices[vertexIndex];
						if (
							vertex.x === activeVertex.attrs.x &&
							vertex.y === activeVertex.attrs.y
						) {
							annotations[focusedName].vertices.splice(vertexIndex, 1)
							if (annotations[focusedName].vertices.length === 2) {
								annotations[focusedName].isClosed = true;
							}
							break;
						}
					}
				}
				return {
					...prevState,
					entities: {
						...prevState.entities,
						annotations
					}
				}
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}
	handleCanvasVertexExtendInAnnotation = async (e) => {
		// if (this.props.isViewOnlyMode) {
		// 	return;
		// }

		const activeVertex = e.target;
		const group = activeVertex.getParent();

		if (!group) {
			return;
		}

		this.setState((prevState) => {
			const { focusedName, entities } = prevState;
				const { annotations } = entities;

			if (group.name() === focusedName) {
				/**
				 * making annotation a line if its a polygon
				 */
				entities.annotations[focusedName].isClosed = true
			}	
			
			for (let vertexIndex = 0; vertexIndex < annotations[focusedName].vertices.length; vertexIndex++) {
				const vertexWhichShouldGoToEnd = copyByValue(annotations[focusedName].vertices[vertexIndex]);
				if (
					vertexWhichShouldGoToEnd.x === activeVertex.attrs.x &&
					vertexWhichShouldGoToEnd.y === activeVertex.attrs.y
				) {

					/**
					 * making vertex which user selected to become the last vertex in vertices array and shifting all other vertices accordingly
					 * for eg: if array elements are ['a','b','c','d'] and user selected
					 * 'c' then array elements order after shifting will be: ['d','a','b','c']
					 */
					annotations[focusedName].vertices = annotations[focusedName].vertices.concat(annotations[focusedName].vertices.splice(0,vertexIndex + 1));


					/**
					 * as per library the first vertex id and name should be equal to annotation id.
					 * therefore making changes below for that
					 */
					for (const vertex of annotations[focusedName].vertices) {
						if (vertex.id === annotations[focusedName].id) {
							vertex.id = copyByValue(annotations[focusedName].vertices[0].id);
							vertex.name = copyByValue(annotations[focusedName].vertices[0].name);
							break;
						}
					}
					annotations[focusedName].vertices[0].id = copyByValue(annotations[focusedName].id);
					annotations[focusedName].vertices[0].name = copyByValue(annotations[focusedName].id);

					break;
				}
			}			

			return {
				...prevState,
				isAdding: true,
				entities: {
					...entities
				}
			}
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});		
	}
	handleViewAnnotation = async (e) => {

		/**
		 * This function is used to scroll to particular annotation in annotation list
		 */

		if (this.props.isViewOnlyMode) {
			return;
		}

		const activeVertex = e.target;
		const group = activeVertex.getParent();

		if (!group || !group.name()) {
			return;
		}

		/** @type {string} */
		const groupName = group.name();

		/**
		 * waiting for few seconds to get view updated before we perform manual scroll
		 */
		await new Promise((resolve, reject)=>{
			setTimeout(() => {
				resolve();
			}, 500);
		})

		/** @type {HTMLElement} */
		const annotationHtmlElement = document.getElementsByName(`${groupName}`)[0];

		if (!annotationHtmlElement) {
			return;
		}

		const annotationListElement = document.getElementById("annotation-list");
		if (!annotationListElement) {
			return;
		}

		/**
		 * performing calculations to get better view of annotation to which user wants to scroll
		 */
		annotationListElement.scrollTop = 
			(annotationHtmlElement.offsetTop - 96) > annotationListElement.scrollTop
			? annotationHtmlElement.offsetTop - 10
			: annotationHtmlElement.offsetTop - 50

	}
     
	toggleShowMagnifier=(value)=>{
       this.setState({isShowMangnifier:value})
	}

	handleCanvasVertexMouseDown = (e) => {
		const activeVertex = e.target;
		const group = activeVertex.getParent();
		this.setState((prevState) => {
			const { isAdding, focusedName, entities } = prevState;
			if (isAdding) {
				const { annotations } = entities;
				if (group.name() === focusedName && annotations[focusedName].vertices[0].name === activeVertex.name()) {
					annotations[focusedName].isClosed = true;
					return { isAdding: false, entities: { ...entities, annotations } };
				}
				return {};
			}
			return { focusedName: group.name() };
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	handleCanvasVertexDragEnd = (e) => {
		const activeVertex = e.target;
		const group = activeVertex.getParent();
		if (!group || !group.name()) {
			return;
		}
		this.setState((prevState) => {
			const {
				isAdding, entities, imageWidth, imageHeight,
			} = prevState;
			if (isAdding) return {};
			const { annotations } = entities;
			// const annotationId = Object.keys(entities.annotations)[0]
			// const vertices = entities.annotations[annotationId].vertices.map((v) => {
			const vertices = annotations[group.name()].vertices.map((v) => {
				if (v.name !== activeVertex.name()) return v;
				// prevent x, y exceeding boundary
				let x = activeVertex.x(); let y = activeVertex.y();
				x = x < 0 ? 0 : x; x = x > imageWidth ? imageWidth : x;
				y = y < 0 ? 0 : y; y = y > imageHeight ? imageHeight : y;
				return { ...v, x, y };
			});
			// entities.annotations[annotationId].vertices = vertices;
			annotations[group.name()].vertices = vertices;
			return { entities: { ...entities, annotations },isShowMangnifier:false };
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}


	updateVertexPosition=(e)=>{
		this.setState({isShowMangnifier:true})
		const activeVertex = e.target;
		const group = activeVertex.getParent();
       
		if (!group || !group.name()) {
			return;
		}
		this.setState((prevState) => {
			const {
				isAdding, entities, imageWidth, imageHeight,
			} = prevState;
			if (isAdding) return {};
			const { annotations } = entities;
			// const annotationId = Object.keys(entities.annotations)[0]
			// const vertices = entities.annotations[annotationId].vertices.map((v) => {
			const vertices = annotations[group.name()].vertices.map((v) => {
				if (v.name !== activeVertex.name()) return v;
				// prevent x, y exceeding boundary
				let x = activeVertex.x(); let y = activeVertex.y();
				x = x < 0 ? 0 : x; x = x > imageWidth ? imageWidth : x;
				y = y < 0 ? 0 : y; y = y > imageHeight ? imageHeight : y;
				return { ...v, x, y };
			});
			// entities.annotations[annotationId].vertices = vertices;
			annotations[group.name()].vertices = vertices;
			return { entities: { ...entities, annotations }};
		})
	}

	handleCanvasFocusing = (e) => {
		const activeShape = e.target;
		this.setState((prevState) => {
			if (prevState.isAdding) return {};
			return { focusedName: activeShape.name() };
		});
	}

	/* ==================== anootation list ==================== */
	handleAnnotationClick = (name) => { 
		if (this.state.focusedName === name) {
			this.setState({ focusedName: "" }); 
		} else {
			this.setState({ focusedName: name }); 
		}
	};

	handleAnnotationDeleteClick = (name, groupAnnotationId) => {
		this.setState((prevState) => {
			const { entities } = prevState;
			const { annotations } = entities;
			// const newAnnotation = prevState.annotations.filter(annotation => 
			// 	annotation.name !== name && annotation.groupAnnotationId !== groupAnnotationId
			// )
			delete annotations[name];
			const i = prevState.annotations.indexOf(name);
			if(i>=0){
				prevState.annotations.splice(i, 1);
			}
			return { annotations: prevState.annotations, entities: { ...entities, annotations } };
			// return { annotations: newAnnotation, entities: { ...entities, newAnnotation } };
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	/**
	 * @param {string} annotationGroupIdToDelete 
	 */
	handleAnnotationGroupDeleteClick = (annotationGroupIdToDelete) => {
		const {annotations, annotationGroups} = this.state.entities;

		/** @type {string[]} */
		const annotationIds = this.state.annotations
		for (let index = 0; index < annotationGroups.length; index++) {
			const annotationGroup = annotationGroups[index];
			if (annotationGroup.id === annotationGroupIdToDelete) {
				annotationGroups.splice(index, 1);
			}
		}
		for (let index = 0; index < Object.keys(annotations).length; index++) {
			const annotatationId = Object.keys(annotations)[index];
			const annotation = annotations[annotatationId];
			if (annotation.groupAnnotationId === annotationGroupIdToDelete) {
				const annotationIdIndexToDelete = annotationIds.indexOf(annotatationId);
				if (annotationIdIndexToDelete !== -1) {
					annotationIds.splice(annotationIdIndexToDelete, 1);
				}
				delete annotations[annotatationId];
				index = index - 1;
			}
		}
		this.setState(oldState=>({
			...oldState,
			annotations: [...annotationIds],
			entities: {
				...oldState.entities,
				annotationGroups,
				annotations
			}
		}), ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		})
	}

	/* ==================== option list ==================== */
	handleOptionCustomizedInputFocus = () => this.setState({ customizedOptionInputFocused: true });

	handleOptionCustomizedInputBlur = () => this.setState({ customizedOptionInputFocused: false });

	handleOptionCustomizedFormSubmit = (e, parentId, value) => {
		e.preventDefault();
		this.setState((prevState) => {
			const { entities } = prevState;
			const { options } = entities;
			const uniqueKey = getUniqueKey();
			options[uniqueKey] = { id: uniqueKey, value, children: [] };
			options[parentId].children.push(uniqueKey);
			return { entities: { ...entities, options } };
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	handleOptionSelect = (name, selectedIds) => {
		this.setState((prevState) => {
			const { entities } = prevState;
			const selectedOptions = selectedIds.map(id => entities.options[id]).map(s => ({ id: s.id, value: s.value }));
			const updatedAnn = { ...entities.annotations[name], selectedOptions };
			return { entities: { ...entities, annotations: { ...entities.annotations, [name]: updatedAnn } } };
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	/**
	 * @param {string} updatedLabel 
	 * @param {string} annotationGroupIdWhoseLabelToChange 
	 */
	handleAnnotationGroupLabelChange = (updatedLabel, annotationGroupIdWhoseLabelToChange) => {
		this.setState(oldState=>{
			const entities = copyByValue(oldState.entities);
			if (Array.isArray(entities.annotationGroups)) {
				for (const annotationGroup of entities.annotationGroups) {
					if (annotationGroup.id === annotationGroupIdWhoseLabelToChange) {
						annotationGroup.name = updatedLabel;
					}
				}
			}
			return {
				...oldState,
				entities: {
					...oldState.entities,
					...entities
				}
			}
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		})
	}	

	handleOptionDeleteClick = (deleteIds) => {
		this.setState((prevState) => {
			const { entities } = prevState;
			const { options } = entities;
			delete options[deleteIds[deleteIds.length - 1]];
			const i = options[deleteIds[deleteIds.length - 2]].children.indexOf(deleteIds[deleteIds.length - 1]);
			options[deleteIds[deleteIds.length - 2]].children.splice(i, 1);
			return { entities: { ...entities, options } };
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}


	/* ==================== submit ==================== */

	/**
	 * @param {"onAnnotationsChange" | "Skip" | "Save" | "Previous" | "Next"} type 
	 */
	handleSubmit = (type) => {
		const {
			imageScaleFactor, imageWidth, imageHeight, annotations, entities, rootOptionId,
		} = this.state;
		const { annotationGroups } = entities;
		const { url, onSkipClick, onPreviousClick, onNextClick, onSaveClick } = this.props;

		/** @type {import('../../../../../../../../common').IModelType} */
		const modelType = this.props.modelType;

		const annotation = new schema.Entity('annotations');
		console.log("annotations", annotation);
		let denormalizedAnnotations = denormalize({ annotations }, { annotations: [annotation] }, entities).annotations;
		console.log("denormalize :: ", denormalizedAnnotations);
		const option = new schema.Entity('options');
		const children = new schema.Array(option);
		option.define({ children });
		const denormalizedOptions = denormalize({ options: rootOptionId }, { options: option }, entities).options;
		document.body.style.cursor = 'default';

		const imageGroupAnnotations = 
		 modelType === 'imageAnnotationGroup'
		 	? setImageGroupAnnotationsInAnnotationComponentForSavingInDBIfModelTypeImageGroupAnnotation(
					denormalizedAnnotations,
					annotationGroups,
					imageScaleFactor
				)
			:	[];

			denormalizedAnnotations =
				modelType === 'imageAnnotation'
					?	setImageAnnotationsInAnnotationComponentForSavingInDBIfModelTypeImageAnnotation(
							denormalizedAnnotations,
							imageScaleFactor
						)
					: [];

		switch (type) {
		case 'Skip':
			onSkipClick({
				url, imageScaleFactor, imageWidth, imageHeight, annotations: denormalizedAnnotations, options: denormalizedOptions,
			});
			break;
		case 'Save':
			onSaveClick({
				url, imageScaleFactor, imageWidth, imageHeight, annotations: denormalizedAnnotations, imageGroupAnnotations: imageGroupAnnotations, options: denormalizedOptions,
			});
			break;
		case 'Previous':
			onPreviousClick({
				url, imageScaleFactor, imageWidth, imageHeight, annotations: denormalizedAnnotations, options: denormalizedOptions,
			});
			break;
		case 'Next':
			onNextClick({
				url, imageScaleFactor, imageWidth, imageHeight, annotations: denormalizedAnnotations, options: denormalizedOptions,
			});
			break;
		case "onAnnotationsChange":
			if (this.props.onAnnotationsChange) {
				this.props.onAnnotationsChange({annotations: denormalizedAnnotations, imageGroupAnnotations: imageGroupAnnotations})
			}
			break;
		default:
			break;
		}
	}

	/**
	 * @param {number} xAxis 
	 * @param {number} yAxis 
	 */
	setMouseAnnotationCoordinates = (xAxis, yAxis) => {
		this.setState({
			mouseAnnotationCoordinates: {
				xAxis,
				yAxis
			}
		})
	}
	render() {
		const {
			isAdding,
			focusedName,
			focusedGroupAnnotationId,
			magnifyingPower,
			isShowMangnifier,
			isLabelOn,
			imageWidth,
			fullScreenMode,
			imageHeight,
			annotations,
			entities,
			rootOptionId
		} = this.state;
		const {
			className,
			url,
			emptyAnnotationReminderText,
			isDynamicOptionsEnable,
			disabledOptionLevels,
			isViewOnlyMode,
			hasPreviousButton,
			hasNextButton,
			hasSaveButton,
			hasSkipButton,
			alert
		} = this.props;
		const twoDimensionalImageContext = {
			url,
			isAdding,
			entities,
			isViewOnlyMode,
			annotations,
			height: imageHeight,
			width: imageWidth,
			settingManuallyCanvasWidth : this.props.settingManuallyCanvasWidth,
			focusedName,
			focusedGroupAnnotationId,
			isLabelOn,
			magnifyingPower,
			modelType: this.props.modelType,
			emptyAnnotationReminderText,
			onAnnotationClick: this.handleAnnotationClick,
			onAnnotationDeleteClick: this.handleAnnotationDeleteClick,
			isDynamicOptionsEnable,
			disabledOptionLevels,
			onOptionSelect: this.handleOptionSelect,
			onOptionDeleteClick: this.handleOptionDeleteClick,
			onOptionCustomizedInputFocus: this.handleOptionCustomizedInputFocus,
			onOptionCustomizedInputBlur: this.handleOptionCustomizedInputBlur,
			onOptionCustomizedFormSubmit: this.handleOptionCustomizedFormSubmit,
			onCanvasStageMouseDown: this.handleCanvasStageMouseDown,
			onCanvasVertexMouseDown: this.handleCanvasVertexMouseDown,
			toggleShowMagnifier:this.toggleShowMagnifier,
			onCanvasVertexDragEnd: this.handleCanvasVertexDragEnd,
			onCanvasLabelMouseDown: this.handleCanvasFocusing,
			onCanvasLineMouseDown: this.handleCanvasFocusing,
			onCanvasImgLoad: this.handleCanvasImgLoad,
			rootOptionId,
			handleCanvasVertexRemove: this.handleCanvasVertexRemove,
			handleCanvasVertexExtendInAnnotation: this.handleCanvasVertexExtendInAnnotation,
			handleAddImageAnnotationInExistingGroupButtonClick: this.handleAddImageAnnotationInExistingGroupButtonClick, 
			handleAnnotationGroupDeleteClick: this.handleAnnotationGroupDeleteClick,
			handleAnnotationGroupLabelChange: this.handleAnnotationGroupLabelChange,
			canvasContextMenu: this.state.canvasContextMenu,
			updateCanvasContextMenuState: this.updateCanvasContextMenuState,
			scenario: this.props.scenario,
			stageScale: this.state.stageScale,
			setStageScale: this.setStageScale,
			isShowingCompressedImageBecauseOriginalImageIsDownloading: this.props.isShowingCompressedImageBecauseOriginalImageIsDownloading,
			isZoomFeatureEnabled: this.props.isZoomFeatureEnabled,
			setMouseAnnotationCoordinates: this.setMouseAnnotationCoordinates,
			imageScaleFactor: this.state.imageScaleFactor,
			originalImageWidth: this.props.originalImageWidth,
			originalImageHeight: this.props.originalImageHeight,
			naturalWidth: this.state.naturalWidth,
			naturalHeight: this.state.naturalHeight,
			maxImageHeightAllowed: this.props.maxImageHeightAllowed,
			isFullScreenMode: fullScreenMode,
			isShowMangnifier,
			updateVertexPosition:this.updateVertexPosition
		};
		// document.body.style.cursor = isAdding ? 'crosshair' : 'default';

		const canvasElement = document.getElementById(annotationcanvasId);
		
		if (canvasElement) {
			canvasElement.style.cursor = isAdding ? 'crosshair' : 'default';
		}

		// console.log("image width and height are :: ", imageWidth, imageHeight)

		const toggleLabelButtonUI = (
			<Button color='link' onClick={ this.handleToggleLabel } className='two-dimensional-image__label-button d-flex align-items-center'>
				<FaCommentAlt className='pr-1' />
				{isLabelOn ? 'On' : 'Off'}
				<small className='pl-1'>{`(${SHORTCUTS.BUTTON.TOGGLE_LABEL.key})`}</small>
			</Button>
		);
		const previousButtonUI = hasPreviousButton ? (
			<Button color='secondary' onClick={ () => this.handleSubmit('Previous') }>
				Previous
				<small>{`(${SHORTCUTS.BUTTON.PREVIOUS.key})`}</small>
			</Button>
		) : '';
		const ImageOptionsUI = () => {
			return (
				<div 
				className={`
					mb-1 d-flex
					${this.props.scenario === 'coPilotActivePageReadOnlyAnnotation' ? 'displayNone' : ''}
				`}
				style={{
					display: "flex",
					// justifyContent:"center"
					alignItems:"center",
					gap:"7px"
				}}
			>
			     <InfoUI/>
				 <p style={{color:"#999999",fontSize:"14px",marginBottom:"2px"}}>Tap on the Wall / Object you wish to colour</p>
			   </div>				
			)
		}
		const nextButtonUI = hasNextButton ? (
			<Button color='secondary' onClick={ () => this.handleSubmit('Next') }>
				Next
				<small>{`(${SHORTCUTS.BUTTON.NEXT.key})`}</small>
			</Button>
		) : '';
		const skipButtonUI = hasSkipButton ? (
			<Button color='secondary' onClick={ () => this.handleSubmit('Skip') }>
				Skip
				<small>{`(${SHORTCUTS.BUTTON.SKIP.key})`}</small>
			</Button>
		) : '';
		const saveButtonUI = hasSaveButton ? (
			// <Button style={{backgroundColor: themePrimaryMainColor}} onClick={ () => this.handleSubmit('Save') }>
			// 	Save Annotation
			// 	{/* <small>{`(${SHORTCUTS.BUTTON.SKIP.key})`}</small> */}
			// </Button>
			<MuiButton
				variant='contained'
				size="small"
				style={{
					cursor: "pointer",
					backgroundColor: "#5675E7",
					color: "white",
					borderRadius: "8px",
					fontSize: "10px",
					padding: "10px 30px"
				}}
				onClick={()=>this.handleSubmit('Save')}
			> <CheckIcon style={{ color: "white", height: "15px"}} /> Save Changes</MuiButton>
		) : '';

		const addButtonUI = (
			// <Button
			// 	outline
			// 	className='d-flex align-items-center mb-3 two-dimensional-image__add-button'
			// 	// color='primary'
			// 	style={{
			// 		color: "rgb(0, 141, 156)",
			// 		borderColor: "rgb(0, 141, 156)"
			// 	}}
			// 	onClick={ () => this.handleAddClick() }
			// >
			// 	<MdAdd />
			// 	{isAdding ? 'Adding Annotation' : 'Add Annotation'}
			// 	<small>{`(${SHORTCUTS.BUTTON.ADD.key})`}</small>
			// </Button>

				<MuiButton
					size="small"
					onClick={()=>{this.handleAddClick()}}
					variant='outlined'
					style={{
						color: "rgb(0, 141, 156)",
						borderColor: "rgb(0, 141, 156)",
						cursor: "pointer"
					}}
				>{isAdding ? 'Adding New Wall' : 'Add New Wall'}</MuiButton>
		);

		const PointOptionsInfo = () => {
			return (
				<div style={{display: "flex", padding: "10px", color: "#007bff", justifyContent: "space-between"}}>
					{/* <div><InfoIcon size="small" />&nbsp;Right click on point for options</div> */}
					{/* <div><InfoIcon size="small" />&nbsp;</div> */}
					<div></div>
					<div>{saveButtonUI}</div>
					{/* <div>{addButtonUI}</div> */}
				</div>				
			)
		}

		const rootClassName = `two-dimensional-image${className ? ` ${className}` : ''}`;

		// console.log("full screen mode , ", fullScreenMode)
		return (
			<I18nextProvider i18n={ i18nextInstance }>
				<TwoDimensionalImageContext.Provider value={ twoDimensionalImageContext }>
					
					<div className={ rootClassName } style={{width : '100%', display : 'flex', alignItems : 'center', justifyContent : this.props.scenario === "BackgroundRemover"?"flex-start": "center", flexDirection: "column", height: this.props.scenario === "BackgroundRemover"?"auto": "100%"}}>
						{/* { !isViewOnlyMode && (
							<div 
								className={`d-flex justify-content-center pb-3
									${this.props.scenario==="coPilotActivePageReadOnlyAnnotation" || this.props.scenario==="coPilotActivePageEditAnnotation" ? "padding-top-0 padding-bottom-0" : ""}
								`}
							>
								<ButtonGroup>
									{ previousButtonUI }
									{ nextButtonUI }
								</ButtonGroup>
							</div>
						)} */}

						<div className={`d-flex two-dimensional-image__image-canvas-container two-dimensional-mobile-view
							${this.props.scenario === "coPilotActivePageEditAnnotation" ? 'justify-content-space-between' : '' }
							${this.props.scenario === "coPilotActivePageEditAnnotation" || this.props.scenario==="coPilotActivePageReadOnlyAnnotation" || this.props.scenario==="coPilotRejectPageReadOnlyAnnotation" || this.props.scenario==="modelAnalyticsResourcePageViewPopoup"  ? 'padding-top-0 padding-bottom-0' : '' }
							${this.props.scenario === "coPilotRejectPageReadOnlyAnnotation" ? "justify-content-space-between" : ""}
							${css.paddingBottom0}
						`} 
						style={{flexDirection : 'column', height : '100%', width : '100%', justifyContent : 'space-between', alignItems : 'center'}}
						>
							<div className={`
								mb-3
								${this.props.scenario === 'sideDrawer' ? "two-dimensional-image__canvasContainerIfSideDrawer" : ""}
							`}
							style={{...(this.props.canvasBorderTimerAnimationStyle ? this.props.canvasBorderTimerAnimationStyle : {}), position: this.props.isDrawerOpen ? "fixed" : "sticky", height : '60%'}}
							>
								
								{
									(
										this.props.scenario !== 'coPilotActivePageReadOnlyAnnotation' &&
										this.props.scenario !== 'coPilotActivePageEditAnnotation' && !isViewOnlyMode && this.props.scenario !== "BackgroundRemover"
									) &&
									<ImageOptionsUI />
								}

								<div style={ { 
									position: 'relative' , 
									background: this.props.scenario === "BackgroundRemover"?"#BABABA": "",
									border: "none",
									borderRadius: "10px",
									width : this.props.isExpandFeatureEnabled && this.props.settingManuallyCanvasWidth 
										? this.props.settingManuallyCanvasWidth 
										: this.props.scenario === "coPilotActivePageReadOnlyAnnotation" || this.props.scenario === "coPilotActivePageEditAnnotation"
											? "448px"
											: "",
								} }
									onContextMenu={(e)=>e.preventDefault()}
								>
									<Canvas
										entities={ entities }
										focusedName={ focusedName }
										power={ magnifyingPower }
										isLabelOn={ isLabelOn }
									/>
									{this.props.isExpandFeatureEnabled &&
									<div style={{position: 'absolute', bottom: 92,right: 0, cursor: 'pointer', background : 'rgba(255, 255, 255, 0.3)'}}
									onClick={() => {
											this.props.onClickFullScreenMode()
										}} >
									{fullScreenMode ?
										<Tooltip title={`Exit Expand View (f)`}>
											<FullscreenExitIcon 
											color="primary"
											style={{fontSize: '30px'}}
											/>
										</Tooltip>
										 :
										 <Tooltip title={`Open in Expand View (f)`}>
											<FullscreenIcon
											style={{fontSize: '30px'}}
											color="primary" />
										</Tooltip>}
									</div>}
									{!isViewOnlyMode && this.props.scenario!== "BackgroundRemover"&& this.props.scenario !== "coPilotActivePageReadOnlyAnnotation" && <PointOptionsInfo />}
									{this.props.ActionsSection && (
										<div
											className={`
												${this.props.scenario === 'dialog' || this.props.scenario === 'copilotImageAnnotationPopUp'  ? 'two-dimensional-image__actionSectionContainerForDialogScenario' : ''}
											`}
										>
											{this.props.ActionsSection}
										</div>
									)}
								</div>
							</div>
								{/* <div className={`mb-3 margin-top-option ${this.props.isDrawerOpen ? "annotations-list" : ""}
								${this.props.scenario === 'dialog' || this.props.scenario === 'copilotImageAnnotationPopUp' ? "two-dimensional-image__addScrollbarForDialogScenario" : ""}`}>
									<div
										className={`
											${this.props.scenario === 'sideDrawer' ? "two-dimensional-image__addAnnotationButtonRowForSideDrawerScenario" : ""}
											${this.props.scenario === 'dialog' || this.props.scenario === 'copilotImageAnnotationPopUp' ? "two-dimensional-image__addAnnotationButtonRowForDialogScenario" : ""}
										`}
									>

									{ 
										(
											this.props.scenario === "coPilotActivePageEditAnnotation"
										) &&
										<ImageOptionsUI />
									} */}

									{/* {!isViewOnlyMode && this.props.canShowAddAnnotationButton ? addButtonUI : <div></div>} */}
									{/* {this.props.fullscreenButton}
									</div>

									{
										this.props.scenario !== "coPilotActivePageReadOnlyAnnotation" && this.props.scenario!=="coPilotRejectPageReadOnlyAnnotation" && !isViewOnlyMode && this.props.scenario !== "BackgroundRemover" &&
										<></>
									} */}
										{/* <AnnotationList /> */}
									{	this.props.scenario === 'advancedMode' &&
										<div style={{height : '30%', transition : 'height 2s', width : '100vw', position : 'relative'}}>
											<Paper elevation={0} style={{transition : 'height 2s', height : 'calc(100% - 10px)',
												filter : 'drop-shadow(4px 4px 4px black)',
												borderRadius : '30px 30px 0px 0px',
												padding : '10px 2% 0 2%',
												overflow : 'scroll',
												position : 'absolute',
												bottom : 0,
												left : 0,
												right : 0,
												width : '100%',
												}}>
												<div style={{height : 'calc(100% - 55px)', width : '100%', overflow: 'scroll'}}>
												<Accordion expanded={this.state.expandAccordian === 'panel1'} onChange={() => this.setState({expandAccordian : this.state.expandAccordian === 'panel1' ? false :'panel1'})} style={{backgroundColor : this.state.expandAccordian === 'panel1' ? '#f4f4f4' : '#ffffff', borderRadius :'10px'}}>
													<AccordionSummary
													expandIcon={<ExpandMoreIcon />}
													aria-controls="panel1bh-content"
													id="panel1bh-header"
													style={{margin : '0px'}}
													>
														<div style={{display : 'flex', flexDirection : 'column', margin : '0px'}}>
															<Typography style={{fontSize: '18px', flexBasis: '33.33%', flexShrink: 0,}}>Select Wall</Typography>
															{/* <span style={{fontSize : '10px', color : '#959595'}}>Tap to select the wall</span> */}
														</div>
													{/* <Typography className={classes.secondaryHeading}>I am an accordion</Typography> */}
													</AccordionSummary>
															<hr style={{width : '100%' , color : '#959595'}}/>
													{/* <hr/> */}
													<AccordionDetails>
													<AnnotationList />
												
													</AccordionDetails>
												</Accordion>

												</div>
												<div style={{display : 'flex', justifyContent : 'space-evenly', width : '100%'}}>
													<Button color='primary' variant='outlined' style={{cursor : 'pointer'}} onClick={()=>{this.handleAddClick()}}>{isAdding ? 'Adding New Wall' : 'Add New Wall'}</Button>
													<Button color='primary' variant='contained' style={{cursor : 'pointer'}} onClick={()=>this.handleSubmit('Save')}>Save And Colour</Button>
												</div>
											</Paper>
										</div>}


										{/* {
											this.props.ResourceRemarksSection &&
											<div
												style={{marginTop: "20px"}}
											>
													{this.props.ResourceRemarksSection}
											</div>
										} */}
										{
											
										}

										{
											this.props.scenario === "coPilotActivePageEditAnnotation" &&
											<div
												style={{
													marginTop: "20px"
												}}
											>
												{saveButtonUI}
											</div>
										}
								{/* </div> */}
						</div>
						{ !isViewOnlyMode && this.props.scenario!== "BackgroundRemover" &&(
							<div 
								className={`d-flex justify-content-center pt-3
									${this.props.scenario==="coPilotActivePageEditAnnotation" ? "padding-top-0 padding-bottom-0" : ""}
								`}
							>{ skipButtonUI }</div>
						)}
						
						<div style={{position:'relative'}}
							className={`
								${this.props.scenario === 'dialog' || this.props.scenario === 'copilotImageAnnotationPopUp'? 'two-dimensional-image__saveAnnotationButtonRowForDialogScenario' : ''}
							`}
						>
						{
							false &&
							this.props.scenario !== "coPilotRejectPageReadOnlyAnnotation" &&
							this.props.scenario !== "modelAnalyticsResourcePageViewPopoup" &&
							<div style={{display:'flex', justifyContent:'center', position:'absolute', zIndex: 99}}
							className={`
								${this.props.scenario === 'coPilotActivePageEditAnnotation' ? "alertForEditAnnotation" : "alert"}
							`}
							>

									{/* alert text   */}
									{alert[0]==="success" && 
									<Alert  severity="success" >
										{alert[1]}
										</Alert>
								}
								{alert[0]==="failed" && 
									<Alert  severity="error" >
										{alert[1]}
										</Alert>
								}
							</div>
						}
				
							{/* {(this.props.scenario === "BackgroundRemover") && (
								<div className='d-flex justify-content-center pt-3' style={{ gap: "10px", paddingBottom: "20px"}}>
									<div
									style={{ display: "flex", fontSize: "10px", color: "#5959FF", flexDirection: "column", justifyContent: "center", alignItems: "center"}}
									>
										<UndoIcon style={{ height: "20px"}} />
										Undo
									</div>
									<div
									style={{ display: "flex", fontSize: "10px", color: "#5959FF", flexDirection: "column", justifyContent: "center", alignItems: "center"}}
									>
										<RedoIcon style={{ height: "20px"}} />
										Redo
									</div>
									<div
									style={{ display: "flex", fontSize: "10px", color: "#5959FF", flexDirection: "column", justifyContent: "center", alignItems: "center"}}
									>
										<AddIcon style={{ height: "20px"}} />
										Add
									</div>
								</div>
							)} */}
						
						{ this.props.scenario !== "coPilotActivePageEditAnnotation" && (
							<div className='d-flex justify-content-center pt-3'>{ saveButtonUI }</div>
						)}

						</div>
						

					
						

						{
						this.state.canvasContextMenu.isOpen &&
						<CanvasContextMenu 
							open={this.state.canvasContextMenu.isOpen}
							handleRemovePointClick={()=>{
								this.handleCanvasVertexRemove(this.state.canvasContextMenu.event);
								this.updateCanvasContextMenuState({isOpen: false})
							}}
							handleModifyAnnotationFromThisPointClick={()=>{
								this.handleCanvasVertexExtendInAnnotation(this.state.canvasContextMenu.event)
								this.updateCanvasContextMenuState({isOpen: false})
							}}
							handleViewAnnotation={()=>{
								this.handleViewAnnotation(this.state.canvasContextMenu.event);
								this.updateCanvasContextMenuState({isOpen: false})
							}}
							xCoordinate={this.state.canvasContextMenu.xCoordinate}
							yCoordinate={this.state.canvasContextMenu.yCoordinate}
							handleOnClose={()=>this.updateCanvasContextMenuState({isOpen: false})}
						/>			
						}

					</div>
				</TwoDimensionalImageContext.Provider>
			</I18nextProvider>
		);
	}
}

TwoDimensionalImage.propTypes = {
	className: PropTypes.string,
	url: PropTypes.string,
	imageWidth: PropTypes.number,
	defaultAnnotations: PropTypes.arrayOf(PropTypes.object),
	defaultAnnotationGroups: PropTypes.arrayOf(PropTypes.object),
	isDynamicOptionsEnable: PropTypes.bool,
	disabledOptionLevels: PropTypes.arrayOf(PropTypes.string),
	emptyAnnotationReminderText: PropTypes.string,
	isViewOnlyMode: PropTypes.bool,
	hasPreviousButton: PropTypes.bool,
	hasNextButton: PropTypes.bool,
	hasSkipButton: PropTypes.bool,
	hasSaveButton: PropTypes.bool,
	canShowAddAnnotationButton: PropTypes.bool,
	onPreviousClick: PropTypes.func,
	onAnnotationsChange: PropTypes.func,
	onSkipClick: PropTypes.func,
	onNextClick: PropTypes.func,
	onSaveClick: PropTypes.func,
	isLabelOn: PropTypes.bool,
	options: PropTypes.shape({
		id: PropTypes.string,
		value: PropTypes.string,
		children: PropTypes.array,
	}),
	ResourceRemarksSection: PropTypes.element,
	scenario: PropTypes.string,
	modelType: PropTypes.string,
	fullscreenButton: PropTypes.element,
	ActionsSection: PropTypes.element,
	alert: PropTypes.arrayOf(PropTypes.object),
	fullScreenMode : PropTypes.bool,
	onClickFullScreenMode: PropTypes.func,
	key : PropTypes.string,
	isExpandFeatureEnabled : PropTypes.bool,
	settingManuallyCanvasWidth : PropTypes.number || undefined,
	isShowingCompressedImageBecauseOriginalImageIsDownloading: PropTypes.bool,
	resourceId: PropTypes.string,
	isZoomFeatureEnabled: PropTypes.bool,
	addNewWall : PropTypes.bool,
};
TwoDimensionalImage.defaultProps = {
	isShowingCompressedImageBecauseOriginalImageIsDownloading: false,
	onAnnotationsChange: null,
	className: '',
	url: '',
	imageWidth: 400,
	canvasBorderTimerAnimationStyle: null,
	defaultAnnotations: [],
	defaultAnnotationGroups: [],
	options: {},
	isDynamicOptionsEnable: false,
	disabledOptionLevels: [],
	isLabelOn: false,
	isViewOnlyMode: false,
	emptyAnnotationReminderText: '',
	hasPreviousButton: false,
	hasNextButton: false,
	hasSkipButton: false,
	hasSaveButton: false,
	canShowAddAnnotationButton: true,
	ResourceRemarksSection: undefined,
	onPreviousClick: () => {},
	onSkipClick: () => {},
	onSaveClick: () => {},
	onNextClick: () => {},
	fullscreenButton: undefined,
	ActionsSection: undefined,
	modelType: "",
	alert:["",""],
	fullScreenMode : false,
	onClickFullScreenMode : () => {},
	key : "",
	isExpandFeatureEnabled : false,
	settingManuallyCanvasWidth : undefined,
	addNewWall : false,
};
export default React.memo(TwoDimensionalImage);
