export function isNullOrUndefined(data: any): boolean {
    if (data === null || data === undefined) {
      return true;
    }
    return false;
  }
  
  export function isNumber(value: any): boolean {
    return typeof value === "number" ? true : false;
  }
  
  export function isString(value: any): boolean {
    return typeof value === "string" ? true : false;
  }
  
  export function copyByValue<ValueType>(value: ValueType): ValueType {
    if (
      value === null ||
      value === undefined
    ) {
      return value;
    }
    return JSON.parse(JSON.stringify(value));
  }
  