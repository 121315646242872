import React, { useEffect, useState } from 'react'
import { Chip, Theme, makeStyles, Popover , Typography, Button} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { ColorPicker, useColor } from 'react-color-palette';
import "react-color-palette/lib/css/styles.css";

const useStyles = makeStyles((theme: Theme) => ({
    colorButton : {
        width: "26px" , 
        height:"26px", 
        padding: '2px' , 
        borderRadius : "50%",
        display: "flex",
        justifyContent : "center",
        alignItems : "center"
    },
    ring: {
        width : '20px',
        height : '20px',
        border: '4px solid white',
        borderRadius : '50%',
    },
    addBtn : {
        position: "absolute",
        right: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // padding: "2px",
        top: 8,
        backgroundColor: "black",
        borderRadius: "50%",
        // color: "white",
        cursor: "pointer",
        width: "22px",
        height: "22px"
    }
}))

const colorArray : string[] = [
        "#9F8C76",
        "#80bf87",
        "#f4a460",
        "#6a8da6",
        "#FF6347",
        "#daa520",
        "#000080",
        "#B7410E",
        "#588157",
        "#9D6AC3",
        "#FF4040"
    ]

function ColorOptionsUI(props : {
    choords : {
        xChord: number;
        yChord: number;
        id?: string | undefined;
        wallColorSelected?: string | undefined;
        choordColorSelected?: string | undefined;
        canvasX: number;
        canvasY: number;
    }[],
    setChoords : (choords: {
        xChord: number;
        yChord: number;
        id?: string | undefined;
        wallColorSelected?: string | undefined;
        choordColorSelected?: string | undefined;
        canvasX: number;
        canvasY: number;
    }[] | undefined) => void,
}) {
    const classes = useStyles();
    const {choords, setChoords} = props;
    const [selectedChoordIndex, setSelectedChoordIndex] = useState<number>(0);
    const [colorSelected, setColorSelected] = useState<string>('');
    const [colorPicker , setColorPicker] = useState<string>(colorSelected|| "") 
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [color, setColor] = useColor("hex", "#121212");

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };
    
      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;

    const onColorChange = (color : string) => {
        setColorSelected(color)
        if(choords){
            console.log("selected choords are :: ", selectedChoordIndex)
            let newChoords = choords || [];
            // for(var i=0 ; i<choords.length ; i++){
            //     console.log('choord is  :: ', newChoords[i])
            //     if(choords[i].xChord === selectedChoord.selected_x && choords[i].yChord === selectedChoord.selected_y){
            newChoords[selectedChoordIndex].wallColorSelected = color
            // console.log
            //     }
            // }
            console.log('new choords are :: ', newChoords)
            setChoords([...newChoords])
        }
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
          if (colorPicker) {
            onColorChange(colorPicker)
          }
        }, 500)
      
        return () => clearTimeout(delayDebounceFn)
      }, [colorPicker])

    //   const isSelected = (x: number , y : number) : boolean => {
    //     if(selectedChoordIndex){
    //         console.log('selectedChoord is :: ', selectedChoordIndex)
    //     const selected = (selectedChoord.selected_x === x && selectedChoord.selected_y === y)
    //         return selected
    //     }
    //     return false
    // };

    const clickMoreColor = () => {
        const moreColor = document.createElement('input');
        moreColor.type = 'color';
        moreColor.onchange = async (_) => {setColorPicker(moreColor.value)}
        console.log('moreColor.valuem',moreColor.value)
    }

    useEffect(() => {
        console.log("color is ::", color)
    },[color])

  return (
    <div style={{width : '100%'}}>
    {
        choords && choords.length > 0 && choords.map((choord, index) => {
        // const isItemSelected = isSelected(choord.xChord, choord.yChord)
        // showCoordinatesOnCanvasImage(choord.canvasX, choord.canvasY, choord?.choordColorSelected)
        return(
            //@ts-ignore
            <Chip variant={`${index===selectedChoordIndex ? "default" : 'outlined'}`} onClick={() => setSelectedChoordIndex(index)} color='primary' label={`Wall ${index+1}`}/>
        )})
    }
    <div style={{display : 'flex', justifyContent : 'space-between', width : '100%', paddingTop : '10px'}}>
        <div style={{display : 'flex', justifyContent : 'center', alignItems : 'center', flexDirection : 'column'}}>
            <p style={{fontSize : '10px', padding : '0px', color : 'grey', margin : '0px'}}>Your Palate</p>
            <div style={{width : '60px', height : '60px', borderRadius : '10px', backgroundColor:`${choords[selectedChoordIndex].wallColorSelected? choords[selectedChoordIndex].wallColorSelected : '#ffffff'}`}}></div>
        </div>
        
        <div style={{overflow :'auto', width : 'calc(100% - 70px)', display : 'flex', justifyContent : 'center', alignItems : 'center'}}>
        <div style={{width: '100%', display: 'flex',overflow : 'auto', flexWrap : 'wrap'}}>
                {
                colorArray.map((color , index) => {
                    return (
                        <div style={{position : 'relative', padding:"6px"}}>
                        <button className={classes.colorButton} style={{backgroundColor : color , border : colorSelected === color ?"0px solid black" :"none"}}   onClick={() => onColorChange(color)}>
                            {colorSelected === color &&
                                <div className={classes.ring}>

                                </div>
                            }
                        </button>
                        </div>
                    )
                })}
                <div style={{padding : "4px",width : '40px', height : '40px', position: 'sticky', right : 0}}>
                    <div
                    className={classes.addBtn}
                    onClick={handleClick}
                    >
                        <AddCircleIcon aria-describedby={id} onClick={handleClick} style={{color: '#dbdbdd',height: '30px',fontSize: '28px'}}/>
                    </div>
                {/* <input type="color" id="favcolor" name="favcolor" value={colorSelected || "#000000"} onChange={(e) => setColorPicker(e.target.value)} /> */}
                </div>
                </div>
        </div>
    </div>
    {
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{color : '#000000'}}
      >
        {/* <Typography>The content of the Popover.</Typography> */}
        <ColorPicker width={300} height={150} 
                   color={color}
                   onChange={setColor} hideHSV dark />
                   {/* <div> */}
                   <Button variant='contained' color='primary' onClick={() => {onColorChange(color.hex);handleClose()}} style={{width : '100%'}}>Set</Button>
                   {/* </div> */}
        
      </Popover>
      
    }
    </div>
  )
}

export default ColorOptionsUI
