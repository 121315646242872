import React, { useMemo, useState ,useEffect} from 'react'
import { Button, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, Tab, Tabs, Theme, makeStyles, useTheme } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {ReactComponent as Logo} from '../../../assets/svg/TechoXPaintMyWall.svg'
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import ImageTakeAndUploadSection from './ImageTakeAndUploadSection.component';
import RecentImages from './RecentImages.component';
import { ImagesDBData, ImagesType, ObjectImagesType, Scenarios, furnitureOptionsType } from '../../../types/image.type';
import ImagesDBDataContext from './PaintMyWallDBData.context';
import ImagePreview from './ImagePreview/ImagePreview.component';
import PaintMyWall from '../PaintingWalls/PaintMyWall.component';
import ImageSuperimpositionPOC from '../../POC/ImageSuperimpositionPOC.component';
import MagicWandOptions from '../PaintingWalls/MagicWandOptions.component';
import AdvancedModeEditing from '../PaintingWalls/AdvancedModeEditing.component';
import VisualiseMyFurnitureOptions from '../PaintingWalls/VisualiseMyFurnitureOptions';

const drawerWidth = 400;

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    homeAction : {
        height : '10%',
        display : 'flex',
        justifyContent : 'space-between',
        alignItems : 'center',
        margin : '0 20px'
    },
    arrowBackIcon : {
        cursor : 'pointer'
    },
    moreOptions : {
        cursor : 'pointer',

    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    tab : {
        background: '#116EBE44',
    /* opacity: .23; */
        borderRadius: '16px',
        border: '1px solid #00000000',
        color: 'black',
        width: 'fit-content',
    }
}))


export default function HomeScreen() {
    const classes = useStyles()
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [images, setImages] = useState<ImagesType[]>([]);
    const [objectImages,setObjectImages]=useState<ObjectImagesType[]>([])
    const [selectedImage, setSelectedImage] = useState<string>('');
    const [originalImage, setOriginalImage] = useState<string>('');
    const [scenario, setScanario] = useState<Scenarios>('home');
    const [furnitureOptions, setFurnitureOptions] = useState<furnitureOptionsType>('addingObject')
    const [ tabValue, setTabValue] = useState(0);

    const providerValue : ImagesDBData = useMemo(() => ({images,originalImage,setOriginalImage,furnitureOptions,setFurnitureOptions, setImages, selectedImage,setObjectImages,objectImages, setSelectedImage, scenario, setScanario}) , [images, setImages, furnitureOptions,setOriginalImage,setFurnitureOptions,selectedImage, setSelectedImage, originalImage,scenario,setObjectImages,objectImages, setScanario])

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
      };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  


  


  return (
    <ImagesDBDataContext.Provider value={providerValue}> 
        <div style={{height :'100svh', width : '100vw', overflow: "hidden"}}>
            {/* Home Action */}
            
            <div style={{width : '100%', height: '100%'}}>
                {
                    scenario === 'home' &&
                    <>
                        <div className={classes.homeAction}>
                            {/* <ArrowBackIcon className={classes.arrowBackIcon} color='primary'>
                            Back
                            </ArrowBackIcon> */}
                            
                            {/* logo section */}
                            <div></div>
                            <div>
                                <Logo />
                            </div>
                            {/* More options */}
                            <div>
                                    <IconButton
                                    style={{ display: "none"}}
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={handleDrawerOpen}
                                    className={`${open && classes.hide}`}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div style={{width : '100%', height : '90%'}}>
                            {/* <Paper className={classes.root}> */}
                                {/* <Tabs
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    indicatorColor= {undefined}
                                    textColor="primary"
                                    // centered
                                >
                                    <Tab label="Home" className={classes.tab}/>
                                    <Tab label="History" />
                                </Tabs> */}
                            {/* </Paper> */}
                            <div style={{width :"100%", height : '30%'}}>
                            <RecentImages />
                            </div>
                            <div style={{height : 'auto', width : '100%'}}>
                            <ImageTakeAndUploadSection />
                            </div>
                        </div>
                    </>
                }
                {
                    scenario ==='image-preview' &&
                    <>
                        <div className={classes.homeAction}>
                            <ArrowBackIcon className={classes.arrowBackIcon} color='primary' onClick={() => setScanario('home')}>
                            Back
                            </ArrowBackIcon>
                            {/* logo section */}
                            <div>
                                <Logo />
                            </div>
                            {/* More options */}
                            <div>
                                    <IconButton
                                    style={{ display: "none"}}
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={handleDrawerOpen}
                                    className={`${open && classes.hide}`}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div style={{height : '90%', width : '100%'}}>
                            <ImagePreview />
                        </div>
                    </>
                }
                {
                    scenario ==='actions' &&
                    <>
                        <div className={classes.homeAction}>
                            <ArrowBackIcon className={classes.arrowBackIcon} color='primary' onClick={() => setScanario('magicWandOptions')}>
                            Back
                            </ArrowBackIcon>
                            {/* logo section */}
                            <div>
                                <Logo />
                            </div>
                            {/* More options */}
                            <div>
                                    <IconButton
                                    style={{ display: "none"}}
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={handleDrawerOpen}
                                    className={`${open && classes.hide}`}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div style={{height : '90%', width : '100%'}}>
                            <PaintMyWall />
                        </div>
                    </>
                }
                {
                    scenario === 'magicWandOptions' &&
                    <>
                        <div className={classes.homeAction}>
                            <ArrowBackIcon className={classes.arrowBackIcon} color='primary' onClick={() => setScanario('image-preview')}>
                            Back
                            </ArrowBackIcon>
                            {/* logo section */}
                            <div>
                                <Logo />
                            </div>
                            {/* More options */}
                            <div>
                                    <IconButton
                                    style={{ display: "none"}}
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={handleDrawerOpen}
                                    className={`${open && classes.hide}`}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div style={{height : '90%', width : '100%'}}>
                            <MagicWandOptions />
                        </div>
                    </>
                }
                {
                    scenario === 'visualiseMyFurniture' &&
                    <>
                        <div className={classes.homeAction}>
                            <ArrowBackIcon className={classes.arrowBackIcon} color='primary' onClick={() => setScanario('magicWandOptions')}>
                            Back
                            </ArrowBackIcon>
                            {/* logo section */}
                            <div>
                                <Logo />
                            </div>
                            {/* More options */}
                            <div>
                                    <IconButton
                                    style={{ display: "none"}}
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={handleDrawerOpen}
                                    className={`${open && classes.hide}`}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div style={{height : '90%', width : '100%'}}>
                           <VisualiseMyFurnitureOptions/>
                        </div>
                    </>
                }
                {
                    scenario === 'uploadObject' &&
                    <>
                        <div className={classes.homeAction}>
                            <ArrowBackIcon className={classes.arrowBackIcon} onClick={() => setScanario('visualiseMyFurniture')}  color='primary'>
                            Back
                            </ArrowBackIcon>
                            {/* logo section */}
                            <div>
                                <Logo />
                            </div>
                            {/* More options */}
                            <div>
                                    <IconButton
                                    style={{ display: "none"}}
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={handleDrawerOpen}
                                    className={`${open && classes.hide}`}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div style={{width : '100%', height : '90%'}}>
                            {/* <Paper className={classes.root}> */}
                                {/* <Tabs
                                    value={tabValue}
                                    onChange={handleTabChange}
                                    indicatorColor= {undefined}
                                    textColor="primary"
                                    // centered
                                >
                                    <Tab label="Home" className={classes.tab}/>
                                    <Tab label="History" />
                                </Tabs> */}
                            {/* </Paper> */}
                            <div style={{width :"100%", height : '30%'}}>
                            <RecentImages isObjectUpload={true} />
                            </div>
                            <div style={{height : 'auto', width : '100%'}}>
                            <ImageTakeAndUploadSection  isObjectUpload={true} />
                            </div>
                        </div>
                    </>
                }
                {
                    scenario === 'advancedMode' &&
                    <>
                        <div className={classes.homeAction}>
                            <ArrowBackIcon className={classes.arrowBackIcon} color='primary' onClick={() => setScanario('actions')}>
                            Back
                            </ArrowBackIcon>
                            {/* logo section */}
                            <div>
                                <Logo />
                            </div>
                            {/* More options */}
                            <div>
                                    <IconButton
                                    style={{ display: "none"}}
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={handleDrawerOpen}
                                    className={`${open && classes.hide}`}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div style={{height : '90%', width : '100%'}}>
                            <AdvancedModeEditing />
                        </div>
                    </>
                }
                {
                    scenario ==='reimagination' &&
                    <>
                        <div className={classes.homeAction}>
                            <ArrowBackIcon className={classes.arrowBackIcon} color='primary' onClick={() =>{
                                furnitureOptions !== "furnitureImageAnnotation" && setScanario(furnitureOptions==="addingObject"?'uploadObject':"visualiseMyFurniture")
                                furnitureOptions==="furnitureImageAnnotation" && setFurnitureOptions('addingObject')
                                 setObjectImages([])
                                  }}>
                            Back
                            </ArrowBackIcon>
                            {/* logo section */}
                            <div>
                                <Logo />
                            </div>
                            {/* More options */}
                            <div>
                                    <IconButton
                                    style={{ display: "none"}}
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={handleDrawerOpen}
                                    className={`${open && classes.hide}`}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div style={{height : '90%', width : '100%'}}>
                            <ImageSuperimpositionPOC />
                        </div>
                    </>
                }
                
                
            </div>
            {/* <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classes={{
                paper: classes.drawerPaper,
                }}
                color ='primary'
            >
                <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
                </div>
                <Divider />
                <List>
                {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                    <ListItem button key={text}>
                    <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                    <ListItemText primary={text} />
                    </ListItem>
                ))}
                </List>
        </Drawer> */}
        </div>
    </ImagesDBDataContext.Provider>
  )
}

