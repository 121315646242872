import React, { useContext, useEffect, useRef, useState } from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CameraIcon from '@material-ui/icons/Camera';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
// import classes from './captureImage.module.classes';
import Webcam from "react-webcam";
import { Dialog, Theme, hexToRgb, makeStyles } from '@material-ui/core';
import ImagesDBDataContext from './PaintMyWallDBData.context';
import 'react-circular-progressbar/dist/styles.css';
import { Height } from '@material-ui/icons';


const useStyles = makeStyles((theme: Theme) => ({
  root : {
    width : '100vw',
    height : '99vh',
    display : 'flex',
    flexDirection : 'column',
    justifyContent : 'center',
    // alignItems : 'center',
  },
  captureAreaBody : {

  },
  arrowBackIcon : {
    fontSize : '45px !important'
  },
  clickPhotoButton : {
    fontSize : '45px !important'
  },
  flipCameraButton : {
    fontSize : '45px !important'
  }
}))


// const 
export default function CaptureImageLibrary(props : {isObjectUpload:boolean,openCamera : boolean, exitCamera : () => void}) {
  const {isObjectUpload}=props;
    const classes = useStyles()
    const [showImagePreview, setShowImagePreview] = useState<boolean>(false)
    const imageContext = useContext(ImagesDBDataContext)
    const webcamRef = React.useRef(null);
    const [image, setImage] = useState<string>();
    const [percentage, setPercentage] = useState(25);
    const [flag, setFlag] = useState<boolean>(false);

    useEffect(() => {
      const interval = setInterval(() => {
        setPercentage((prevPercentage) => (prevPercentage + 1) % 101);
      }, 50); 
      return () => clearInterval(interval);
    }, [flag]);

    const capture = React.useCallback(() => {
      if(webcamRef.current){
        //@ts-ignore
      const imageSrc = webcamRef.current.getScreenshot();
      if(isObjectUpload){
        const byteCharacters = atob(imageSrc.split(',')[1]);
        const byteArrays = [];
        for (let i = 0; i < byteCharacters.length; i++) {
          byteArrays.push(byteCharacters.charCodeAt(i));
        }
        
        const blob = new Blob([new Uint8Array(byteArrays)], { type: 'image/png' });
        
        // Create a File object from the Blob
        const file = new File([blob], 'screenshot.png', { type: 'image/png' });
        imageContext.setObjectImages((oldState) => {return [...oldState,{url:imageSrc,file:file}]});
      }else{
        imageContext.setImages((oldState) => {return [...oldState, {url : imageSrc}]})
      }
      setImage(imageSrc);
      imageContext.setScanario(!isObjectUpload?'image-preview':'reimagination');
      // imageContext.setSelectedImage(imageContext.images[0].url)
    //   console.log(imageSrc);
      setShowImagePreview(true);
    }
    }, [webcamRef]);

    const stream = useRef<MediaStream | null>(null);

    const [videoConstraints, setVideoConstraints] = useState<MediaTrackConstraints | undefined>();
    const [numberOfCameras, setNumberOfCameras] = useState<number>(0);
    

    const onBackButtonClick = () => {
        if(stream.current){
          let videoStream = stream.current
          videoStream.getTracks().forEach(function(track) {
              track.stop();
            });
        }
        stream.current = null;
          // stopAllDevices()
        props.exitCamera()
    
    }

    const resetPreview = () => {
        props.exitCamera()
        setShowImagePreview(false)
      }

      useEffect(() => { 
        let video = document.getElementById("video") as HTMLVideoElement;
        setVideoConstraints({
            width: video?.width || 1280,
            height: video?.height || 720,
            facingMode: "environment"
        })
        calcNumberOfCamera()
      },[])

      const flipCamera = () => {
        setFlag(true);
        if(videoConstraints){
            if(videoConstraints.facingMode  === "user"){
                setVideoConstraints({
                    width: videoConstraints.width || 1280,
                    height: videoConstraints.height || 720,
                    facingMode : "environment"
                })
            }else{
                setVideoConstraints({
                    width: videoConstraints.width || 1280,
                    height: videoConstraints.height || 720,
                    facingMode : "user"
                })
            }
        }
        setTimeout(() => {
          setFlag(false);
          setPercentage(0);
        }, 1000);     
      }

      const calcNumberOfCamera = () => {
        navigator.mediaDevices
        .enumerateDevices()
        .then(r => (setNumberOfCameras(r.filter(i => i.kind === 'videoinput').length)));

      }

      const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', 
      };

  return (
    <Dialog 
    open={props.openCamera}
    onClose={props.exitCamera}
    fullScreen
    className={classes.root}>
      {
        // showImagePreview ? image && 
        //   <ImagePreview imgSrc={[image]} setShowImagePreview={() => resetPreview()}/>
        // //   <></>
        // :
        (<div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <div  
            style={{
              position : 'relative',
              width: "100%",
              height: "80%",
              // backgroundColor : 'red'
            }}
          >
              { flag === false ? <Webcam
                id='video'
                style={{width: "100%", height: "100%"}}
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                mirrored={videoConstraints?.facingMode === "user"}
              /> : 
              <div style={containerStyle}>
                <div style={{ width: '5rem', height: '5rem' }}>
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                      rotation: 0.25,
                      strokeLinecap: 'butt',
                      textSize: '10px',
                      pathTransitionDuration: 0.5,
                      pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
                      textColor: '#f88',
                      trailColor: '#d6d6d6',
                      backgroundColor: '#3e98c7',
                    })}
                  />
                </div>
              </div>
              }
          </div>
            <div style={{width : '100%', height : 'auto', display : 'flex', justifyContent : 'space-evenly', alignItems : 'center'}}>

                <ArrowBackIcon id='back-button'
                onClick={onBackButtonClick} 
                className={classes.arrowBackIcon}>Back</ArrowBackIcon>
                  <CameraIcon id="click-photo" onClick={capture} className={classes.clickPhotoButton}>Click Photo</CameraIcon>
                  { 
                  // numberOfCameras > 1 &&
                  <FlipCameraIosIcon id="flip-camera" onClick={flipCamera} className={classes.flipCameraButton}>Flip camera</FlipCameraIosIcon>
                }
            </div>
          
          </div>)
      }
        
    </Dialog>
  )
}