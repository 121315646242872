import React from 'react';
import PropTypes from 'prop-types';
import { MdUndo, MdRedo } from 'react-icons/md';
import MuiButtonGroup from '@material-ui/core/ButtonGroup';
import {Button as MuiButton} from '@material-ui/core';
import {
	Button,
	ButtonGroup,
} from 'reactstrap';

const UndoRedoButton = ({
	className,
	onUndoClick,
	onRedoClick,
	shortcuts,
	undoRedoState,
}) => {
	const rootClassName = `undo-redo-button${className ? ` ${className}` : ''}`;
	return (
		<ButtonGroup className={ rootClassName }
			style={{
				
			}}
		>
			<Button disabled={ undoRedoState.previous.length === 0 } outline onClick={ onUndoClick }>
				<MdUndo />
				<small>{`(${shortcuts.UNDO.key})`}</small>
			</Button>
			<Button disabled={ undoRedoState.next.length === 0 } outline onClick={ onRedoClick }>
				<MdRedo />
				<small>{`(${shortcuts.REDO.key})`}</small>
			</Button>
		</ButtonGroup>

		// <MuiButtonGroup color="primary" size='small'
		// 	style={{
		// 		color: "rgb(66, 170, 180)",
		// 		borderColor: "rgb(66, 170, 180)",

		// 	}}
		// >
		// 	<MuiButton size='small' startIcon={<MdUndo />}>(z)</MuiButton>
		// 	<MuiButton size='small' startIcon={<MdRedo />}>(x)</MuiButton>
		// </MuiButtonGroup>
	);
};


UndoRedoButton.propTypes = {
	className: PropTypes.string,
	onUndoClick: PropTypes.func,
	onRedoClick: PropTypes.func,
	shortcuts: PropTypes.object,
	undoRedoState: PropTypes.object,
};
UndoRedoButton.defaultProps = {
	className: '',
	onUndoClick: () => {},
	onRedoClick: () => {},
};
export default UndoRedoButton;
