import React, { useEffect, useState,useRef } from 'react';
import { ImagesType } from '../../types/image.type';

let isReadyToProcess=true;
let intervalId:any;

interface LoadingBarProps {
 
  staticImgSrc:any,
  objectData:ImagesType
  setLoadingStatus:React.Dispatch<React.SetStateAction<boolean[]>>,
  objectIndex:number

}

const LoadingBar: React.FC<LoadingBarProps> = (props: any) => {
  const [progress, setProgress] = useState<number>(0);
  const progressRef = useRef<number>(0);

  useEffect(() => {

  const progressLimit=props.objectData.progress?70:50;
  const intervalSpeed=props.objectData.progress?0.1:10;
  intervalId=setInterval(()=>{
    if (progressRef.current < progressLimit) {
      progressRef.current += 0.1;
      setProgress(progressRef.current);
    } 
  },intervalSpeed) 
  return ()=>{
  }
 
  }, [props.objectData.progress]);


  useEffect(() => {
    if(progressRef.current>=70 && isReadyToProcess){
      isReadyToProcess=false;
      props.setLoadingStatus((oldData:boolean[])=>{
        const updatedStatus = [...oldData];
        updatedStatus[props.objectIndex]=false;
        console.log(updatedStatus,"new data")
        return updatedStatus;
      })  
      clearInterval(intervalId)
      isReadyToProcess=true;
    }
  }, [progressRef.current])
  

  return (
    <div style={{ width: '70px', height:"85px", justifyContent:"space-between", display: "flex",flexDirection: 'column' ,gap:0 }}>
      <div style={{width:"70px",height:"80px",display:"flex" , justifyContent:"center",alignItems:"center"}}>
      <img className='loading-bar-img' src={props.staticImgSrc} style={{ opacity: `60%`,  width: '70px', padding:"10px", height: 'auto' }} />
      </div>
      <div style={{ width: `${progress}px`, backgroundColor: 'rgb(147 197 253)', height: '4px' }}></div>
    </div>
  );
};

export default LoadingBar;
