import React, { useContext, useEffect, useState } from 'react'
import CancelIcon from '@material-ui/icons/Cancel';
// import css from './showImages.module.css'
import ImagesDBDataContext from '../PaintMyWallDBData.context';
import { Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    imagesBody :{
        width: '100%',
        display: 'flex',
        height: '85px',
        columnGap: '15px',
        overflow : 'auto'
    },
    individualImageBody: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        margin: '5px 0px',
        borderRadius: '20px'
    },
    cancelIconParent : {
        position: 'absolute',
        right: '-7px',
        top: '0px',
        backgroundColor: 'rgb(0, 0, 0)',
        borderRadius: '50%',
        // color: '#BEBEBE',
        cursor: 'pointer',
        fontSize : '20px',
        margin: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '11px',
        width: '11px',
    },
    cancelIcon : {
        position: 'absolute',
        right: '-5px',
        top: '-5px',
        // backgroundColor: ,
        borderRadius: '50%',
        color: '#BEBEBE',
        cursor: 'pointer',
        fontSize : '20px',
    },
    image: {
        // padding: '5px',
        margin: '2px',
        borderRadius: '16px'
        // objectFit: 'contain'
    }
 }))

function ShowImages() {
    const classes= useStyles()
    const imageContext = useContext(ImagesDBDataContext)
    const [indexSelected , setIndexSelected] = useState<number>()
    const [isImageDeleting, setImageDeleting] = useState<boolean>(false)

    function removeImage (url : string , index : number) {
        setImageDeleting(true)
        let prevImageUrls = imageContext?.images
        for(var i=0;i<prevImageUrls.length;i++){
            if(i === index){
                prevImageUrls.splice(index,1)
                break;
            }
        }
        imageContext.setImages([...prevImageUrls])
        if(imageContext?.images?.length === 0 ){
            imageContext.setScanario('home')
        }
        if(index === 0 && imageContext.images.length >= 1){
            imageContext.setSelectedImage(imageContext?.images[0]?.url)
            imageContext.setOriginalImage(imageContext?.images[0]?.url)
        }else{
            imageContext.setSelectedImage(imageContext?.images[index-1]?.url)
            imageContext.setOriginalImage(imageContext?.images[index-1]?.url)

        }
        
        setIndexSelected(undefined)
        console.log("imageurls are  :: ", imageContext.images.map(image => image.url))
        setImageDeleting(false)
    }
    function onIndexSelect(url :string , index : number) {
        setIndexSelected(index)
        imageContext.setSelectedImage(url)
        imageContext.setOriginalImage(url)
        if(imageContext.scenario === 'home'){
            imageContext.setScanario('image-preview')
        }
    }
    
    useEffect(() => {
        imageContext.images.map((image, index) => {
            if(image.url === imageContext.selectedImage) setIndexSelected(index)
            return image
        })
    }, [imageContext.selectedImage])
    
  return (
    <div className={classes.imagesBody}>
        {!isImageDeleting ?
        imageContext.images.map((image , index) => {
            // console.log("url and indexes are :: ", url, index)
            return (
                <div className={classes.individualImageBody}>
                    <div  className={classes.cancelIconParent} >
                        <CancelIcon className={classes.cancelIcon} onClick={() => removeImage(image.url, index)}/>
                    </div>
                    <img src={image.url} width="60" height="60" alt="" className={classes.image} style={{border: indexSelected === index ? '5px solid #5675E7' : '', objectFit: 'cover'}}   onClick={() => onIndexSelect(image.url , index)}></img>
                </div>
            )
        })
        :
        <div style={{height:'60px', backgroundColor:'grey', width: '300px'}}>
        </div>
        }
    </div>
  )
}

export default ShowImages