import { Button, makeStyles, withStyles } from '@material-ui/core';
import React, { Ref, useContext, useEffect, useRef, useState } from 'react'
import { Stage, Layer } from "react-konva";
import eraser from './eraser.svg';
import undo from './undo.svg';
import redo from './redo.svg';
import brush from './brush.svg';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import html2canvas from 'html2canvas';
import WallPaintingApiService from '../../services/WallPaintingApiService';
import CheckIcon from '@material-ui/icons/Check';
import ImagesDBDataContext from '../PaintMyWall/Home/PaintMyWallDBData.context';
import ImageService from '../../services/image.service';
import Slider from '@material-ui/core/Slider';
import WandAnimation from '../../assets/Lottie-files/White Wand.json';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import InfoIcon from '@material-ui/icons/Info';




let brushWidth=20;



function Sliderr(Color: any) {
    const [value, setValue] = useState(20); // Initial value of the slider
    const handleChange = (event: any, newValue: number | number[]): void => {
        brushWidth = newValue as number
        setValue(newValue as number);
    };
    return (
        <Slider
            // type="range"
            min={7}
            max={40}
            step={1}
            value={value}
            onChange={handleChange}
            // color={Color}
            style={{ width: '80px' }}
            aria-labelledby="continuous-slider"
        />
    );
}


const useStyles = makeStyles(() => ({
    ImageArea: {
        height: '64%',
        width: 'calc(100% - 80px)',
        // padding : '0px 20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttons: {
        fontSize: '12px',
        width: '150px',
        cursor: 'pointer',
        borderRadius: "7px"
    },
    container: {
        position: 'fixed',
        top: '-80px',
        bottom: '0px',
        left: '0px',
        right: '0px',
        width: '100%',
        overflow:"hidden",
        height: '110svh',
        backdropFilter: 'blur(6px)',
        zIndex: 999999,
        background: 'rgb(0, 0, 0, 0.35) 0% 0% no-repeat padding-box',
        // -webkit-backdrop-filter: 'blur(20px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '@media (min-height: 1000px)':{
      ImageArea : {
        height : '74%',
      },
      container:{
        top:"-150px"
      }
    }
}))

let paintColor: string = "#3d34a5aa"
let newCanvas:HTMLElement;



function ObjectEraser(props: any) {
    const {  selectedImageUrl,setIsBlurred } = props
    const stageRef = React.useRef();
    const layerRef = React.useRef();
    const classes = useStyles()
    const isPainting = useRef(false);
    const isEraserMode = useRef(false);
    const currentStateIndex = useRef(0)
    const [canSaveChanges, setcanSaveChanges] = useState(false);
    const imageContext = useContext(ImagesDBDataContext);
    const lastPointerPosition = useRef({ x: 0, y: 0 });
    const [finalImageUrl, setfinalImageUrl] = useState(selectedImageUrl)
    const [canvasStates, setCanvasStates] = useState<any[]>([selectedImageUrl]);
    const imageContainerRef = useRef<HTMLDivElement>();
    const [ imageWidthToSet , setImageWidthToSet ] = useState<number>(0);
    const [ imageHeightToSet , setImageHeightToSet ] = useState<number>(0);
    const [loadingErasedImage, setLoadingErasedImage] = useState<boolean>(false);
    const [selectedTool, setSelectedTool] = useState('brush');
    const [parentDivHeightForMarginObject, setParentDivHeightForMarginObject] = useState<number>(0);
    const [imageHeightForMarginObject, setImageHeightForMarginObject] = useState<number>(0);
   
    function calculateWidthAndHeight () {
        let base_image : HTMLImageElement = new window.Image();
        base_image.src = selectedImageUrl;

        base_image.onload = () => {
          console.log('base image :: ', base_image.width, base_image.height, imageContainerRef.current?.offsetWidth)  
          const imageWidth = base_image.width || 0;
          const imageHeight = base_image.height || 0;
          
          // newCanvas.setAttribute("width", `${imageWidth}`);
          // newCanvas.setAttribute("height", `${imageHeight}`);
              const imageAspectRatio = imageWidth / imageHeight;
             
              let imageWidthToSet: number = 0;
              let imageHeightToSet: number = 0;
              //@ts-ignore
              const imageMaxWidthAllowed = imageContainerRef.current?.offsetWidth -20 ||  window.innerWidth || 0;
              // 0.81*(window.innerWidth - (window.innerWidth > 900 ?  200 : 50));
              const imageMaxHeightAllowed = (imageContainerRef.current?.offsetHeight && imageContainerRef.current?.offsetHeight ) ||  window.innerHeight || 0;
              // 0.81*(window.innerHeight - (window.innerWidth > 900 ? 300 : 150));
              console.log("max width allowed and max height allowed", imageMaxWidthAllowed, imageMaxHeightAllowed, imageWidth, imageHeight);
        
              if (imageWidth >= imageHeight) {
                imageWidthToSet = imageMaxWidthAllowed 
                imageHeightToSet = imageWidthToSet / imageAspectRatio;
                imageHeightToSet = ImageService.getImageHeight(
                  imageWidthToSet,
                  imageAspectRatio
                );
        
                if (imageHeightToSet > imageMaxHeightAllowed) {
                  imageHeightToSet = imageMaxHeightAllowed;
                  imageWidthToSet = ImageService.getImageWidth(
                    imageHeightToSet,
                    imageAspectRatio
                  );
                }
              } else if (imageHeight > imageWidth) {
                imageHeightToSet = imageMaxHeightAllowed;
                imageWidthToSet = imageAspectRatio * imageHeightToSet;
                imageWidthToSet = ImageService.getImageWidth(
                  imageHeightToSet,
                  imageAspectRatio
                );
        
                if (imageWidthToSet > imageMaxHeightAllowed) {
                  imageWidthToSet = imageMaxWidthAllowed; 
                  imageHeightToSet = ImageService.getImageHeight(
                    imageWidthToSet,
                    imageAspectRatio
                  );
                }
              }
        
              setImageWidthToSet(imageWidthToSet);
              setImageHeightToSet(imageHeightToSet);
              console.log("the new image",imageHeightToSet);
              setImageHeightForMarginObject(imageHeightToSet);
          
            }
        }
          
        const calculateWidthAndHeightRef = useRef(calculateWidthAndHeight);
        calculateWidthAndHeightRef.current = calculateWidthAndHeight;

        useEffect(() => {
            calculateWidthAndHeightRef.current()
             window.addEventListener('resize', calculateWidthAndHeightRef.current, false)
         },[])
     
  

    useEffect(() => {
        brushWidth=20;
        newCanvas = document.createElement('canvas');  
       // @ts-ignore
        newCanvas.width = imageWidthToSet;
        // @ts-ignore
        newCanvas.height = imageHeightToSet
        newCanvas.style.backgroundColor = "#000000";
    }, [imageWidthToSet,imageHeightToSet])
    

    useEffect(() => {

        const stage = stageRef.current;
        const layer = layerRef.current;

        if (stage) { 
            // @ts-ignore
            stage.on('mousedown touchstart', () => {
                isPainting.current = true;
                //@ts-ignore
                const pos = stage.getPointerPosition();
                if (pos) {
                    lastPointerPosition.current = pos;
                }
            });

            //@ts-ignore
            stage.on('mouseup touchend', () => {
                isPainting.current = false;
                saveCanvasState();
            });

            //@ts-ignore
            stage.on('mousemove touchmove', () => {
                const eraserMode=isEraserMode.current
                if (!isPainting.current) {
                    return;
                }
                //@ts-ignore
                const pos = stage.getPointerPosition();
                if (pos) {
                    const oldPos = lastPointerPosition.current;
                    const layer = layerRef.current;
                    if (layer) {
                        //@ts-ignore
                        const context = layer.getContext("2d");
                        //@ts-ignore
                        const context2 = newCanvas.getContext("2d");
                        if (context) {
                            if (eraserMode) {
                                // If eraser mode is active, use the background image to erase
                                context.globalCompositeOperation = 'destination-out';
                                context.fillStyle = 'rgba(0,0,0,0)';
                                context.lineWidth = brushWidth;
                            } else {
                                context.globalCompositeOperation = 'source-over';
                                context.strokeStyle = paintColor;
                                context.lineJoin = 'round';
                                context.lineWidth = brushWidth
                                context.lineCap = "round";
                            }
                            context.beginPath();
                            context.moveTo(oldPos.x, oldPos.y);
                            context.lineTo(pos.x, pos.y);
                            context.stroke();
                        }

                        if (context2) {
                            if (eraserMode) {
                                // If eraser mode is active, use the background image to erase
                                context2.globalCompositeOperation = 'destination-out';
                                context2.fillStyle = 'rgba(0,0,0,0)';
                                context2.lineWidth = brushWidth
                            } else {
                                context2.globalCompositeOperation = 'source-over';
                                context2.strokeStyle = '#ffffff';
                                context2.lineJoin = 'round';
                                context2.lineWidth = brushWidth
                                context2.lineCap = "round";
                            }
                            context2.beginPath();
                            context2.moveTo(oldPos.x, oldPos.y);
                            context2.lineTo(pos.x, pos.y);
                            context2.stroke();
                        }
                        
                        lastPointerPosition.current = pos

                    }
                }

            });
        }
    }, []);



    const saveCanvasState = () => {
        const stage = stageRef.current;
        if (stage) {
            // @ts-ignore
            const htmlElement = stage.container();
            htmlElement.style.borderRadius = 0;
            console.log("cliked  .........")
            html2canvas(htmlElement, { useCORS: true, allowTaint: true, }).then((canvas) => {
                setCanvasStates((prevStates) => {
                    const newStateStack = prevStates.slice(0, currentStateIndex.current + 1)
                    newStateStack.push(canvas.toDataURL());
                    return newStateStack;
                });
                currentStateIndex.current = currentStateIndex.current +  1;
            })

        }
    };



    const undoButton = () => {
        if (currentStateIndex.current > 0) {
            clearCanvas();
            currentStateIndex.current = currentStateIndex.current -  1;
            loadCanvasState();
        }
    };

    const redoButton = () => {
        if (currentStateIndex.current < canvasStates.length - 1) {
            currentStateIndex.current = currentStateIndex.current + 1;
            loadCanvasState();
        }
    };


     
    const loadCanvasState = () => {
        setfinalImageUrl(canvasStates[currentStateIndex.current])
    }


    const submitSelectedMask = async () => {
        const stage = stageRef.current;
        // const layer=layerRef.current
        setcanSaveChanges(false)
        if (stage) {
               setLoadingErasedImage(true)
                 // @ts-ignore
               let bodyFormData = new FormData();
               const imageResponse = await fetch(finalImageUrl);
               const imageBlob = await imageResponse.blob();
               const imageFile = new File([imageBlob], 'image.jpg', { type: imageBlob.type });
               bodyFormData.append("image", imageFile);
                // @ts-ignore
               const maskedImageGenerate = newCanvas.toDataURL("image/jpeg");
                const response = await fetch(maskedImageGenerate);
                const blob = await response.blob();
                const file = new File([blob], 'image.jpg', { type: blob.type });
                bodyFormData.append("mask", file);
                const apiResponse = await WallPaintingApiService.WallErasingObjectApi(bodyFormData)
                if (apiResponse.data) {  
                    const imageLoader = new Image();
                    imageLoader.src =apiResponse.data
                    imageLoader.onload = () => {
                        // Image has finished loading
                        setfinalImageUrl(apiResponse.data)
                        clearCanvas()
                        setLoadingErasedImage(false)
                      };

                      setTimeout(() => {
                        newCanvas = document.createElement('canvas');  
                        // @ts-ignore
                        newCanvas.width = imageWidthToSet;
                        // @ts-ignore
                        newCanvas.height =  imageHeightToSet;
                        newCanvas.style.backgroundColor = "#000000";
                        console.log(newCanvas,imageHeightToSet,imageWidthToSet)
                      }, 1000)                        
                      setcanSaveChanges(true)
                    // setfinalImageUrl(apiResponse.data)
                }

        }
    }

    const clearCanvas = () => {
        const layer = layerRef.current;
        // @ts-ignore
        const context = layer.getContext("2d") ;
    
        if (context){ 
        // @ts-ignore
          context.clearRect(0, 0, layer.width(), layer.height());
          // @ts-ignore
          layer.batchDraw();
        }   
  }

  const preventPullToRefresh = (e: TouchEvent) => {
    e.preventDefault();
  };

  useEffect(() => {
    window.addEventListener('touchmove', preventPullToRefresh, { passive: false });
    return () => {
        // @ts-ignore
        window.removeEventListener('touchmove', preventPullToRefresh, { passive: true});
    };
  }, []);
      
  useEffect(() => {

    const parentDivHeight = document.getElementById("calHeightForMarginInfo");
    if(parentDivHeight){
      console.log("parentDivHeightObectEraser:", parentDivHeight.offsetHeight);
      setParentDivHeightForMarginObject(parentDivHeight.offsetHeight);
    }
  }, [] );
 

    return (
        <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
              {loadingErasedImage &&
            <div className={classes.container}>
                <Player
                    autoplay
                    loop
                    src={WandAnimation}
                    style={{ height: '300px', width: '300px', backgroundColor : 'transparent' }}
                    >
                    {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                    </Player>
            </div>}
            <div 
            // @ts-ignore
            ref={imageContainerRef} className={classes.ImageArea} id='calHeightForMarginInfo' style={{ width: '100%', position:'relative'}}>
                {/* {<p>hi this ther wit ehwiet hwet i</p>} */}
                {<p style={{
                        position: 'absolute',
                        top: `${(parentDivHeightForMarginObject - imageHeightToSet)/2}px`,
                        left: `${ (window.innerWidth - imageWidthToSet)/2}px`,
                        padding:'5px',
                        // paddingBottom:'10px',
                        // left:'0',
                        width: `${imageWidthToSet}px`,
                        height:'1.8rem',
                        // width:'100%',
                        // backgroundColor: 'grey',
                        // transform: 'translateY(-100%)',
                        zIndex: 100,
                        fontSize: '11px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap:'7px',
                        justifyContent: 'flex-start',
                        color: 'white',
                        textAlign: 'center',
                        borderRadius: '10px 10px 0 0',
                        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0))',
                    }}><InfoIcon style={{fontSize:'13px'}}/>You can brush or erase the boundary of the object</p>}
                <Stage 
                    width={imageWidthToSet}
                    height={imageHeightToSet}
                    id={"stage"}
                    // @ts-ignore
                    ref={stageRef}

                    style={{ borderRadius: "10px", backgroundImage: `url(${finalImageUrl})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                    <Layer
                        width={imageWidthToSet}
                        height={imageHeightToSet}
                        // @ts-ignore
                        ref={layerRef}
                        fill={paintColor}
                    >
                    </Layer>
                </Stage>
            </div>
            <div style={{ width:"100%", height:"230px", borderRadius: '2rem 2rem 0 0', marginTop: '1rem', boxShadow: '0px 0px 4px inset #4C5DD4', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <span style={{}}>
                    <DragHandleIcon style={{ color: 'blue' }} />
                </span>
                <div style={{ backgroundColor: '#F1F2FC', borderRadius: '1.5rem', margin: '.2rem', width: '88%', paddingBottom: '.0rem', marginBlock: '.5rem' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingTop: '.3rem', paddingLeft: '15px', paddingRight: '15px' }}>
                        <span style={{ fontWeight: '500', fontSize: '1rem', fontStyle: 'normal', letterSpacing: '-0.03cm' }}>Erase Object</span>
                        {/* <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <VisibilityIcon style={{ color: '#4C5DD4', fontSize: '13px' }} />
                            <span style={{ fontSize: '8px', color: '#4C5DD4' }}>Show Original</span>
                        </span> */}
                    </div>
                    {/* <hr ></hr> */}
                    <div style={{ borderBottom: '1px solid #4C5DD4', marginTop: '10px', marginBottom: '0.4rem' }} ></div>
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '0.5rem', padding: '0px 20px' }}>
                            <div style={{display:"flex",alignItems:"center",gap:"10px"}}   >
                                <span style={{borderRadius:"50%",backgroundColor:"#909BE6", width:"10px",height:"10px"}}></span>
                            <Sliderr brushWidth={brushWidth} Color='#4C5DD4' />
                            <span style={{borderRadius:"50%",backgroundColor:"#909BE6", width:"18px",height:"18px",marginTop:"-3px"}}></span>
                            </div>
                            <div  style={{  display: 'flex', alignItems: 'center', gap: '17px' }}>
                                <span onClick={() => {
                                setSelectedTool('brush');
                                isEraserMode.current = false
                            }}
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' ,  borderBottom: selectedTool === 'brush' ? '2px solid rgb(106 118 204)' : 'none'}}>
                                    <img src={brush} alt="eraser" style={{ color: '#4C5DD4', height: '27px', width: '27px' }} />
                                    {/* <span style={{fontSize:'9px', color:'#4C5DD4'}}>Brush</span> */}
                                </span>
                                <span onClick={() => {
                                    setSelectedTool('eraser');
                                    isEraserMode.current = true
                                }}
                                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',borderBottom: selectedTool === 'eraser' ? '2px solid rgb(106 118 204)' : 'none' }}>
                                    <img   src={eraser} alt="eraser" style={{ color: '#4C5DD4', height: '27px', width: '27px' }} />
                                    {/* <span style={{fontSize:'9px', color:'#4C5DD4'}}>Erase</span> */}
                                </span>
                                {/* <span  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={redo} alt="eraser" style={{ color: '#4C5DD4', height: '15px', width: '15px' }} />
                                    <span style={{ fontSize: '8px', color: '#4C5DD4' }}>Redo</span>
                                </span>
                                <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <img src={undo} alt="eraser" style={{ color: '#4C5DD4', height: '15px', width: '15px' }} />
                                    <span style={{ fontSize: '8px', color: '#4C5DD4' }}>Undo</span>
                                </span> */}
                            </div>
                        </div>
                        <div style={{display:"flex" ,gap:"20px", justifyContent:"center",alignItems:"center"}}>   
                        <div  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            {/* <Button variant="contained" size="medium" style={{width:'4.8rem', height:'3.2rem',backgroundColor:'#F1F2FC', fontSize:'.35em', marginLeft:'.7rem', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', border:'1px solid blue' }}>
                            <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                              <span><UndoIcon style={{color:'#4C5DD4'}}/></span>
                              <span style={{display:'inline-block'}}>Full Screen</span>
                            </div>
                          </Button> */}
                            <Button onClick={submitSelectedMask} variant="contained" size="large" style={{ fontSize: '.6rem', height: '2.4rem', width: '11rem', backgroundColor: '#4C5DD4', color: 'white', fontWeight: 'bold', borderRadius: '8px', marginTop: '1.2rem' }}>
                                Remove Furniture</Button>
                            <p style={{ fontSize: '10px', color: 'grey', marginTop: '3px' }}>Erase the selected Furniture</p>
                        </div>
                        { canSaveChanges && <div onClick={()=>{
                              imageContext.setSelectedImage(finalImageUrl)
                              imageContext.setScanario("visualiseMyFurniture");
                        }} style={{display:"flex", marginBottom:"12px", borderRadius:"10px",alignItems:"center", fontSize:"13px",flexDirection:"column",gap:"-5px", color:"#4356CB",border:"1px solid #4356CB", padding:"3px 12px" }}>
                            <CheckIcon style={{fontSize:"18px"}}/>
                            <span style={{marginTop:"-3px"}}>
                             Save Changes
                            </span>
                       </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ObjectEraser