import React, { useContext, useState } from 'react'
import { Button, Theme, makeStyles, useTheme } from '@material-ui/core'
import { ReactComponent as UploadIcon } from '../../../assets/svg/UploadFile.svg';
import PublishIcon from '@material-ui/icons/Publish';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import ImagesDBDataContext from './PaintMyWallDBData.context';
import CaptureImageLibrary from './captureImage.component';
import { decreaseImageResolution } from '../../../services/image.service';

const useStyles = makeStyles((theme: Theme) => ({
    root : {
        width : '100%',
        height : '100%',
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        // filter : 'drop-shadow(0px 15px 15px)'
    },
    body : {
        width : '90%',
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        border : '1px solid #5675E76E',
        flexDirection : 'column',
        borderRadius : '16px',
        boxShadow : '0px 10px 10px 1px #aaaaaa55'
        // filter : 'drophadow(0px 15px 15px)'
    },
    uploadIcon : {
        width : '100px',
        height : '100px',
        color : '#5675E76E',
    },
    title: {
        height : '100px',
        margin : '20px',
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
    },
    iconArea:{
        margin : '15px'
    },
    actionButtons : {
        display: 'flex',
        justifyContent: 'space-evenly',
        margin: '40px 20px',
        width: '100%',
    },
    buttons : {
        fontSize : '10px',
        width : '40%',
        cursor : 'pointer',
    }
}))
function ImageTakeAndUploadSection(props:{isObjectUpload?:boolean}) {
    const {isObjectUpload} =props;
    const classes = useStyles();
    const imageContext = useContext(ImagesDBDataContext)
    const [img, setImg] = useState<string[]>([]);
    const [openCamera, setOpenCamera] = useState<boolean>(false);

    const uploadImagePopup = () => {
        let input = document.createElement('input');
        input.setAttribute("accept","image/*");
        input.setAttribute("multiple","");
        input.type = 'file';
        input.onchange = async (_) => {
          // you can use this method to get file and perform respective operations
                  if(input.files){
                    let files =   Array.from(input.files);
                    for(let i = 0; i < files.length; i++) {
                        setImg(oldData => [...oldData ,URL.createObjectURL(files[i])]);
                        if(!isObjectUpload){
                            await imageContext.setImages((oldState) => {return [...oldState, {url : URL.createObjectURL(files[i])}]})
                        }else{
                        //   files[i]=await decreaseImageResolution(files[i])
                         await imageContext.setObjectImages((oldState)=>{return [...oldState,{url:URL.createObjectURL(files[i]),file:files[i]}]})
                      }
                    //   image.setImages(oldData => [...oldData , 
                    //     {url : URL.createObjectURL(files[i])}
                    //     ])
                    }
                    // setIsUploadingFromLocal(true)
                    imageContext.setScanario(!isObjectUpload?'image-preview':'reimagination')
                  }
              };
        input.click();
        // imageContext.setSelectedImage(imageContext.images[0].url)
      }

    const onCaptureImageClick = () => {
        setOpenCamera(true);
    }
  return (
    <div className={classes.root}>
        <div className={classes.body}>
            <div style={{margin:"30px 60px"}} className={classes.title}>
                <p style={{textAlign : 'center'}}>{isObjectUpload?"Upload / Take the photo of the furniture to it in your room   ":"Upload / Take the photo of the wall to view different color options"} </p>
            </div>
            <div className={classes.iconArea}>
                <svg className={`${classes.uploadIcon} MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv`} focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="UploadFileIcon" ><path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"></path></svg>            
            
            </div>
            <div className={classes.actionButtons}>
                <Button variant='outlined' startIcon={<PublishIcon/>} className={classes.buttons} style={{border: '1px solid #5675E76E', color : '#5675E7',}} onClick={() => uploadImagePopup()}> Upload</Button>
                <Button variant='contained' startIcon={<PhotoCameraIcon />} className={classes.buttons} style={{color :'#ffffff', backgroundColor : '#5675E7'}} onClick={onCaptureImageClick}>Take a Photo</Button>
            </div>
            {
                openCamera && 
                <CaptureImageLibrary isObjectUpload={isObjectUpload as boolean} openCamera={openCamera} exitCamera={() => setOpenCamera(false)}/>
            }
        </div>
    </div>
  )
}

export default ImageTakeAndUploadSection
