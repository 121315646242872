import { Button, Menu, MenuItem, CircularProgress, makeStyles, Theme, Tooltip } from '@material-ui/core';
import React, { BlockquoteHTMLAttributes, useCallback } from 'react'
import { useEffect , useContext, useRef, useState} from "react";
import { Stage, Layer, Image, Transformer, Group } from "react-konva";
import html2canvas from 'html2canvas';
import useImage from "use-image";
import ImagesDBDataContext from '../PaintMyWall/Home/PaintMyWallDBData.context';
import WallPaintingApiService from '../../services/WallPaintingApiService';
import ImageService from '../../services/image.service';
import GestureIcon from '@material-ui/icons/Gesture';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import ClearAllRoundedIcon from '@material-ui/icons/ClearAllRounded';
import { TwoDimensionalImage } from '../../assets/components/react-annotation-tool/src/apps';
import { getDefaultAnnotationsToMapInAnnotationComponentIfModelTypeImageGroupAnnotation, setResourceImageAnnotationsForSendingToAnnotationComponent, setResourceImageGroupAnnotationsForSendingToAnnotationComponent } from '../../services/imageAnnotationHelperService';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PublishIcon from '@material-ui/icons/Publish';
import DoneIcon from '@material-ui/icons/Done';
import Skeleton from '@material-ui/lab/Skeleton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { ImagesType, Wall } from '../../types/image.type';
import ObjectAdvancedMode from './ObjectAdvancedMode.component';
import InfoIcon from '@material-ui/icons/Info';
import LoadingBar from './loading_bar';
//import LinearProgress from '@material-ui/material/LinearProgress';
import axios from 'axios';
import { object } from 'prop-types';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import BrushIcon from '@material-ui/icons/Brush';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import AutoFixOffIcon from '@material-ui/icons/AutoFixOff';
import UndoIcon from '@material-ui/icons/Undo';
import RedoIcon from '@material-ui/icons/Redo';
// import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import eraser from './eraser.svg'; 
import undo from './undo.svg';
import redo from './redo.svg';
import brush from './brush.svg';
import ObjectEraser from './ObjectErase.component';

function Slider(Color:any) {
  const [value, setValue] = useState(50); // Initial value of the slider
  const handleChange = (event:any):void => {
    setValue(event.target.value);
  };
  return (
      <input
        type="range"
        min="0"
        max="100"
        step="1"
        value={value}
        onChange={handleChange}
        color={Color}
        style={{ width: '50%' }}
      />
  );
}

const lightColorCodes = [
  "#FFCCCC",
  "#FFE5CC",
  "#FFFFCC",
  "#E5FFCC",
  "#CCFFCC",
  "#99FFCC",
  "#CCFFFF",
  "#CCE5FF",
  "#FFCCFF",
  "#FFCCE5",
  "#FFF8DC",
  "#FFFF00"
]

type Images = {
  src: string,
  id: string,
  x: number,
  y: number,
  width: number,
  height: number,
  scaleX?:number,
  scaleY?:number,
  rotation?:number
}

const useStyles = makeStyles((theme: Theme) => ({
  ImageArea : {
    height : '100%',
    width :'calc(100% - 80px)',
    // padding : '0px 20px',
    display: 'flex',
    justifyContent : 'center',
    alignItems : 'center',
  },
  buttons : {
    fontSize : '12px',
    width : '150px',
    cursor : 'pointer',
    borderRadius:"7px"
},

}))

const URLImage = (props : { shapeProps : Images, isSelected : boolean, onSelect : () => void, onChange : (data : Images) => void, selectedIndex ?: number, updateCanvasContextMenuState : React.Dispatch<React.SetStateAction<{
  isOpen: boolean;
  xCoordinate?: number | undefined;
  yCoordinate?: number | undefined;
  selectedIndex?: number | undefined;
} | undefined>> }) => {
  const [img] = useImage(props.shapeProps.src);
  const {scaleX,scaleY}=props.shapeProps;
  const shapeRef = React.useRef<any>();
  const [currentPosition, setcurrentPosition] = useState({x:0,y:0})
  const trRef = React.useRef<any>();
  // console.log("all the props :: ", props.shapeProps, props.isSelected, props.onSelect, props.onChange);
   

  useEffect(() => {
    if(shapeRef.current){
      // @ts-ignore
       setcurrentPosition({x:shapeRef.current.x(),y:shapeRef.current.y()})  
    }
  }, [])
  

  React.useEffect(() => {
    if (props.isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [props.isSelected,shapeRef]);

  return (
    <React.Fragment>
      <Image
        image={img}
        // x={image.x}
        // y={image.y}
        onClick={props.onSelect}
        onTap={props.onSelect}
        //@ts-ignore
        ref={shapeRef}
        {...props.shapeProps}
        // I will use offset to set origin to the center of the image
        draggable={true}
        resize={true}
        onDragEnd={(e) => {
          console.log("drag end",e.target.x() ,e.target.y())
          props.onChange({
            ...props.shapeProps,
            x: e.target.x(),
            y: e.target.y()
          });
          setcurrentPosition({x:e.target.x(),y:e.target.y()})
        }}
        onTouchEnd={(e) => {
          props.onChange({
            ...props.shapeProps,
            x: e.target.x(),
            y: e.target.y()
          });
          setcurrentPosition({x:e.target.x(),y:e.target.y()})
        }}
        onContextMenu={(event) => {
          event.evt.preventDefault();
          const timer = setTimeout(() => {
            props.updateCanvasContextMenuState({
            isOpen: true,
            xCoordinate: event.evt.offsetX,
            yCoordinate: event.evt.offsetY -10,
            selectedIndex: props.selectedIndex
          })
          // setContextMenuVisible(true);
          // setContextMenuPosition({ x: offsetX, y: offsetY });
          }, 800); // Adjust the duration as needed for long tap recognition

          const handleTouchEnd = () => {
          clearTimeout(timer);
          };

          document.addEventListener('touchend', handleTouchEnd, { once: true });
        }}
        // onTransform={(evt) => {
        //   console.log(evt);
        // }}
        onTransformEnd={(e) => {
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const { width, height, x, y ,scaleX,scaleY,rotation} = e.target.attrs;
          console.log("width,height",width, height,e.target.attrs);
          props.onChange({
            ...props.shapeProps,
            x:x,
            y: y,
            width: width,
            height: height,
            scaleX:scaleX,
            scaleY:scaleY,
            rotation:rotation
          });
          
        }}
      />
      {props.isSelected && ( 
        <Transformer
        //@ts-ignore
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            // console.log("oldbox", oldBox);
            // console.log("newBox :: ", newBox);
            if ((newBox.width / oldBox.width).toFixed(2) !== (newBox.height / oldBox.height).toFixed(2)) {
              return oldBox;
            }
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
        )}
        
    </React.Fragment>
  );
};


 // Drawing state
 let latestPoint : any;
 let drawing = false;
 let canvas : HTMLCanvasElement;
 let newCanvas : HTMLCanvasElement

function ImageSuperimpositionPOC() {
  const classes = useStyles();
  const dragUrl = React.useRef<string>();
  const stageRef = React.useRef();
  const layerRef = React.useRef();
  const [images, setImages] = React.useState<Images[]>([]);
  // const shapeRef = React.useRef();
  const [selectedId, selectShape] = React.useState<string | null>(null);
  // const [selectedId, selectShape] = React.useState(null);
  // const [img , setImg] = React.useState<string[]>([]);
  // const [objects, setObjects] = React.useState<string[]>([]);
  // const [selectedBackground, setSelectedBackground] = React.useState<string>('');
  const [contextMenu, setContextMenu] = React.useState<{
    isOpen : boolean, 
    xCoordinate?: number; 
    yCoordinate?: number;
    selectedIndex?: number;}>();
  const imageContext = useContext(ImagesDBDataContext);
  const {objectImages}=imageContext;
  const [selectedImageUrl, setSelectedImageUrl] = React.useState<string>(imageContext.selectedImage)
  const [loadingImages, setLoadingImages] = React.useState<boolean>(false);
  const [scenario , setScenario ]= React.useState<"addingObject" | "erasingObject" | "furnitureImageAnnotation"| "brushEraser">(imageContext.furnitureOptions);

  const [ imageWidthToSet , setImageWidthToSet ] = useState<number>(0);
  const [ imageHeightToSet , setImageHeightToSet ] = useState<number>(0);
  const [scalingFactor, setScalingFactor] = React.useState<number>(0);
  const canvasElementRef = useRef<HTMLCanvasElement>();
  const imageContainerRef = useRef<HTMLDivElement>();
  const [windowWidth, setwindowWidth] = useState(0)
  const [ imageWidthAndHeight , setImageWidthAndHeight ] = useState<{width : number , height : number}>({width : 0, height : 0})
  // const [maskedImage, setMaskedImage ]= useState<string>('');
  const [loadingErasedImage, setLoadingErasedImage ]= useState<boolean>(false);
  const [allObjectData, setAllObjectData] = React.useState<ImagesType[]>([]);
  const [selectedObjectUrl, setselectedObjectUrl] = useState("");
  const [advancedModePopupForObjects, setAdvancedModePopupForObjects] = useState<{open : boolean, selectedObjectUrl ?: string}>({open : false})
  const [loadingStatus, setLoadingStatus] = React.useState<boolean[]>([]);
  const [isRemovingSelectedFurniture, setIsRemovingSelectedFurniture] = useState<boolean>(false)
  const [removeFurnitureAnnotatedScreen, setremoveFurnitureAnnotatedScreen] = useState<boolean>(false);
  const [walldata, setWalldata] = useState<any>([]);
  const [maskUrl, setMaskUrl] = useState<string>("");
  const [imageCoordinates, setImageCoordinates] = useState<string[]>([]);
  const [canvasWidth, setCanvasWidth] = useState(window.innerWidth-40);
  const [canvasHeight, setCanvasHeight] = useState(window.innerHeight-400);
  const [imageDifference, setImageDifference] = useState(0);
  const [removeFurnitureFirstScreen, setRemoveFurnitureFirstScreen] = useState<boolean>(false);
  const [annotationApiReceived, setAnnotationApiReceived] = useState<boolean>(false);
  const [isBlurred, setIsBlurred] = useState<boolean>(false);
  // const [parentDivHeightForMargin, setParentDivHeightForMargin] = useState<number>(0);
  // const [imageHeightForMargin, setImageHeightForMargin] = useState<number>(0);
  // const [infoTextForImage, setInfoTextForImages] = useState<string>("Tap on the Object you wish to remove");

  useEffect(() => {
    if(scenario==="erasingObject"){
      let canvas = document.getElementById("canvas");
      // @ts-ignore
      let context = canvas?.getContext("2d");
      make_base(context);
    }
  }, []);
     

    function generateRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

    // @ts-ignore
    function make_base(context:any) {
    let base_image:any = new window.Image();
    base_image.src = selectedImageUrl;
    base_image.onload = function () {
      let imageWidth = base_image.width;
      let imageHeight = base_image.height;
      console.log("specs are : ", imageWidth, ',', imageHeight);
      let differenceBetweenCanvasAndImageToSet: number;
      let canvasHeightToSet: number;
      let canvasWidthToSet: number;
      if (imageWidth>imageHeight) {
        differenceBetweenCanvasAndImageToSet = imageWidth / canvasWidth;
        canvasHeightToSet = imageHeight / differenceBetweenCanvasAndImageToSet;
      } else if (imageHeight > imageWidth) {
        differenceBetweenCanvasAndImageToSet = imageHeight / canvasHeight;
        canvasWidthToSet = imageWidth / differenceBetweenCanvasAndImageToSet;
      }
      // @ts-ignore
      if (differenceBetweenCanvasAndImageToSet) {
        setImageDifference(differenceBetweenCanvasAndImageToSet);
      }
      // @ts-ignore
      if (canvasHeightToSet) {
        setCanvasHeight(canvasHeightToSet);
      }
      // @ts-ignore
      if (canvasWidthToSet) {
        setCanvasWidth(canvasWidthToSet);
      }
      // @ts-ignore
      context.canvas.width = canvasWidthToSet || canvasWidth;
      // @ts-ignore
      context.canvas.height = canvasHeightToSet || canvasHeight;
      // context.canvas.height = "auto";
      // context.drawImage(base_image, 0, 0, base_image.width, base_image.height);
    };

    console.log("the specs are ", canvasHeight, canvasWidth);
  }

  // const uploadBackgroundImagePopup = () => {
  //   let input = document.createElement('input');
  //   input.setAttribute("accept","image/*");
  //   input.setAttribute("multiple","");
  //   input.type = 'file';
  //   input.onchange = async (_) => {
  //     // you can use this method to get file and perform respective operations
  //             if(input.files){
  //               let files =   Array.from(input.files);
  //               for(let i = 0; i < files.length; i++) {
  //                 setImg(oldData => [...oldData ,URL.createObjectURL(files[i])]);
  //               }
  //             }
  //         };
  //   input.click();
  // }
  
  
  // console.log("objectImages ",typeof(objectImages[0].url));

   const colour = "#3d34a5aa";
    const strokeWidth = 10;
    
   
    useEffect(() => {
        setScenario(imageContext.furnitureOptions)
    }, [imageContext.furnitureOptions])
    


    // useEffect(() => {
    //   // calculateWidthAndHeightRef.current()
    //   if(scenario === 'erasingObject' && !loadingErasedImage){
    //   canvas = document.getElementById("canvas") as HTMLCanvasElement;    
    //   newCanvas = document.createElement('canvas');  
    //   newCanvas.width = canvas.width;
    //   newCanvas.height = canvas.height
    //   newCanvas.style.backgroundColor = "#000000";
    //   }
    // }, [scenario, loadingErasedImage])
    
      

  function calculateWidthAndHeight () {
    let base_image : HTMLImageElement = new window.Image();
    base_image.src = selectedImageUrl;
    setwindowWidth(window.innerWidth)
    base_image.onload = () => {
      console.log('base image :: ', base_image.width, base_image.height, imageContainerRef.current?.offsetWidth)  
      const imageWidth = base_image.width || 0;
      const imageHeight = base_image.height || 0;
      
      // newCanvas.setAttribute("width", `${imageWidth}`);
      // newCanvas.setAttribute("height", `${imageHeight}`);
          const imageAspectRatio = imageWidth / imageHeight;
          setImageWidthAndHeight({width: imageWidth, height: imageHeight})

          let imageWidthToSet: number = 0;
          let imageHeightToSet: number = 0;
          //@ts-ignore
          const imageMaxWidthAllowed = imageContainerRef.current?.offsetWidth -20 ||  window.innerWidth || 0;
          // 0.81*(window.innerWidth - (window.innerWidth > 900 ?  200 : 50));
          const imageMaxHeightAllowed = (imageContainerRef.current?.offsetHeight && imageContainerRef.current?.offsetHeight ) ||  window.innerHeight || 0;
          // 0.81*(window.innerHeight - (window.innerWidth > 900 ? 300 : 150));
          console.log("max width allowed and max height allowed", imageMaxWidthAllowed, imageMaxHeightAllowed, imageWidth, imageHeight);
    
          if (imageWidth >= imageHeight) {
            imageWidthToSet = imageMaxWidthAllowed 
            imageHeightToSet = imageWidthToSet / imageAspectRatio;
            imageHeightToSet = ImageService.getImageHeight(
              imageWidthToSet,
              imageAspectRatio
            );
    
            if (imageHeightToSet > imageMaxHeightAllowed) {
              imageHeightToSet = imageMaxHeightAllowed;
              imageWidthToSet = ImageService.getImageWidth(
                imageHeightToSet,
                imageAspectRatio
              );
            }
          } else if (imageHeight > imageWidth) {
            imageHeightToSet = imageMaxHeightAllowed;
            imageWidthToSet = imageAspectRatio * imageHeightToSet;
            imageWidthToSet = ImageService.getImageWidth(
              imageHeightToSet,
              imageAspectRatio
            );
    
            if (imageWidthToSet > imageMaxHeightAllowed) {
              imageWidthToSet = imageMaxWidthAllowed; 
              imageHeightToSet = ImageService.getImageHeight(
                imageWidthToSet,
                imageAspectRatio
              );
            }
          }
          setImageWidthToSet(imageWidthToSet);
          setImageHeightToSet(imageHeightToSet);
          console.log('calculateHandW', imageWidthToSet, imageHeightToSet);
          // setImageHeightForMargin(imageHeightToSet);
          setScalingFactor(imageWidthToSet/imageWidth);
        }
    }
    // executeFunction()
    // },[selectedImageUrl])

    const calculateWidthAndHeightRef = useRef(calculateWidthAndHeight);
    calculateWidthAndHeightRef.current = calculateWidthAndHeight;
    
    // useEffect(() => {
    //     calculateWidthAndHeightRef.current()
    // },[selectedImageUrl])

    useEffect(() => {
       calculateWidthAndHeightRef.current()
        window.addEventListener('resize', calculateWidthAndHeightRef.current, false)
    },[])

    // useEffect(() => {
    //     calculateWidthAndHeightRef.current()
    // },[])

  
    
    // Drawing functions
    
    const continueStroke = (newPoint : any) => {
      console.log('latest point is  : ', latestPoint, newPoint)
      const context = canvas && canvas.getContext("2d");
      const context2 = newCanvas.getContext("2d");
      if(context){
        context.beginPath();
        context.moveTo(latestPoint[0], latestPoint[1]);
        context.strokeStyle = colour;
        context.lineWidth = strokeWidth;
        context.lineCap = "round";
        context.lineJoin = "round";
        context.lineTo(newPoint[0], newPoint[1]);
        context.stroke();
      }
    
      if(context2){
        context2.beginPath();
        context2.moveTo(latestPoint[0], latestPoint[1]);
        context2.strokeStyle = "#ffffff";
        context2.lineWidth = strokeWidth;
        context2.lineCap = "round";
        context2.lineJoin = "round";
        context2.lineTo(newPoint[0], newPoint[1]);
        context2.stroke();
      }
    
      latestPoint = newPoint;

    };
    
    // Event helpers

    
    const startStroke = (point : any) => {
      drawing = true;
      latestPoint = point;
      console.log("point is  :: ", point)
    };
    
    const getTouchPoint = (evt : any) => {
      if (!evt.currentTarget) {
        return [0, 0];
      }
      const rect = evt.currentTarget.getBoundingClientRect();
      const touch = evt.targetTouches[0];
      return [touch.offsetX - rect.left, touch.offsetY - rect.top];
    };
    
    const BUTTON = 0b01;
    const mouseButtonIsDown = (buttons : any) => (BUTTON & buttons) === BUTTON;
    
    // Event handlers
    
    const mouseMove = (evt : any) => {
      console.log("moving.....")
      if (!drawing) {
        return;
      }
   
      console.log(' mouse event is : ', evt)
      continueStroke([evt.offsetX, evt.offsetY]);
    };
    
    const mouseDown = (evt : any) => {
      if (drawing) {
        return;
      }
      evt.preventDefault();
      canvas.addEventListener("pointermove", mouseMove, false);
      console.log("logs 2 :: ", [evt.offsetX, evt.offsetY], evt);
      startStroke([evt.offsetX, evt.offsetY]);
    };
    
    const mouseEnter = (evt: any) => {
      if (!mouseButtonIsDown(evt.buttons) || drawing) {
        return;
      }
      mouseDown(evt);
    };
    
      function generateRandomLightColor() {
        var letters = 'BCDEF'.split('');
        var color = '#';
        for (var i = 0; i < 6; i++ ) {
            color += letters[Math.floor(Math.random() * letters.length)];
        }
        return color;
        return "hsl(" + 360 * Math.random() + ',' +
        (25 + 70 * Math.random()) + '%,' +
        (85 + 10 * Math.random()) + '%)'
      return lightColorCodes[Math.floor(Math.random()*lightColorCodes.length)]
      }

    const endStroke = (evt: any) => {
      if (!drawing) {
        return;
      }
      drawing = false;
      let tempCanvas = newCanvas
      console.log('newCanvas value is :: ', newCanvas)
      // var img = newCanvas.toDataURL("image/jpeg");
      // console.log("image is ::", img);
      // setMaskedImage(img)
      newCanvas = tempCanvas
      evt.currentTarget.removeEventListener("mousemove", mouseMove, false);
    };
    
    const touchStart = (evt: any) => {
      if (drawing) {
        return;
      }
      console.log('event to pass : ', evt)
      startStroke(getTouchPoint(evt));
      // evt.preventDefault();
    };
    
    const touchMove = (evt : any) => {
      if (!drawing) {
        return;
      }
      console.log("touch move is working")
      continueStroke(getTouchPoint(evt));
    };
    
    const touchEnd = (evt : any) => {
      console.log("Canvas :: ", canvas);
      drawing = false;
    
      // var img = newCanvas.toDataURL("image/png");
      // console.log("image is ::", img);
      // setMaskedImage(img)
    };

    const getUrl = (gcsLink:string) => {
     return new Promise((resolve,reject)=>{
      const GCSLink = gcsLink; // Replace with your GCS link
      const fileName = 'downloaded_photo.jpg'; // Specify the desired file name
      // Send a GET request to the GCS link
      
      axios({
        url: GCSLink,
        method: 'GET',
        responseType: 'blob', // Set the response type to 'blob' to handle binary data
      }).then((response) => {
        // Create a URL for the blob data
        const url = window.URL.createObjectURL(new Blob([response.data]));
        resolve(url)
      });
     })  
    };

   


    const uploadObjectImagePopup = async () => {
      // let input = document.createElement('input');
      // input.setAttribute("accept","image/*");
      // input.setAttribute("multiple","");
      // input.type = 'file';
      // input.onchange = async (_) => {
        // you can use this method to get file and perform respective operations
                if(objectImages.length){
                  // let files =   Array.from(input.files);
                  const newAllObjectData: ImagesType[] = [];
                  const newLoadingStatus: boolean[] = [];
                  for(let i = 0; i < objectImages.length; i++) {
                    if(objectImages[i].file){
                      newAllObjectData.push({url:objectImages[i].url as string,loading:true,progress:0})
                      newLoadingStatus.push(true);
                    }else{
                      newAllObjectData.push(objectImages[i].data as ImagesType);
                      newLoadingStatus.push(false);
                    }
                  }       
                  setAllObjectData(newAllObjectData);
                  setLoadingStatus(newLoadingStatus);
                  setLoadingImages(true);
                  for(let i = 0; i < objectImages.length; i++) {
                    let newFormData = new FormData();
                   if(objectImages[i].file){
                    newFormData.append("image", objectImages[i].file as File);
                    const apiResponse = await WallPaintingApiService.WallReimaginationApi(newFormData);
                    if(apiResponse.data.output_url !==  undefined){
                      // setObjects(oldData => [...oldData, apiResponse.data.output_url as string]);
                      const updatedUrl=await getUrl(apiResponse.data.output_url as string) as string
                      setAllObjectData((prev)=>{
                         const objectIndex=prev.findIndex((object)=>object.url===objectImages[i].url)
                         const newAllObjectData = [...prev]
                         newAllObjectData[objectIndex].data={
                            newUrl : updatedUrl,
                            wall : apiResponse.data.outline_points_list
                          }
                          newAllObjectData[objectIndex].loading=false;
                          newAllObjectData[objectIndex].progress=100;
                          // newLoadingStatus[objectIndex]=false;
                          // setLoadingStatus(newLoadingStatus)
                         return newAllObjectData;
                      })
                      // setAllObjectData(oldData => [...oldData, {url : URL.createObjectURL(objectImages[i].file as File), data : {
                      //   newUrl : updatedUrl,
                      //   wall : apiResponse.data.outline_points_list
                      // }}])
                    }
                   }
                    // console.log('apiresponse is :: ', apiResponse.data)
                    // setObjects(oldData => [...oldData ,URL.createObjectURL(files[i])]);
                  }
                  setLoadingImages(false)
                }
            // };
      // input.click();
    }

    useEffect(()=>{ 
      uploadObjectImagePopup();
    },[objectImages]) 

 
  const randomHex = (length : number) =>
    (
      "0".repeat(length) + Math.floor(Math.random() * 16 ** length).toString(16)
    ).slice(-length);

  const handleScenarioChange = (event: React.MouseEvent<HTMLElement>, newAlignment: "addingObject" | "erasingObject") => {
    setScenario(newAlignment);
  };

  const submitSelectedMask = async() => {
    let bodyFormData = new FormData();
    setLoadingErasedImage(true)
    if(selectedImageUrl){
      const response = await fetch(selectedImageUrl);
      const blob = await response.blob();
      const file = new File([blob], 'image.jpg', {type: blob.type});
      bodyFormData.append("image", file);
    }
    const maskedImageGenerate = newCanvas.toDataURL("image/jpeg");
    if(maskedImageGenerate){
      const response = await fetch(maskedImageGenerate);
      const blob = await response.blob();
      const file = new File([blob], 'image.jpg', {type: blob.type});
      bodyFormData.append("mask", file);
    }
    const apiResponse = await WallPaintingApiService.WallErasingObjectApi(bodyFormData)
    if(apiResponse.data){
      setSelectedImageUrl(apiResponse.data)
    }
    setLoadingErasedImage(false)
    setScenario('erasingObject')
    canvas = document.getElementById("canvas") as HTMLCanvasElement;  
    // calculateWidthAndHeightRef.current()
    setTimeout(() => {
      newCanvas = document.createElement('canvas');  
      newCanvas.width = canvas.width || imageWidthToSet;
      newCanvas.height = canvas.height || imageHeightToSet;
      newCanvas.style.backgroundColor = "#000000";
    }, 1000)  
  }

  const updateObjectAnnotation = 
	  async (data_points : Wall[], selectedObjectUrlEdited : string) => { 
		  if (data_points) {
        let objectIndex:any="";
        setLoadingImages(true)
        setAllObjectData( (prev)=>{
          const newObjectData=[...prev];
          objectIndex =newObjectData.findIndex((object)=>object.url===selectedObjectUrlEdited)
          newObjectData[objectIndex].loading=true
          newObjectData[objectIndex].progress=0
          return newObjectData
        })
        setLoadingStatus((oldData)=>{
          const newLoadingStatus=[...oldData]
          newLoadingStatus[objectIndex]=true;
          return newLoadingStatus;
        })
			  let bodyFormData = new FormData();
        bodyFormData.append('outline_points_list', JSON.stringify(data_points));
        if(selectedObjectUrlEdited){
          const response = await fetch(selectedObjectUrlEdited);
          const blob = await response.blob();
          const file = new File([blob], 'image.png', {type: blob.type});
          bodyFormData.append("image", file);
        }
        bodyFormData.append('edit', 'true');
        const apiResponse = await WallPaintingApiService.WallReimaginationApi(bodyFormData);
        console.log("apiResponse", apiResponse)
        if(apiResponse.data){
         
          const updatedUrl = await getUrl(apiResponse.data.output_url as string) as string
          setAllObjectData( (prev)=>{
            const updatedObjectData=[...prev]
            updatedObjectData[objectIndex].data={
               newUrl : updatedUrl,
               wall : apiResponse.data.outline_points_list,
             }
             updatedObjectData[objectIndex].loading=false;
             updatedObjectData[objectIndex].progress=100
            return updatedObjectData;
         })  
           setLoadingImages(false);
        }
  
          // allObjects.map((object) => {
          //   if(object.url === selectedObjectUrlEdited){
          //     // object.data?.wall= data;
          //     // object.data.  
          //     return {...object, data : {
          //       wall : apiResponse.data.outline_points_list,
          //       newUrl : apiResponse.data.output_url
          //     }}
          //   }
          //   return object
          // })
         
    
          // setAllObjectData((oldData) => [...oldData, {url : selectedObjectUrlEdited, data : {
          //   wall : apiResponse.data.outline_points_list,
          //   newUrl : apiResponse.data.output_url
          // }}])
        
		  
  }}
  //   ,
	//   [allObjectData],
	// );

  const handleOnDragStart=(e:any)=>{
    console.log("touched.............. dragged one")
    dragUrl.current = e.target.src;
  }
   
  const handleDrag=(e:any)=>{
    // e.preventDefault();
            // register event position
            //@ts-ignore
            // stageRef.current.setPointersPositions(e);
            // add image
            let base_image : HTMLImageElement = new window.Image();
            base_image.src = dragUrl.current || '';
            base_image.onload = () => {
              let widthToSet :number=0;
              let heightToSet : number=0;
              const imageAspectRatio = base_image.width / base_image.height;
              const imageMaxWidthAllowed=80
              const imageMaxHeightAllowed=80
              // if(base_image.width <200 && base_image.height < 200){
              //   widthToSet = base_image.width 
              //   heightToSet = base_image.height 
              // }
              // else if(base_image.width>400 ){
              //   widthToSet = base_image.width - 200
              // }
              // heightToSet = base_image.height - 100

              if (base_image.width  >= base_image.height ) {
                widthToSet = imageMaxWidthAllowed 
                heightToSet = widthToSet / imageAspectRatio;
                heightToSet = ImageService.getImageHeight(
                  widthToSet,
                  imageAspectRatio
                );
        
                if (heightToSet > imageMaxHeightAllowed) {
                  heightToSet = imageMaxHeightAllowed;
                  widthToSet = ImageService.getImageWidth(
                    heightToSet,
                    imageAspectRatio
                  );
                }
              } else if (base_image.height > base_image.width) {
                heightToSet = imageMaxHeightAllowed;
                widthToSet = imageAspectRatio * heightToSet;
                widthToSet = ImageService.getImageWidth(
                  heightToSet,
                  imageAspectRatio
                );
        
                if (widthToSet > imageMaxHeightAllowed) {
                  widthToSet = imageMaxWidthAllowed; 
                  heightToSet = ImageService.getImageHeight(
                    widthToSet,
                    imageAspectRatio
                  );
                }
              }
              
              // console.log( ...stageRef.current.getPointerPosition()  ,"position");
                
              // @ts-ignore
                console.log(layerRef.current,stageRef.current)
                 //@ts-ignore
                const canvasWidth=layerRef?.current?.hitCanvas.width
                
                //@ts-ignore
                const canvasHeight=layerRef?.current?.hitCanvas.height;
                const positionX=(canvasWidth/ 2)
                const positionY=(canvasHeight/ 2)
                console.log(widthToSet,heightToSet,"width and height of object",canvasWidth,canvasHeight,"x and y", positionX, positionY,imageWidthToSet,imageHeightToSet)

              setImages(
                images.concat([
                  {
                    //@ts-ignore
                    // ...stageRef.current.getPointerPosition(),
                    src: dragUrl.current,
                    crossOrigin :"anonymous",
                    id: randomHex(13),
                    x:positionX,
                    y: positionY,
                    width: widthToSet,
                    height: heightToSet
                  }
                ])
              );
            }
  }


  const selectFurnitureToRemove = async (xCoordinate: number, yCoordinate: number,  selectedObjectUrlEdited : string) => {
    var bodyFormData = new FormData();
    bodyFormData.append("selected_coordinates_x", Math.round(xCoordinate).toString());
    bodyFormData.append("selected_coordinates_y", Math.round(yCoordinate).toString());
    if(selectedObjectUrlEdited){
      const response = await fetch(selectedObjectUrlEdited);
      const blob = await response.blob();
      const file = new File([blob], 'image.png', {type: blob.type});
      console.log('file is :: ', file)
      bodyFormData.append("image", file);
    }
    const apiResponse = await WallPaintingApiService.WallMagicEraserApi(bodyFormData);
    setAnnotationApiReceived(true);
    // setInfoTextForImages("Tap on the wall you wish to color");
    setremoveFurnitureAnnotatedScreen(true);
    setIsBlurred(false);
    setWalldata(apiResponse.data.outline_points_list);
    setMaskUrl(apiResponse.data.mask_link||"")
    console.log("apiResponse--->remove furniture", apiResponse)
  }

  const handleUploadMoreObject=async()=>{
    if(loadingImages) return ;

    await imageContext.setObjectImages([])
    allObjectData.forEach((objectData)=>{
         imageContext.setObjectImages((prev)=>{return [...prev ,{data:objectData}]})
    })
    imageContext.setScanario("uploadObject")
   
  }
   
 

 const captureScreenshot = () => {
    if(stageRef){
      const stage = stageRef?.current as any;
     const htmlElement=stage.container();
     htmlElement.style.borderRadius=0;
      html2canvas(htmlElement,{ useCORS: true ,allowTaint: true,}).then((canvas) => {
        const dataURL = canvas.toDataURL();
        imageContext.setSelectedImage(dataURL);
        imageContext.setImages([...imageContext.images.filter((image)=>image.url===selectedImageUrl), {url: dataURL}])
        imageContext.setSelectedImage(dataURL)
      });
      // const dataURL = stage.toDataURL();
    }


     setTimeout(()=>{
      if(stageRef){
        const stage = stageRef?.current as any;
        const htmlElement=stage.container();
        htmlElement.style.borderRadius=0;
        html2canvas(htmlElement,{ useCORS: true ,allowTaint: true,}).then((canvas) => {
        const dataURL = canvas.toDataURL();
        imageContext.setSelectedImage(dataURL);
        imageContext.setImages([...imageContext.images.filter((image)=>image.url===selectedImageUrl), {url: dataURL}])
        imageContext.setSelectedImage(dataURL)
        setAllObjectData([]);
        setLoadingStatus([]);
        imageContext.setObjectImages([]);
        imageContext.setScanario("visualiseMyFurniture");
        });
         // const dataURL = stage.toDataURL();
       }
     },5)
     
    
  }


  const handleObjectEditButton=()=>{
    if(loadingImages) return ;
    setImages((prev)=>{
      const updatedImages=[...prev];
      return updatedImages.filter((images)=>images.src!==selectedObjectUrl)
    })
    setScenario("furnitureImageAnnotation")
    imageContext.setFurnitureOptions("furnitureImageAnnotation")
    console.log(".....",selectedObjectUrl)
    setAdvancedModePopupForObjects({open : true, selectedObjectUrl : selectedObjectUrl})
  }

  const handleRemoveFurniture = async (selectedObjectUrlEdited: string) => {
    var bodyFormData = new FormData();
    setIsBlurred(true)
    setIsRemovingSelectedFurniture(true)
    if(selectedObjectUrlEdited){
      const responseImage = await fetch(selectedObjectUrlEdited);
      const blobImage = await responseImage.blob();
      const fileImage = new File([blobImage], 'image.png', {type: blobImage.type});
      bodyFormData.append("image", fileImage);
    }
    if(maskUrl!=="")
    {
      const responseMask = await fetch(maskUrl);
      const blobMask = await responseMask.blob();
      const fileMask = new File([blobMask], 'image.png', {type: blobMask.type});
      bodyFormData.append("mask", fileMask);
    }
    if(selectedObjectUrlEdited!=""&&maskUrl!=="")
    {
      const apiResponse = await WallPaintingApiService.WallErasingObjectApi(bodyFormData);
      const newImageUrl = await getUrl(apiResponse.data||"");
     // imageContext.setImages((oldState) => {return [...oldState, {url : newImageUrl||}]})
     imageContext.setSelectedImage(`${newImageUrl}`);
     imageContext.setImages([...imageContext.images.filter((image)=>image.url===selectedImageUrl), {url: `${newImageUrl}`}])
     imageContext.setSelectedImage(`${newImageUrl}`)
     setSelectedImageUrl(`${newImageUrl}`)
     setremoveFurnitureAnnotatedScreen(false)
     setAnnotationApiReceived(false)
      console.log("removeFurnitureDone",apiResponse)
    }
    setIsBlurred(false)
    setIsRemovingSelectedFurniture(false)
  }

  // useEffect(() => {
  //   const element1 = document.getElementById("canvas"); 
  //   if (element1) {
  //     const rect = element1.getBoundingClientRect();
  //     const top = rect.top; 
  //     const left = rect.left; 
  //     const right = rect.right; 
  //     const bottom = rect.bottom; 
  //     console.log(`Top: ${top}, Left: ${left}, Right: ${right}, Bottom: ${bottom}`); 
  //   } 
  //   if (element1) {
  //     const divHeight = element1.offsetHeight;
  //     console.log("Div height:", divHeight);
  //   } 

  //   // const parentDivHeight = document.getElementById("calHeightNirbhay");
  //   // if(parentDivHeight){
  //   //   console.log("parentDivHeight:", parentDivHeight.offsetHeight);
  //   //   // setParentDivHeightForMargin(parentDivHeight.offsetHeight);
  //   // }
  // }, [] );
 


  return (
    <div style={{width : '100%', height : '100%', display : 'flex', filter: isBlurred ? 'blur(10px)' : 'blur(0)'}}>
      {/* Try to trag and image into the stage: */}
      <br />
      {/* <div style={{width : '10%', height : 'calc(100% - 20px)', padding : '10px'}}>
        <Button onClick={uploadBackgroundImagePopup}>Upload Images </Button>
        <br/>
        <div style={{width : '100%', height : '100%', overflow : 'auto', padding : '10px'}}>
          {img.length > 0 &&
          img.map((imgs) => {
            return <div style={{width : '120px', height : '120px', display: 'flex', justifyContent : 'center', alignItems : 'center'}}>
              <img onClick={() => setSelectedBackground(imgs)} src={imgs} alt='img' style={{height : '100px', width : 'auto', margin : '10px', padding : '10px'}}/>
            </div>
          })}
        </div>
      </div> */}
      <div style={{width : '100%', height : '100%', display : 'flex', flexDirection : 'column'}}>
        {scenario ==='addingObject' && <div style={{height : '22%', width : '100%', display : 'flex', overflow : 'auto', justifyContent : 'center', alignItems : 'center'}}>
          {/* <div style={{display :'flex', justifyContent : 'space-evenly', alignItems : 'center', flexDirection : 'column', height : '100%'}}>
            <Button variant='contained' color='primary' disabled={scenario === 'erasingObject'}  style={{height : '35px', width : '200px'}} onClick={()=>uploadObjectImagePopup()}>Upload Objects </Button>
            <ToggleButtonGroup
              value={scenario}
              exclusive
              onChange={handleScenarioChange}
              aria-label="text alignment"
            >
              <ToggleButton value="addingObject" aria-label="centered">
                <Tooltip title='Add Object'>
                  <GestureIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="erasingObject" aria-label="left aligned">
                <Tooltip title='Erase Object'>
                  <ClearAllRoundedIcon />
                  </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
              <Button variant='contained' color='primary' onClick={submitSelectedMask} style={{height : '35px', width : '200px', visibility : scenario === 'erasingObject' ? 'visible' : 'hidden'}}>Submit selection</Button>
          </div> */}
        {/* <br/> */}
        <div style={{width : '100%',height : '100%', overflow : 'auto',gap:"10px", padding : '0px 10px', display : 'flex', flexDirection :'column'}}>
       <div style={{display:"flex" ,justifyContent:"space-between"}}>
          <span style={{color:"#a7a7a7",fontSize:"14px"}}>Uploaded Images</span>   <span onClick={handleUploadMoreObject} style={{color: loadingImages?"#CED7F8":"#5675E7",fontSize:"14px",textDecoration:"underline", display:"flex", gap:"5px"}}><BackupOutlinedIcon/> Upload more objects</span>
        </div>
          <div style={{width : '100%', height : 'calc(100% - 20px)',gap:"15px" , padding : '10px', display : 'flex',}}>
            
            { allObjectData.map((objectData, index) => {

              return (
              <div className='final-img' style={{width : '70px',height : '50px', marginTop:"-5px",borderRadius:"7px",backgroundColor: !loadingStatus[index]?"#F1F2FC":"transparent", display: 'flex', justifyContent : 'center', alignItems : 'center'}}>
                {
                  !loadingStatus[index]  &&
                    <img src={objectData.data?.newUrl || objectData.url}  alt='img' style={{width : 'auto', height :'60px', maxHeight:"60px", padding : '10px 15px'}} draggable="true"
                  // onDragStart={handleOnDragStart}
                  onTouchStart={(e) => {
                    console.log("touched.............. touch one")
                    //@ts-ignore
                    dragUrl.current = e.target.src;
                    handleDrag(e);
                  }}
                  />}
                  { loadingStatus[index] && <LoadingBar objectIndex={index}  setLoadingStatus={setLoadingStatus} objectData={objectData} staticImgSrc = {objectData.url as string}/>}
              </div>
              )})}
            {/* {scenario === 'erasingObject' && maskedImage.length > 0 &&
              <div style={{width : '120px', height : '120px', display: 'flex', justifyContent : 'center', alignItems : 'center'}}>
                <img src={maskedImage} alt='img' style={{width : '100px', height : 'auto', margin : '10px', padding : '10px'}} draggable="true"/>
              </div>
            } */}
          
          </div>
          {/* <span style={{color :'grey', fontSize : '13px', padding : '0px', margin : '0px'}}><InfoIcon style={{color :'#048d9a', fontSize : '18px', margin : '0px 5px 0px 15px'}}/>Right click on the object for more options</span> */}
        </div>
          {/* <img
            alt="lion"
            src="https://konvajs.org/assets/lion.png"
            draggable="true"
            onDragStart={(e) => {
              //@ts-ignore
              dragUrl.current = e.target.src;
            }}
          /> */}
        </div>
      }
        <div
          style={{ height : scenario==="erasingObject"?'78%' :'100%', width : '100%'}}
          // onDrop={(e) => {
          //   e.preventDefault();
          //   // register event position
          //   //@ts-ignore
          //   stageRef.current.setPointersPositions(e);
          //   let base_image : HTMLImageElement = new window.Image();
          //   base_image.src = dragUrl.current || '';
          //   base_image.onload = () => {
          //     let widthToSet :number=0;
          //     let heightToSet : number=0;
          //     const imageAspectRatio = base_image.width / base_image.height;
          //     const imageMaxWidthAllowed=100
          //     const imageMaxHeightAllowed=100
          //     // if(base_image.width <200 && base_image.height < 200){
          //     //   widthToSet = base_image.width 
          //     //   heightToSet = base_image.height 
          //     // }
          //     // else if(base_image.width>400 ){
          //     //   widthToSet = base_image.width - 200
          //     // }
          //     // heightToSet = base_image.height - 100

          //     if (base_image.width  >= base_image.height ) {
          //       widthToSet = imageMaxWidthAllowed 
          //       heightToSet = widthToSet / imageAspectRatio;
          //       heightToSet = ImageService.getImageHeight(
          //         widthToSet,
          //         imageAspectRatio
          //       );
        
          //       if (heightToSet > imageMaxHeightAllowed) {
          //         heightToSet = imageMaxHeightAllowed;
          //         widthToSet = ImageService.getImageWidth(
          //           heightToSet,
          //           imageAspectRatio
          //         );
          //       }
          //     } else if (base_image.height > base_image.width) {
          //       heightToSet = imageMaxHeightAllowed;
          //       widthToSet = imageAspectRatio * heightToSet;
          //       widthToSet = ImageService.getImageWidth(
          //         heightToSet,
          //         imageAspectRatio
          //       );
        
          //       if (widthToSet > imageMaxHeightAllowed) {
          //         widthToSet = imageMaxWidthAllowed; 
          //         heightToSet = ImageService.getImageHeight(
          //           widthToSet,
          //           imageAspectRatio
          //         );
          //       }
          //     }
              
          //     // console.log( ...stageRef.current.getPointerPosition()  ,"position");
                
          //     console.log(widthToSet,heightToSet,"width and height of object",imageWidthToSet,imageHeightToSet,"x and y", imageWidthToSet>90?300:230, 320)
                
          //     //  //@ts-ignore
          //     // console.log( ...stageRef.current.getPointerPosition()  ,"position");
          //     setImages(
          //       images.concat([
          //         {
          //           //@ts-ignore
          //           ...stageRef.current.getPointerPosition(),
          //           src: dragUrl.current,
          //           id: randomHex(13),
          //           width: widthToSet,
          //           height: heightToSet
          //         }
          //       ])
          //     );
          //   }
          // }}
          // onDragOver={(e) => e.preventDefault()}
          // style={{
          //   backgroundImage: `url("https://konvajs.org/assets/lion.png")`,
          //   backgroundRepeat: "no-repeat"
          // }}
        >
           
          <div style={{width : '100%', height : '100%', position:'relative' }}>
            {/* <img className='canvas__img'
              // ref={ imgRef }
              // key={width}
              height={ window.innerHeight - 200 }
              width='auto'
              style={ { visibility: 'hidden' } }
              // onLoad={ onImgLoad }
              src={ selectedImageUrl }
              alt=''
              /> */}
              {/* {<p style={{
                        position: 'absolute',
                        top: `${(parentDivHeightForMargin - imageHeightForMargin)*0.38 + 26}px`,
                        // top:'0',
                        left: `${ (window.innerWidth - canvasWidth)/2 + 10}px`,
                        // left:'0',
                        // width: `${imageHeightToSet}px`,
                        // width:'100%',
                        // backgroundColor: 'grey',
                        // transform: 'translateY(-100%)',
                        zIndex: 100,
                        fontSize: '11px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap:'7px',
                        justifyContent: 'flex-start',
                        backgroundColor:'red',
                        color: 'white',
                        textAlign: 'center',
                    }}><InfoIcon style={{fontSize:'13px'}}/>{infoTextForImage}</p>} */}

              {scenario==="brushEraser" && <ObjectEraser setIsBlurred={setIsBlurred} selectedImageUrl={selectedImageUrl} imageContainerRef={imageContainerRef} imageWidthToSet={imageWidthToSet} imageHeightToSet={imageHeightToSet}/>}
              {
                scenario === 'erasingObject' &&
                <>         
               
                <div 
                //@ts-ignore
                //position changed................
                ref={imageContainerRef} className={classes.ImageArea} id='calHeightNirbhay' style={{width: '100%', height : annotationApiReceived ? '79%' : "84%"}}>

                  {
                    removeFurnitureAnnotatedScreen === false ?
                    <canvas
                        // @ts-ignore
                        ref={canvasElementRef}
                        // onClick={getClickCoords}
                        
                        width={canvasWidth}
                        height={canvasHeight}
                        id = 'canvas'
                        style={{
                            backgroundImage: `url(${selectedImageUrl})`,
                                // `url(${isToggleEnabledToNewImage ? imageUrlToShow : imageUrlSelected })`,
                            
                            width: canvasWidth,
                            height: canvasHeight, 
                            backgroundSize: `${canvasWidth}px ${canvasHeight}px`,
                            cursor: "crosshair",
                            borderRadius : '10px',
                            position:'relative'
                        }}
                        // onTouchStart={(e) => touchStart(e)}
                        // onTouchEnd={(e) => touchEnd(e)}
                        // onTouchCancel={(e) => touchEnd(e)}
                        // onTouchMove={(e) => touchMove(e)}
                        // onPointerDown={(e) => mouseDown(e)}
                        // onPointerUp={(e) => endStroke(e)}
                        // // onPointerMove={(e:any)=> continueStroke([e.offsetX, e.offsetY])}
                        // // onPointerOut={(e) => endStroke(e)}
                        // onPointerEnter={(e) => mouseEnter(e)}
                        // // onDragMove={(e:any)=>console.log(e,"its working")}
                        // onMouseDown={(e) => mouseDown(e)}
                        // onMouseUp={(e) => endStroke(e)}
                        // onMouseOut={(e) => endStroke(e)}
                        // onMouseEnter={(e) => mouseEnter(e)}

                        onClick={(event) => {
                          console.log("ProjectsList ~ event", event);
                          let canvas = document.getElementById("canvas");
                          // @ts-ignore
                          let context = canvas?.getContext("2d");
                          // @ts-ignore
                          let x = event.clientX - document.getElementById("canvas")?.getBoundingClientRect().left;
                          // @ts-ignore
                          let y = event.clientY - document.getElementById("canvas")?.getBoundingClientRect().top;
                          const randomColor = generateRandomLightColor();
                          context.fillStyle = randomColor;
                          context.beginPath();
                          context.fill();
                          setImageCoordinates((oldValue) => {
                            const newValue = JSON.parse(JSON.stringify(oldValue));
                            newValue.push(`${x},${y},${randomColor}`);
                            console.log(newValue);
                            return [...newValue];
                          });
                        console.log(x, y, "val");
                        let xcor:number = 0;
                        let ycor:number = 0;
                        selectFurnitureToRemove(Number(x*imageDifference), Number(y*imageDifference), selectedImageUrl);
                        console.log(imageDifference, "imagedifference"); 
                        setIsBlurred(true);      
                      }}

                    ></canvas> : 
                       <div style={{
                          marginTop:'2.1rem',
                          cursor: "crosshair",
                          borderRadius : '10px',
                          // position:'relative'
                        }}>
                        <TwoDimensionalImage 
                          url={imageContext.selectedImage}
                          viewMode={true}
                          isViewOnlyMode={true}
                          isLabelOn={false}
                          width = {canvasWidth}
                          defaultAnnotations={setResourceImageAnnotationsForSendingToAnnotationComponent(getDefaultAnnotationsToMapInAnnotationComponentIfModelTypeImageGroupAnnotation(walldata || []), (canvasWidth) / imageWidthAndHeight.width)}
                          defaultAnnotationGroups={setResourceImageGroupAnnotationsForSendingToAnnotationComponent(walldata, (canvasWidth) / imageWidthAndHeight.width)}
                          modelType={'imageAnnotationGroup'}
                          hasSaveButton={false}
                        // scenario = {scenario}
                        />
                      </div>   

                  }
      
                </div>
                <div style={{borderRadius:'2rem 2rem 0 0', marginTop:'1rem', boxShadow: "0px -1px 5px #C8D2F7", display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                    <span style={{}}>
                      <DragHandleIcon style={{color:'blue'}}/>
                    </span>
                    <div style={{ backgroundColor:'#F1F2FC', borderRadius:'1.5rem', margin:'.2rem', width:'88%',paddingBottom:'.0rem', marginBlock:'.5rem'}}>
                      <div style={{display: 'flex', alignItems:'center', justifyContent:'space-between', paddingTop:'.3rem', paddingLeft:'10px', paddingRight:'10px'}}>
                        <span style={{fontWeight:'500', fontSize:'1rem', fontStyle:'normal',letterSpacing:'-0.03cm'}}>Erase Object</span>
                        <span style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                             {removeFurnitureFirstScreen && <><VisibilityIcon style={{color:'#4C5DD4',fontSize:'13px'}}/>
                              <span style={{fontSize:'8px', color:'#4C5DD4'}}>Show Original</span></> }
                            </span>
                      </div>
                      {/* <hr ></hr> */}
                      <div style={{borderBottom:'1px solid #4C5DD4', marginTop:'10px', marginBottom:'1rem'}} ></div> 
                      <div>
                        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', paddingBottom:'1.2rem', padding:'5px'}}>
                          {removeFurnitureFirstScreen === true && <>
                            <Slider Color='#4C5DD4'/>
                            <div style={{width:'50%', display:'flex', alignItems:'center', marginLeft:'35%', gap:'12px'}}>
                              <span style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                                <img src={brush} alt="eraser"  style={{color:'#4C5DD4', height:'25px', width:'25px'}}/>
                                {/* <span style={{fontSize:'9px', color:'#4C5DD4'}}>Brush</span> */}
                              </span>
                              <span style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                                <img src={eraser} alt="eraser"  style={{color:'#4C5DD4', height:'25px', width:'25px'}}/>
                                {/* <span style={{fontSize:'9px', color:'#4C5DD4'}}>Erase</span> */}
                              </span>
                              <span style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                                <img src={redo} alt="eraser"  style={{color:'#4C5DD4', height:'15px', width:'15px'}}/>
                                <span style={{fontSize:'8px', color:'#4C5DD4'}}>Redo</span>
                              </span>
                              <span style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                                <img src={undo} alt="eraser"  style={{color:'#4C5DD4', height:'15px', width:'15px'}}/>
                                <span style={{fontSize:'8px', color:'#4C5DD4'}}>Undo</span>
                              </span>
                            </div>
                          </>}
                          {
                            annotationApiReceived && 
                            <>
                              <div style={{fontWeight:'500', paddingLeft:'10px'}}>
                                Object
                              </div>
                              <div style={{display:'flex', alignItems:'center', justifyContent:'space-around', gap:'15px', paddingRight:'10px'}}>
                                <span style={{display:'flex', alignItems:'center', flexDirection:'column'}}><DeleteIcon style={{color:'red'}}/><span style={{fontSize:'10px', color:'red'}} onClick={() => {
                                  setWalldata([]);
                                  setRemoveFurnitureFirstScreen(false);
                                  setIsBlurred(false);
                                  setremoveFurnitureAnnotatedScreen(false);
                                  setAnnotationApiReceived(false);
                                }}>Delete Selection</span></span>
                                <span  style={{display:'flex', alignItems:'center', flexDirection:'column'}}><EditIcon style={{color:'#4C5DD4'}}/><span style={{fontSize:'10px', color:'#4C5DD4'}} onClick={() => {
                                  
                                  setWalldata([]);
                                  setScenario("brushEraser")
                                  imageContext.setFurnitureOptions("brushEraser");
                                  setAnnotationApiReceived(false);
                                }}>Edit Selection</span></span>
                              </div>
                            </>
                          }
                        </div>
                        <div style={{display:'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
                          {/* <Button variant="contained" size="medium" style={{width:'4.8rem', height:'3.2rem',backgroundColor:'#F1F2FC', fontSize:'.35em', marginLeft:'.7rem', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', border:'1px solid blue' }}>
                            <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                              <span><UndoIcon style={{color:'#4C5DD4'}}/></span>
                              <span style={{display:'inline-block'}}>Full Screen</span>
                            </div>
                          </Button> */}
                          <Button variant="contained" size="large" style={{fontSize:'.7rem', height:'2.4rem',width :'11rem', backgroundColor: annotationApiReceived?'#4C5DD4': "grey", color:'white', fontWeight:'bold', borderRadius:'8px', marginTop:'1.2rem'}}
                          disabled={!annotationApiReceived}
                          onClick={() => 
                          {
                            handleRemoveFurniture(imageContext.selectedImage)
                          }}
                          >Remove Furniture</Button>
                          <p style={{fontSize:'10px', color:'grey', marginTop:'3px'}}>Erase the selected Furniture</p>
                        </div>
                      </div>
                    </div>
              </div>
              </>
              }
              {
                scenario === "furnitureImageAnnotation"&&
                <ObjectAdvancedMode 
                imageUrlSelected={allObjectData.filter(data => data.data?.newUrl === advancedModePopupForObjects?.selectedObjectUrl)[0]?.url || ""} scenario={"BackgroundRemover"}
                imageData={allObjectData.filter(data => data.data?.newUrl === advancedModePopupForObjects?.selectedObjectUrl)[0]}
                onSaveClick={(data: Wall[], selectedObjectUrl: string) => {
                  setScenario("addingObject")
                  imageContext.setFurnitureOptions("addingObject")
                  updateObjectAnnotation(data, selectedObjectUrl);
                  setAdvancedModePopupForObjects({ open: false });
                  selectShape("")
                 } 
                  } />
              }
              { scenario === 'addingObject' &&  
              <div style={{width:"100%",height:"100%",justifyContent:"space-between" , display:"flex",flexDirection:"column"}}>
              <div 
                //@ts-ignore
                ref={imageContainerRef} className={classes.ImageArea} style={{width: '100%', alignItems:"center", maxHeight: "80%"}}
              >      
              <Stage
                width={imageWidthToSet}
                height={imageHeightToSet}
                onClick={()=>selectShape("")}
                style={{ borderRadius: "10px", backgroundImage : `url(${selectedImageUrl})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}
                //@ts-ignore
                ref={stageRef}
              >
                <Layer 
                 width={imageWidthToSet}
                  //@ts-ignore
                 ref={layerRef}
                 height={imageHeightToSet} 
                 >
                  {images.map((image, i) => {
                    return (
                      <URLImage
                        key={i}
                        // image={image}
                        shapeProps={image}
                        isSelected={image.id === selectedId}
                        onSelect={() => {
                          selectShape(image.id);
                          setselectedObjectUrl(image.src)
                          
                        }}
                        onChange={(newAttrs : Images) => {
                          setImages((prev)=>{
                            const oldData=[...prev];
                            oldData[i]=newAttrs
                            return oldData;
                          })
                        }}
                        selectedIndex={image.id === selectedId ? i : undefined}
                        updateCanvasContextMenuState={setContextMenu}
                      />
                    );
                  })}
                </Layer>
              </Stage>
              </div>
              <div style={{border:"1px solid #C8D2F7",boxShadow: "0px -1px 5px #C8D2F7", marginTop:"15px", height:"140px", borderTopLeftRadius:"20px", display:"flex", padding:"20px", borderTopRightRadius:"20px", backgroundColor:"#FFFFFF" , justifyContent:"center",alignItems:"center"}}>
                <div style={{display:"flex", height:selectedId?"100px":"70px" ,width:"100%" ,flexDirection:"column",gap:"0px", padding:"10px", borderRadius:"15px",backgroundColor:"#F1F2FC",justifyContent:"center"}}> 
               
               { selectedId &&  <div style={{display:"flex",justifyContent:"space-between" ,alignItems:"center"}}>
           
                 <p style={{fontSize:"14px"}} >Object</p>
                 <div  style={{display:"flex", gap:"10px"}}>
                  
                <div onClick={()=>{
                 setImages((prev)=>{return prev.filter((image)=>image.id!==selectedId)});
                 selectShape('')
               }} style={{color:"red", textAlign:"center",fontSize:"10px"}}>
                <DeleteIcon style={{fontSize:"13px"}} />
                <p>Remove Selection</p>
                  </div>
                  <div
                  onClick={handleObjectEditButton}
                  style={{color:loadingImages?"#7484CD":"#4A5CD2",textAlign:"center",fontSize:"10px"}}
                  >
                <EditIcon style={{fontSize:"13px",cursor:"pointer"}}/>
                <p>Edit Selection</p>
                  </div>
                </div>
                  </div>
                  }
                  <div style={{display:"flex",justifyContent:"center"}}>

                <Button variant='contained' color='primary' startIcon={<DoneIcon/>} onClick={ async ()=>{
                  if(loadingImages) return ;
                  
                  await selectShape("");
                  captureScreenshot()
                }} className={classes.buttons} style={{color :'#ffffff',fontSize:"11px",padding:"8px 0px",backgroundColor : loadingImages?"#7484CD":'#4255CA'}} >Save Changes</Button>
                </div>
                </div>
              </div>
              </div>
              }
          </div>
        </div>
      </div>
      {/* {
        contextMenu?.isOpen && 
        <CanvasContextMenu 
          open={contextMenu.isOpen}
          selectedIndex={contextMenu.selectedIndex || 0}
          handleOnClose={() => {setContextMenu({isOpen: false})}}
          xCoordinate={contextMenu.xCoordinate || 0}
          yCoordinate={contextMenu.yCoordinate || 0}
          handleHideCoordinatesSettings={() => {selectShape(''); setContextMenu({isOpen: false});}}
          handleDeleteObject={() => {
            let tempObjects = images;
            tempObjects.splice(contextMenu.selectedIndex || 0, 1)
            setImages(tempObjects);
            setContextMenu({isOpen: false});
          }}
        />
      } */}
      {/* {
        advancedModeForObjects.open &&
        <ObjectContextMenu 
          open={advancedModeForObjects.open}
          selectedIndex={advancedModeForObjects.selectedIndex || 0}
          handleOnClose={() => {setAdvancedModeForObjects({open: false})}}
          xCoordinate={advancedModeForObjects.xCoordinate || 0}
          yCoordinate={advancedModeForObjects.yCoordinate || 0}
          handleAdvancedModeClick={() => {setAdvancedModePopupForObjects({open : true, selectedObjectUrl : advancedModeForObjects.selectedObjectUrl})}}
          handleDeleteObject={() => {
            let tempObjects = allObjectData;
            tempObjects.splice(advancedModeForObjects.selectedIndex || 0, 1)
            setAllObjectData(tempObjects);
            setAdvancedModeForObjects({open: false});
          }}
        />
      } */}
      {/* {
        advancedModePopupForObjects.open &&
        <ObjectAdvancedMode
        open={advancedModePopupForObjects.open}
        onClose = {() => {setAdvancedModePopupForObjects({open : false}); setAdvancedModeForObjects({open: false});}}
        imageUrlSelected= {advancedModePopupForObjects?.selectedObjectUrl || ''}
        scenario={'BackgroundRemover'}
        imageData = {allObjectData.filter(data => data.url === advancedModeForObjects?.selectedObjectUrl)[0]} 
        onSaveClick={(data : Wall[], selectedObjectUrl : string) => {updateObjectAnnotation(data, selectedObjectUrl); setAdvancedModePopupForObjects({open : false}); setAdvancedModeForObjects({open: false});} }
        />
      } */}
    </div>
  );
    };

export default ImageSuperimpositionPOC

const CanvasContextMenu = (props : {
	open : boolean,
  selectedIndex : number,
	handleDeleteObject : (index : number) => void,
  handleHideCoordinatesSettings : () => void,
	xCoordinate : number,
	yCoordinate : number,
	handleOnClose : () => void
}) => {
	return (
		<Menu
		open={props.open}
		onClose={()=>{props.handleOnClose()}}
		anchorReference="anchorPosition"
		anchorPosition={
			props.yCoordinate !== null && props.xCoordinate !== null
				? { top: props.yCoordinate, left: props.xCoordinate }
				: undefined
		}
	>
		<MenuItem onClick={()=>props.handleHideCoordinatesSettings()}>Hide Coordinates </MenuItem>
		<MenuItem onClick={()=>props.handleDeleteObject(props.selectedIndex)}>Delete Object</MenuItem>
	</Menu>	
	);
}

const ObjectContextMenu = (props : {
	open : boolean,
  selectedIndex : number,
	handleDeleteObject : (index : number) => void,
  handleAdvancedModeClick : () => void,
	xCoordinate : number,
	yCoordinate : number,
	handleOnClose : () => void
}) => {
	return (
		<Menu
		open={props.open}
		onClose={()=>{props.handleOnClose()}}
		anchorReference="anchorPosition"
		anchorPosition={
			props.yCoordinate !== null && props.xCoordinate !== null
				? { top: props.yCoordinate, left: props.xCoordinate }
				: undefined
		}
	>
		<MenuItem onClick={()=>props.handleAdvancedModeClick()}>Advanced Mode</MenuItem>
		<MenuItem onClick={()=>props.handleDeleteObject(props.selectedIndex)}>Delete Object</MenuItem>
	</Menu>	
	);
}

