import axios, { AxiosResponse, Canceler } from 'axios';
import { processImageApiResponse } from '../types/wallPaintingDBData.type';
import { API } from "./API";


class WallpaintingApiService extends API {
    WallPaintingProcessImageApi (bodyFormData : FormData) : Promise<AxiosResponse<processImageApiResponse>> {
        // return this.api.post(`https://paintmywall-backend-exjsxe2nda-uc.a.run.app/process_image`, bodyFormData, {headers: {'Content-Type': 'multipart/form-data'}})
        return this.api.post(`https://41e4-34-31-236-244.ngrok-free.app/process_image`, bodyFormData, {headers: {'Content-Type': 'multipart/form-data'}})
        // return this.api.post(`https://76a8-34-28-251-21.ngrok-free.app/process_image`, bodyFormData, {headers: {'Content-Type': 'multipart/form-data'}})
    }
    WallMagicEraserApi (bodyFormData : FormData) : Promise<AxiosResponse<processImageApiResponse>> {
        return this.api.post(`https://41e4-34-31-236-244.ngrok-free.app/magic_eraser`, bodyFormData, {headers: {'Content-Type': 'multipart/form-data'}})
    }
    WallReimaginationApi (bodyFormData : FormData) : Promise<AxiosResponse<processImageApiResponse>> {
        return this.api.post(`https://41e4-34-31-236-244.ngrok-free.app/remove_background`, bodyFormData, {headers: {'Content-Type': 'multipart/form-data'}})
    }
    WallErasingObjectApi (bodyFormData : FormData) : Promise<AxiosResponse<string>> {
        return this.api.post(`https://4e45-34-72-90-113.ngrok-free.app/inpaint_image`, bodyFormData, {headers: {'Content-Type': 'multipart/form-data'}})
    }
}

export default new WallpaintingApiService();
