
import React, { useContext } from 'react'
import ImagesDBDataContext from './PaintMyWallDBData.context'
import ShowImages from './ImagePreview/ShowImages.component'

function RecentImages(props:{isObjectUpload?:boolean}) {
  const {isObjectUpload}=props;
    const images = useContext(ImagesDBDataContext)
    
    
  return (
    <div style={{display : 'flex', justifyContent : 'center', alignItems : 'center', width : '100%'}}>
        <div style={{display : 'flex',  justifyContent : 'center', alignItems : 'center', width : '90%', flexDirection : 'column'}}>
            {isObjectUpload? <div style={{margin:"10px 0px",color :'#222628',fontSize : '13px', fontWeight:"bold", width : '100%', textAlign : 'left'}}>
              <span style={{backgroundColor:"#CADFF0",padding:'5px 20px',borderRadius:"10px"}}>
                OBJECT UPLOAD
              </span>
            </div>:
            <div style={{color :'#BABABA', fontSize : '17px', width : '100%', textAlign : 'left'}}>
            Recent
           </div>
            }
            {!isObjectUpload &&<ShowImages />}
        </div>
    </div>
  )
}

export default RecentImages