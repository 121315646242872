import { Wall } from '../types/image.type';
import { ModelAnnotationLabelAvailable, ResourceImageAnnotation, ResourceImageGroupAnnotation } from '../types/imageAnnotation.type';
import { copyByValue } from './variableHelperService';

export type AnnotationOptionChildren = {
  id: string;
  value: string;
  children: [];
};
export type AnnotationOption = {
  id: "0";
  value: "root";
  children: AnnotationOptionChildren[];
};

export type AnnotationComponentSaveClickData = {
  imageGroupAnnotations: Wall[],
  annotations: Wall['imageAnnotations'],
  imageScaleFactor: number
}

export type AnnotationComponentAnnotationChangeData = {
  imageGroupAnnotations: ResourceImageGroupAnnotation[],
  annotations: ResourceImageAnnotation[],
  // imageScaleFactor: number
}

export const defaultAnnotationOptions: AnnotationOption = {
  id: "0",
  value: "root",
  children: [
    // {
    //     id: '12',
    //     value: "burritos",
    //     children: []
    // },
    // {
    //     id: '13',
    //     value: "hashbrown",
    //     children: []
    // },
    // {
    //     id: '14',
    //     value: "Lunch Item",
    //     children: []
    // },
    // {
    //     id: '15',
    //     value: "Sizzli",
    //     children: []
    // },
  ],
};

export function valueExistsInAnnotationLabels(
  valueToCheck: string,
  annotationLabels: ModelAnnotationLabelAvailable[]
) {
  for (const annotationLabel of annotationLabels) {
    if (annotationLabel.value === valueToCheck) {
      return true;
    }
  }
  return false;
}

export function getAnnotationLabel(
  value: string,
  array: ModelAnnotationLabelAvailable[]
): ModelAnnotationLabelAvailable | null {
  for (const arrayItem of array) {
    if (arrayItem.value === value) {
      return arrayItem;
    }
  }
  return null;
}

export function setResourceImageGroupAnnotationsForSendingToAnnotationComponent(
  imageGroupAnnotations?: Wall[], imageScaleFactor?: number
): Wall[] {
  const oldImageGroupAnnotations = copyByValue(imageGroupAnnotations);

  if (Array.isArray(oldImageGroupAnnotations)) {
      for (const imageGroupAnnotation of oldImageGroupAnnotations) {
        if (Array.isArray(imageGroupAnnotation.imageAnnotations)) {
          for (const annotation of imageGroupAnnotation.imageAnnotations) {
              if (annotation?.vertices?.length > 0) {
  
                annotation.groupAnnotationId = imageGroupAnnotation.id;
  
                  for (const vertice of annotation.vertices) {
                    if(imageScaleFactor){
                      vertice.x = vertice.x * imageScaleFactor;
                      vertice.y = vertice.y * imageScaleFactor;
                    }
                  }
              }
          }                          
        }
      }
    }

  return oldImageGroupAnnotations || [];
}

/**
 * @description
 * Converts imageGroupAnnotations vertices from original image to as per compressedImage width and height
 */
export function setResourceImageGroupAnnotationsOfOriginalImageForCompressedImage(
  imageGroupAnnotations: ResourceImageGroupAnnotation[], 
  originalImageWidth: number, 
  originalImageHeight: number, 
  compressedImageWidth: number, 
  compressedImageHeight: number
): ResourceImageGroupAnnotation[] {
  const oldImageGroupAnnotations = copyByValue(imageGroupAnnotations);

  if (Array.isArray(oldImageGroupAnnotations)) {
      for (const imageGroupAnnotation of oldImageGroupAnnotations) {
        if (Array.isArray(imageGroupAnnotation.imageAnnotations)) {
          for (const annotation of imageGroupAnnotation.imageAnnotations) {
              if (annotation?.vertices?.length > 0) {
  
                  for (const vertice of annotation.vertices) {
                      vertice.x = (vertice.x*compressedImageWidth)/originalImageWidth;
                      vertice.y = (vertice.y*compressedImageHeight)/originalImageHeight;
                  }
              }
          }                          
        }
      }
    }

  return oldImageGroupAnnotations;
}

/**
 * @description
 * Converts imageGroupAnnotations vertices from compressed image to as per originalImage width and height
 */
export function setResourceImageGroupAnnotationsOfCompressedImageForOriginalImage(
  imageGroupAnnotations: ResourceImageGroupAnnotation[], 
  originalImageWidth: number, 
  originalImageHeight: number, 
  compressedImageWidth: number, 
  compressedImageHeight: number
): ResourceImageGroupAnnotation[] {
  const oldImageGroupAnnotations = copyByValue(imageGroupAnnotations);

  if (Array.isArray(oldImageGroupAnnotations)) {
      for (const imageGroupAnnotation of oldImageGroupAnnotations) {
        if (Array.isArray(imageGroupAnnotation.imageAnnotations)) {
          for (const annotation of imageGroupAnnotation.imageAnnotations) {
              if (annotation?.vertices?.length > 0) {
  
                  for (const vertice of annotation.vertices) {
                      vertice.x = (vertice.x*originalImageWidth)/compressedImageWidth;
                      vertice.y = (vertice.y*originalImageHeight)/compressedImageHeight;
                  }
              }
          }                          
        }
      }
    }

  return oldImageGroupAnnotations;
}

export function setResourceImageAnnotationsForSendingToAnnotationComponent(
  imageAnnotations: Wall['imageAnnotations'], 
  imageScaleFactor: number
): Wall['imageAnnotations'] {
  imageAnnotations = copyByValue(imageAnnotations);

  if (Array.isArray(imageAnnotations)) {
    for (const annotation of imageAnnotations) {
        if (annotation?.vertices?.length > 0) {
            for (const vertice of annotation.vertices) {
                vertice.x = vertice.x * imageScaleFactor;
                vertice.y = vertice.y * imageScaleFactor;
            }
        }
    }                          
  }

  return imageAnnotations;
}

/**
 * @description
 * Converts imageAnnotations vertices of originalImage to as per compressedImage width and height
 */
export function setResourceImageAnnotationsOfOriginalImageForCompressedImage(
  imageAnnotations: ResourceImageAnnotation[], 
  originalImageWidth: number, 
  originalImageHeight: number, 
  compressedImageWidth: number, 
  compressedImageHeight: number  
): ResourceImageAnnotation[] {
  imageAnnotations = copyByValue(imageAnnotations);

  if (Array.isArray(imageAnnotations)) {
    for (const annotation of imageAnnotations) {
        if (annotation?.vertices?.length > 0) {
            for (const vertice of annotation.vertices) {
              vertice.x = (vertice.x*compressedImageWidth)/originalImageWidth;
              vertice.y = (vertice.y*compressedImageHeight)/originalImageHeight;
            }
        }
    }                          
  }

  return imageAnnotations;
}

/**
 * @description
 * Converts imageAnnotations vertices of compressed image to as per originalImage width and height
 */
export function setResourceImageAnnotationsOfCompressedImageForOriginalImage(
  imageAnnotations: ResourceImageAnnotation[], 
  originalImageWidth: number, 
  originalImageHeight: number, 
  compressedImageWidth: number, 
  compressedImageHeight: number  
): ResourceImageAnnotation[] {
  imageAnnotations = copyByValue(imageAnnotations);

  if (Array.isArray(imageAnnotations)) {
    for (const annotation of imageAnnotations) {
        if (annotation?.vertices?.length > 0) {
            for (const vertice of annotation.vertices) {
              vertice.x = (vertice.x*originalImageWidth)/compressedImageWidth;
              vertice.y = (vertice.y*originalImageHeight)/compressedImageHeight;
            }
        }
    }                          
  }

  return imageAnnotations;
}

export function getDefaultAnnotationsToMapInAnnotationComponentIfModelTypeImageGroupAnnotation(imageGroupAnnotations: Wall[] | []): Wall['imageAnnotations'] {
  const defaultAnnotations: Wall['imageAnnotations']  = [];

  for (const imageGroupAnnotation of imageGroupAnnotations) {
    if (imageGroupAnnotation.imageAnnotations?.length>0) {
      defaultAnnotations.push(...imageGroupAnnotation?.imageAnnotations)
    }
  }

  return defaultAnnotations;
}

export function setImageGroupAnnotationsInAnnotationComponentForSavingInDBIfModelTypeImageGroupAnnotation(
  imageAnnotations: Wall['imageAnnotations'], 
  imageGroupAnnotations: Wall[],
  imageScaleFactor: number
): Wall[] {
  imageGroupAnnotations = copyByValue(imageGroupAnnotations);
  imageAnnotations = copyByValue(imageAnnotations);

  for (let imageGroupAnnotationIndex = 0; imageGroupAnnotationIndex < imageGroupAnnotations.length; imageGroupAnnotationIndex++) {
    const imageGroupAnnotation = imageGroupAnnotations[imageGroupAnnotationIndex];
    imageGroupAnnotation.imageAnnotations = [];

    for (const imageAnnotation of imageAnnotations) {
      if (imageAnnotation.groupAnnotationId === imageGroupAnnotation.id) {
        if (imageAnnotation?.vertices?.length > 0) {
          for (const vertice of imageAnnotation?.vertices) {
              vertice.x = vertice.x / imageScaleFactor;
              vertice.y = vertice.y / imageScaleFactor;
          }
        }      
        // delete imageAnnotation.groupAnnotationId;
        imageGroupAnnotation.imageAnnotations.push(imageAnnotation);
      }
    }
  }

  return imageGroupAnnotations
}

export function setImageAnnotationsInAnnotationComponentForSavingInDBIfModelTypeImageAnnotation(
  imageAnnotations: ResourceImageAnnotation[], 
  imageScaleFactor: number
): ResourceImageAnnotation[] {
  imageAnnotations = copyByValue(imageAnnotations);

  for (const imageAnnotation of imageAnnotations) {
      if (imageAnnotation?.vertices?.length > 0) {
        for (const vertice of imageAnnotation?.vertices) {
            vertice.x = vertice.x / imageScaleFactor;
            vertice.y = vertice.y / imageScaleFactor;
        }
      }      
  }
  return imageAnnotations;
}