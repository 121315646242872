import { useState, useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { Button, Collapse, useMediaQuery } from '@material-ui/core';

const LargeScreenAlert = () => {
    var isLargeScreen: boolean = useMediaQuery('(min-width: 1200px)');  //returns true if w >= 1200px
    console.log(isLargeScreen)
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        setOpen(isLargeScreen)
    }, [isLargeScreen])


    return (
        <Collapse in={open} style={{ position: 'absolute', width: '100%' }}>
            <Alert
                severity='warning'
                action={
                    <Button onClick={() => setOpen(false)} color='inherit' size='small'>
                        <CloseIcon />
                    </Button>
                }
            >
                This app is designed for mobile and tablet devices; some features may be limited on larger screens.
            </Alert>
        </Collapse>
    )
}

export default LargeScreenAlert
