import { createContext, useState } from "react";
import { ImagesType, ImagesDBData, Scenarios, furnitureOptionsType } from "../../../types/image.type";

const iImagesContextState = {
    images: [],
    setImages: () => {},
    objectImages:[],
    furnitureOptions:"addingObject" as furnitureOptionsType,
    setFurnitureOptions:()=>{},
    originalImage:"",
    setOriginalImage:()=>{},
    setObjectImages:()=>{},
    selectedImage : '',
    setSelectedImage : () => {},
    scenario : "home" as Scenarios,
    setScanario: () => {},
}
const ImagesDBDataContext = createContext<ImagesDBData>(iImagesContextState);

export default ImagesDBDataContext;