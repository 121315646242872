import { Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Cancel } from "@material-ui/icons";
import { ImagesType, Wall } from '../../types/image.type';
import ImageService from '../../services/image.service';
import { TwoDimensionalImage } from '../../assets/components/react-annotation-tool/src/apps';
import { AnnotationComponentSaveClickData, getDefaultAnnotationsToMapInAnnotationComponentIfModelTypeImageGroupAnnotation, setResourceImageGroupAnnotationsForSendingToAnnotationComponent } from '../../services/imageAnnotationHelperService';

type Props = {
    // open: boolean,
    // onClose: ()=>any,
    imageUrlSelected: string,
    scenario : string,
    imageData : ImagesType,
    onSaveClick : (data : Wall[], selectedObjectUrl : string) => any
}

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    // position: "absolute",
    right: -10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: "2px",
    top: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    // color: "white",
    cursor: "pointer",
    width: "25px",
    height: "25px"
  },
}));

export default function ObjectAdvancedMode(props: Props) {
    const {imageUrlSelected, scenario, imageData} = props;

    const classes = useStyles();

    const [ imageWidthAndHeight , setImageWidthAndHeight ] = useState<{width : number , height : number}>({width : 0, height : 0})
    const imageContainerRef = useRef<HTMLDivElement>();

    const [ imageWidthToSet , setImageWidthToSet ] = useState<number>(0);
    const [ imageHeightToSet , setImageHeightToSet ] = useState<number>(0);

    // const [ imageAnnotations, setImageAnnotations ] = useState<ResourceImageGroupAnnotation[]>(annotationData)



  // const handleClose = () => {
  //   props.onClose();
  //   // setOptionSelectedForSelectingWall('Advanced_Mode');
  //   // setGroupAnnotations(scaleAnnotation(imageAnnotations , imageWidthAndHeight.width / (imageWidthToSet)))
  // };

  const calculateWidthAndheight = () => {
    let base_image = new Image();
    base_image.src = imageUrlSelected;

    base_image.onload = () => {
      const imageWidth = base_image.width || 0;
      const imageHeight = base_image.height || 0;

      console.log("width and height or original image", imageWidth, imageHeight);

      const imageAspectRatio = imageWidth / imageHeight;

      setImageWidthAndHeight({ width: imageWidth, height: imageHeight });

      let imageWidthToSet: number = 0;
      let imageHeightToSet: number = 0;
      //   const imageMaxWidthAllowed = 0.81*(window.innerWidth - 300);
      const imageMaxWidthAllowed = imageContainerRef.current?.offsetWidth || 0;
      //   const imageMaxHeightAllowed = 0.9*(window.innerHeight - 220);
      const imageMaxHeightAllowed = (imageContainerRef.current?.offsetHeight && (imageContainerRef.current?.offsetWidth  < 900 ? 0.6 * imageContainerRef.current?.offsetHeight :  imageContainerRef.current?.offsetHeight - 80)) || 0;

      if (imageWidth >= imageHeight) {
        imageWidthToSet = imageMaxWidthAllowed;
        imageHeightToSet = imageWidthToSet / imageAspectRatio;
        imageHeightToSet = ImageService.getImageHeight(
          imageWidthToSet,
          imageAspectRatio
        );

        if (imageHeightToSet > imageMaxHeightAllowed) {
          imageHeightToSet = imageMaxHeightAllowed;
          imageWidthToSet = ImageService.getImageWidth(
            imageHeightToSet,
            imageAspectRatio
          );
        }
      } else if (imageHeight > imageWidth) {
        imageHeightToSet = imageMaxHeightAllowed;
        imageWidthToSet = imageAspectRatio * imageHeightToSet;
        imageWidthToSet = ImageService.getImageWidth(
          imageHeightToSet,
          imageAspectRatio
        );

        // if (imageWidthToSet > 0.81*(window.innerWidth - 300)) {
        if (imageWidthToSet > imageMaxHeightAllowed) {
          imageWidthToSet = imageMaxWidthAllowed;
          imageHeightToSet = ImageService.getImageHeight(
            imageWidthToSet,
            imageAspectRatio
          );
        }
      }
      console.log('image width to set and image height to set :: ', imageWidthToSet, imageHeightToSet)
      // setImageWidthToSet(imageWidth);
      // setImageHeightToSet(imageHeight);
      setImageWidthToSet(imageWidthToSet);
      setImageHeightToSet(imageHeightToSet);
      // setImageAnnotations(scaleAnnotation(imageAnnotations ,  (imageWidthToSet)/imageWidth))
      
    };
    
    let new_base_image = new Image();
    new_base_image.src = imageData?.data?.newUrl || '';

    new_base_image.onload = () => {
      const imageWidth = base_image.width || 0;
      const imageHeight = base_image.height || 0;

      const imageAspectRatio = imageWidth / imageHeight;

      console.log("width and height or new image :: ", imageWidth, imageHeight);
    }
  } 
  
  const calculateWidthAndheightRef = useRef(calculateWidthAndheight);
  calculateWidthAndheightRef.current = calculateWidthAndheight;

  useEffect(()=>{
    calculateWidthAndheightRef.current();            
  }, [imageUrlSelected])


  const preventPullToRefresh = (e: TouchEvent) => {
    e.preventDefault();
  };


    useEffect(() => {
        // calculateWidthAndHeightRef.current()

        window.addEventListener('resize', calculateWidthAndheightRef.current, false)
        // calculateWidthAndHeightRef.current()

        // return  window.removeEventListener('resize', calculateWidthAndHeight)

        window.addEventListener('touchmove', preventPullToRefresh, { passive: false });
        return () => {
            // @ts-ignore
            window.removeEventListener('touchmove', preventPullToRefresh, { passive: true});
        };
    },[])  

  return (
    <React.Fragment>
      {/* <Dialog
        fullWidth={true}
        fullScreen
        maxWidth={"xl"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle 
          style={{
            paddingBottom: 0
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div>
              {"Advanced Mode"}
              </div>
            
            <div
                className={classes.closeBtn}
                onClick={() => {
                    props.onClose(); 
                    // setOptionSelectedForSelectingWall('Advanced_Mode')
                    // setGroupAnnotations(scaleAnnotation(imageAnnotations , imageWidthAndHeight.width / (imageWidthToSet)))
                }}
            >
                <Cancel style={{ fontSize: "40px", padding: "4px" }}
                  // onClick={()=>{props.onClose(); setOptionSelectedForSelectingWall('Advanced_Mode'); setGroupAnnotations(scaleAnnotation(imageAnnotations , imageWidthAndHeight.width / (imageWidthToSet)))}}
                  
                />
            </div> 

          </div>
          
          </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "0px"
          }}
        > */}
            <div className="imageAnnotationComponentContainer" 

              // @ts-ignore
              ref={imageContainerRef}
              style={{
                width: "100vw",
                height: "85vh",
                display:"flex",
                justifyContent:"center",
                alignItems:"center"
              }}
            >
                <TwoDimensionalImage 
                    url={props.imageUrlSelected}
                    viewMode={false}
                    isViewOnlyMode={false}
                    // width = {imageWidthToSet - 200}
                    // width = {props.imageWidthToSet - 200}
                    width = {imageWidthToSet}
                    defaultAnnotations={getDefaultAnnotationsToMapInAnnotationComponentIfModelTypeImageGroupAnnotation(setResourceImageGroupAnnotationsForSendingToAnnotationComponent(imageData?.data?.wall as Wall[] , imageWidthToSet/imageWidthAndHeight.width))}
                    defaultAnnotationGroups={setResourceImageGroupAnnotationsForSendingToAnnotationComponent(imageData.data?.wall as Wall[] , imageWidthToSet/imageWidthAndHeight.width)}
                    modelType={'imageAnnotationGroup'}
                    // defaultAnnotations={arrayToAnnotation(annotationData , (imageWidthToSet)/ imageWidthAndHeight.width)}
                    // defaultAnnotations={annotations}
                    onSaveClick={(data: AnnotationComponentSaveClickData) => {
                        console.log("data is  :: ",data, imageData.data?.wall)
                        let imageDataOfWall = imageData.data?.wall;
                        // imageDataOfWall.annotations
                        props.onSaveClick(data.imageGroupAnnotations, props.imageUrlSelected)
                        if(data.annotations){
                            console.log("data is  :: ",data.annotations)
                            
                            // const annotationArray = annotationToArray(data.imageGroupAnnotations, imageWidthAndHeight.width / (imageWidthToSet))
                            // const annotationArray = annotationToArray(data.annotations, imageWidthAndHeight.width / (imageWidthToSet))
                            // setAnnotationData(annotationArray)

                            // setAnnotations(data.annotations)
                            // // setGroupAnnotations(scaleAnnotation(data.imageGroupAnnotations, imageWidthAndHeight.width / (imageWidthToSet)))
                            // setGroupAnnotations(data.imageGroupAnnotations)
                            // console.log("data annotations are ::" , data.annotations," :: ", data.imageGroupAnnotations)
                            // setOptionSelectedForSelectingWall("Advanced_Mode")
                            // fetchNewWallImageRef.current(colorSelected, imageUrlSelected, choords, data.imageGroupAnnotations)
                            // props.onClose()
                        }   
                    }}
                    hasSaveButton={true}
                    scenario = {scenario}
                />                
            </div>
        {/* </DialogContent> */}
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions> */}
      {/* </Dialog> */}
    </React.Fragment>
  );

}