import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ImagesDBDataContext from '../Home/PaintMyWallDBData.context';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Chip, CircularProgress, Paper, Table, TableBody, TableCell, TableRow, Theme, Typography, makeStyles } from '@material-ui/core';
import ImageService from '../../../services/image.service';
import { TwoDimensionalImage } from '../../../assets/components/react-annotation-tool/src/apps';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RenameShape from './RenameShape.component';
import CancelIcon from '@material-ui/icons/Cancel';
import { AnnotationComponentSaveClickData, getDefaultAnnotationsToMapInAnnotationComponentIfModelTypeImageGroupAnnotation, setResourceImageAnnotationsForSendingToAnnotationComponent, setResourceImageGroupAnnotationsForSendingToAnnotationComponent } from '../../../services/imageAnnotationHelperService';
import TwoDimensionalImageContext from '../../../assets/components/react-annotation-tool/src/apps/two-dimensional-image/components/TwoDimensionalImage/twoDimensionalImageContext';


const useStyles = makeStyles((theme: Theme) => ({
  root : {
    width : '100%',
    height : '100%',
    display : 'flex',
  //   flexDirection : 'column',
    justifyContent : 'center',
    alignItems : 'center',
    flexWrap : 'wrap'
  },
  ImageArea : {
      height : '60%',
      width :'calc(100% - 80px)',
      // padding : '20px',
      // margin : '20px',
      display: 'flex',
      justifyContent : 'center',
      alignItems : 'center',
  },
  downSection : {
      height : '30%',
      width : '100%',
      position : 'relative'
  },
  paper: {
      height : 'calc(100% - 10px)',
      filter : 'drop-shadow(4px 4px 4px black)',
      borderRadius : '30px 30px 0px 0px',
      padding : '10px 2% 0 2%',
      overflow : 'auto',
      position : 'absolute',
      bottom : 0,
      left : 0,
      right : 0,
      width : '100%',
      transition : 'height 1s'
      // height : ''
  },
  heading: {
    fontSize: '18px',
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  container: {
      position: 'fixed',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      width: '100%',
      height: '100%',
      backdropFilter: 'blur(6px)',
      zIndex: 999999,
      background: 'rgb(0, 0, 0, 0.35) 0% 0% no-repeat padding-box',
      // -webkit-backdrop-filter: 'blur(20px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
  },
  objectNames : {
      display: 'flex',
      width: '100%',
      justifyContent : 'space-between',
      margin : '3px 0px'
  },
  renameShapeText : {
      margin : '5px 0px',
      fontSize : '11px',
      color : 'blue',
      textDecoration : 'underline'
  }
}))
function AdvancedModeEditing() {
  const classes = useStyles()
  const imageContext = useContext(ImagesDBDataContext);
  const [openRenameShapeDialog, setOpenRenameShapeDialog] = useState<{open : boolean, objectId?: string, wallId ?: string, shapeName ?: string}>({open : false});
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const [ imageWidthToSet , setImageWidthToSet ] = useState<number>(0);
  const [ imageHeightToSet , setImageHeightToSet ] = useState<number>(0);
  const [ imageWidthAndHeight , setImageWidthAndHeight ] = useState<{width : number , height : number}>({width : 0, height : 0})
  const [ selectedImageIndex, setSelectedImageIndex ] = useState<number>(0);
  const canvasElementRef = useRef<HTMLCanvasElement>();
  const imageContainerRef = useRef<HTMLDivElement>();
  const [ addNewWall, setAddNewWallStatus] = useState(false);

  const twoDimensionalImageContext = useContext(TwoDimensionalImageContext);
	// const {
	// 	entities,
	// 	annotations,
	// 	height,
	// 	focusedName,
	// 	scenario, 
	// 	isViewOnlyMode
	// } = twoDimensionalImageContext;

	// 	/** @type {import('../../../../../../../../common').IModelType} */
	// 	const modelType = twoDimensionalImageContext.modelType;	
    console.log('twoDimensionalImageContext is :: ', twoDimensionalImageContext)


  function calculateWidthAndHeight () {
    let base_image = new Image();
    base_image.src = imageContext.selectedImage;

    base_image.onload = () => {
        const imageWidth = base_image.width || 0;
      const imageHeight = base_image.height || 0;

      const imageAspectRatio = imageWidth / imageHeight;
      setImageWidthAndHeight({width: imageWidth, height: imageHeight})

      let imageWidthToSet: number = 0;
      let imageHeightToSet: number = 0;
      const imageMaxWidthAllowed = imageContainerRef.current?.offsetWidth || 0;
      // 0.81*(window.innerWidth - (window.innerWidth > 900 ?  200 : 50));
      const imageMaxHeightAllowed = (imageContainerRef.current?.offsetHeight && imageContainerRef.current?.offsetHeight ) || 0;
      // 0.81*(window.innerHeight - (window.innerWidth > 900 ? 300 : 150));
      console.log("max width allowed and max height allowed", imageMaxWidthAllowed, imageMaxHeightAllowed, imageWidth, imageHeight);

      if (imageWidth >= imageHeight) {
        imageWidthToSet = imageMaxWidthAllowed 
        imageHeightToSet = imageWidthToSet / imageAspectRatio;
        imageHeightToSet = ImageService.getImageHeight(
          imageWidthToSet,
          imageAspectRatio
        );

        if (imageHeightToSet > imageMaxHeightAllowed) {
          imageHeightToSet = imageMaxHeightAllowed;
          imageWidthToSet = ImageService.getImageWidth(
            imageHeightToSet,
            imageAspectRatio
          );
        }
      } else if (imageHeight > imageWidth) {
        imageHeightToSet = imageMaxHeightAllowed;
        imageWidthToSet = imageAspectRatio * imageHeightToSet;
        imageWidthToSet = ImageService.getImageWidth(
          imageHeightToSet,
          imageAspectRatio
        );

        if (imageWidthToSet > imageMaxHeightAllowed) {
          imageWidthToSet = imageMaxWidthAllowed; 
          imageHeightToSet = ImageService.getImageHeight(
            imageWidthToSet,
            imageAspectRatio
          );
        }
      }
      setImageWidthToSet(imageWidthToSet)
      setImageHeightToSet(imageHeightToSet)
    }
}
// executeFunction()
// },[selectedImageUrl])

const calculateWidthAndHeightRef = useRef(calculateWidthAndHeight);
calculateWidthAndHeightRef.current = calculateWidthAndHeight;

useEffect(() => {
    calculateWidthAndHeightRef.current()
},[imageContext.selectedImage])

useEffect(() => {
    window.addEventListener('resize', calculateWidthAndHeightRef.current, false)
},[])


const [scaledImageAnnotations, setScaledImageAnnotations] = useState<
any[]
>([]);
const [isImageAnnotationFullScreenMode, setIsImageAnnotationFullScreenMode] = useState(false)

const [isScrewImageAnnotationScalingGoingOn, setIsScrewImageAnnotationScalingGoingOn] = useState<boolean>(false)
const imageHeightToSetRef = useRef(imageHeightToSet);
const imageWidthToSetRef = useRef(imageWidthToSet);	
const editedAnnotationsRef = useRef<any[]>([]);
const isImageAnnotationExpandFeatureEnabled = false;

useEffect(()=>{
const executeFunction = async () => {
    setIsScrewImageAnnotationScalingGoingOn(true);

    const maxWidthAllowed = window.innerWidth-30;
    const maxHeightAllowed = window.innerHeight-250;

    let isShowingCompressedImageBecauseOriginalImageIsDownloading = false;
    let image: {
      width: number,
      height: number
    } | undefined = undefined;

    image = await ImageService.getImageWidthHeightFromURL( imageContext.selectedImage || "");

    const imageAspectRatio = image.width / image.height;
    console.log("imageAspectRatio", imageAspectRatio)

    let canvasHeightToSet = 0;
    let canvasWidthToSet = 0;

    if (image.width >= image.height)
    {
      canvasWidthToSet = maxWidthAllowed;
      canvasHeightToSet = canvasWidthToSet / imageAspectRatio;
      canvasHeightToSet = ImageService.getImageHeight(
        canvasWidthToSet,
        imageAspectRatio
      );

      if (canvasHeightToSet > maxHeightAllowed) {
        canvasHeightToSet = maxHeightAllowed;
        canvasWidthToSet = ImageService.getImageWidth(
          canvasHeightToSet,
          imageAspectRatio
        );
      }            
    } else if (image.height > image.width) {
      canvasHeightToSet = maxHeightAllowed;
      canvasWidthToSet = ImageService.getImageWidth(
        canvasHeightToSet,
        imageAspectRatio
      );

      if (canvasWidthToSet > maxWidthAllowed) {
        canvasWidthToSet = maxWidthAllowed;
        canvasHeightToSet = ImageService.getImageHeight(
          canvasWidthToSet,
          imageAspectRatio
        )
      }
    }

    // if(props.imageExpandFeatureEnabled){
    //   imageWidthToSetRef.current = canvasWidthToSet
    // }

    console.log("imageScaleFactor", image.width, canvasWidthToSet)
    let imageScaleFactor = ImageService.getImageScaleFactorForImageWidth(
        image.width,
        canvasWidthToSet
    );

    imageWidthToSetRef.current = canvasWidthToSet;
    console.log('executeFunction ~ canvasWidthToSet:', canvasWidthToSet)
    console.log('executeFunction ~ canvasHeightToSet:', canvasHeightToSet)


    let resourceImageAnnotations = (()=>{
      if (getDefaultAnnotationsToMapInAnnotationComponentIfModelTypeImageGroupAnnotation(imageContext.images[selectedImageIndex]?.data?.wall|| [])) {
        return [...getDefaultAnnotationsToMapInAnnotationComponentIfModelTypeImageGroupAnnotation(imageContext.images[selectedImageIndex]?.data?.wall|| [])];
      }
      return [];
    })();
    console.log("imageScaleFactor--->", imageScaleFactor)
    console.log("imageAnnotationsToSet--->", imageContext.images[selectedImageIndex])
    const imageAnnotationsToSet = 
      setResourceImageAnnotationsForSendingToAnnotationComponent(
          resourceImageAnnotations,
          imageScaleFactor
        )
    //  console.log("imageAnnotationsToSet--->", imageAnnotationsToSet)
        editedAnnotationsRef.current = [...imageAnnotationsToSet];

        setScaledImageAnnotations([...imageAnnotationsToSet])

    // @ts-ignore
    // setResource(oldState=>{return {
    //     ...oldState,
    //     imageGroupAnnotations: [...imageGroupAnnotationsToSet],
    //     imageAnnotations: [...imageAnnotationsToSet]
    // }})

    setIsScrewImageAnnotationScalingGoingOn(false);      
  }

  // setTimeout(() => {
    executeFunction();
  // }, 1000)
 

}, [imageContext.selectedImage, imageContext.images[selectedImageIndex]?.data?.wall])


  const handleParentAccordianChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    setTimeout(() => {
        calculateWidthAndHeightRef.current()
    }, 800);
};

useEffect(() => {
  imageContext.images.map((image, index) => {
      if(image.url === imageContext.selectedImage){
          setSelectedImageIndex(index)
      }
      return image
  })
},[])
  return (
    <div className={classes.root}>
        <>
        <div 
        //@ts-ignore
        ref={imageContainerRef}
        className={classes.ImageArea} style={{
          height : 
          '100%',
          // `calc(${'70'}% - 80px)`, 
          transition : 'height 2s'}}>
            {
                <TwoDimensionalImage 
                    url={imageContext.selectedImage}
                    viewMode={true}
                    // isViewOnlyMode={true}
                    // width = {imageWidthToSet - 200}
                    // width = {props.imageWidthToSet - 200}
                    width = {imageWidthToSetRef.current}
                   // height = {imageWidthToSet<=imageHeightToSet?0:imageHeightToSet}
                    defaultAnnotations={scaledImageAnnotations}
                    // defaultAnnotationGroups={scaleAnnotation(imageContext.images[selectedImageIndex || 0]?.data?.wall || [] ,  (imageWidthToSet) / imageWidthAndHeight.width)}
                    defaultAnnotationGroups={setResourceImageGroupAnnotationsForSendingToAnnotationComponent(imageContext.images[selectedImageIndex || 0]?.data?.wall, (0.7*imageWidthToSet -40) / imageWidthAndHeight.width)}
                    modelType={'imageAnnotationGroup'}
                    // defaultAnnotations={arrayToAnnotation(annotationData , (imageWidthToSet)/ imageWidthAndHeight.width)}
                    // defaultAnnotations={annotations}
                    onSaveClick={(data: AnnotationComponentSaveClickData) => {
                        console.log("data is  :: ",data, data.imageGroupAnnotations)
                        if(data.imageGroupAnnotations){
                            console.log("data is  :: ",data.imageGroupAnnotations)
                            let tempImages = imageContext.images.map((image, index) => {
                              if(image.url === imageContext.selectedImage){
                                image.data = {
                                  wall  : data.imageGroupAnnotations,
                                  newUrl : undefined
                                }
                              }
                              return image
                            });
                            imageContext.setImages(tempImages)
                            imageContext.setScanario('actions')
                            // const annotationArray = annotationToArray(data.imageGroupAnnotations, imageWidthAndHeight.width / (imageWidthToSet))
                            // const annotationArray = annotationToArray(data.annotations, imageWidthAndHeight.width / (imageWidthToSet))
                            // setAnnotationData(annotationArray)

                            // setAnnotations(data.annotations)
                            // // setGroupAnnotations(scaleAnnotation(data.imageGroupAnnotations, imageWidthAndHeight.width / (imageWidthToSet)))
                            // setGroupAnnotations(data.imageGroupAnnotations)
                            // console.log("data annotations are ::" , data.annotations," :: ", data.imageGroupAnnotations)
                            // setOptionSelectedForSelectingWall("Advanced_Mode")
                            // // fetchNewWallImageRef.current(colorSelected, imageUrlSelected, choords, data.imageGroupAnnotations)
                            // props.onClose()
                        }   
                    }}
                    hasSaveButton={false}
                    scenario = {'advancedMode'}
                    addNewWall={addNewWall}
                /> 
            }
            {/* <img alt="" src={imageContext.selectedImage || ""} width={imageWidthToSet} height ={imageHeightToSet} style={{borderRadius : '20px'}}/> */}
        </div>
        {/* <div className={classes.downSection} style={{height : '30%', transition : 'height 2s'}}>
            <Paper elevation={0} className={classes.paper} style={{height : '100%', transition : 'height 2s'}}>
                <div style={{height : 'calc(100% - 55px)', width : '100%', overflow: 'auto'}}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleParentAccordianChange('panel1')} style={{backgroundColor : expanded === 'panel1' ? '#f4f4f4' : '#ffffff', borderRadius :'10px'}}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    style={{margin : '0px'}}
                    >
                        <div style={{display : 'flex', flexDirection : 'column', margin : '0px'}}>
                            <Typography className={classes.heading}>Select Wall</Typography>
                        </div>
                    </AccordionSummary>
                            <hr style={{width : '100%' , color : '#959595'}}/>
                     <AccordionDetails>
                   <div style={{display : 'flex', justifyContent : 'space-evenly', width : '100%'}}>
                    <Button color='primary' variant='outlined' style={{cursor : 'pointer'}} onClick={() => setAddNewWallStatus(!addNewWall)}>Add New Wall / Object</Button>
                    <Button color='primary' variant='contained' style={{cursor : 'pointer'}}>Save And Colour</Button>
                   </div>
                    </AccordionDetails>
                </Accordion>

                </div>
            </Paper>
        </div> */}
        </>
        {openRenameShapeDialog.open && <RenameShape wallId={openRenameShapeDialog.wallId || ''} objectId={openRenameShapeDialog.objectId || ''} open={openRenameShapeDialog.open} onClose={() => setOpenRenameShapeDialog({open : false, wallId : undefined, objectId : undefined})} shapeName={openRenameShapeDialog.shapeName || ''}/>}
    </div>
  )
}

export default AdvancedModeEditing