import React , { Suspense } from 'react';
import logo from './logo.svg';
import './App.css';
import WallDecorHome from './components/home/WallDecorHome.component';
import HomeScreen from './components/PaintMyWall/Home/HomeScreen.component';
import ImageSuperimpositionPOC from './components/POC/ImageSuperimpositionPOC.component';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import LargeScreenAlert from './components/PaintMyWall/Home/LargeScreenAlert';

// import { ThemeProvider } from '@mui/material';

function App() {
  // let { path } = useRouteMatch();
  // console.log('path is  :: ', path)
    // return (
    //     // <Suspense fallback={""}>
    //         <Switch>
    //             <Route path='/' component={HomeScreen} exact/>
    //             {/* <Route path={`${path}/copilot`} component={Copilot}>
    //             </Route> */}
    //             <Route path='/poc' component={ImageSuperimpositionPOC}/>
    //         </Switch>
    //     // </Suspense>
    // )
  return (
    // <ThemeProvider theme={theme}>
      // <Routes>
      //   <Route path='/' element={<HomeScreen/>}/>
      //   <Route path='/reimagenationPOC' element={<ImageSuperimpositionPOC/>}/>
      //   {/* <Route path='/digitalTwin' element={<ImageToVideo/>}/> */}
      // </Routes>
      // <WallDecorHome />
      <>
        <LargeScreenAlert/>
        <HomeScreen />
      </>
      // <ImageSuperimpositionPOC />
    // </ThemeProvider>
  );
}

export default App;
