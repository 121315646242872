

export default class ImageService {
    static getImageHeight(imageWidth: number, imageAspectRatio: number) {
      return imageWidth / imageAspectRatio;
    }
    static getImageWidth(imageHeight: number, imageAspectRatio: number) {
      return imageHeight * imageAspectRatio;
    }
    static async getImageWidthHeightFromURL(
      imageUrl: string
    ): Promise<{ width: number; height: number }> {
      try {
        const imageWidthHeightPromise = new Promise<{
          width: number;
          height: number;
        }>((resolve, reject) => {
          try {
            const image = new Image();
            image.onload = () => {
              return resolve({
                width: image.width,
                height: image.height,
              });
            };
            image.src = imageUrl;
          } catch (error: any) {
            reject(new Error(error.message));
          }
        });
  
        const imageWidthHeight = await imageWidthHeightPromise;
        return imageWidthHeight;
      } catch (error: any) {
        throw new Error(error.message);
      }
    }
    static getImageScaleFactorForImageWidth(
      originalImageWidth: number,
      imageWidthWhichWeWantToSet: number
    ): number {
      const imageScaleFactor = imageWidthWhichWeWantToSet / originalImageWidth;
      return imageScaleFactor;
    }  
  
  }
  


export const decreaseImageResolution = async (imageFile:File)=>{
  return new Promise<File>((resolve,reject)=>{
    console.log(imageFile,"file console")
    const imageUrl= URL.createObjectURL(imageFile)
    const loadImage = new Image();
    loadImage.src = imageUrl;
    loadImage.onload = async () => {
      const canvas = document.createElement('canvas'); 
      const ctx = canvas.getContext('2d');
      canvas.width = loadImage.width;
      canvas.height = loadImage.height;
  
      // Draw the original image onto the canvas at the reduced dimensions
      ctx?.drawImage(loadImage, 0, 0, loadImage.width, loadImage.height);
  
      // Get the reduced resolution image as a data URL
      const reducedImageDataURL = canvas.toDataURL('image/jpeg', 0.7); // You can specify the desired image format and quality here
      console.log(reducedImageDataURL,"reduced image")
  
      const response = await fetch(reducedImageDataURL);
      const blob = await response.blob();
  
      // Create a File object with the Blob data
      const reducedImage=new File([blob], imageFile?.name, { type: blob.type })
      console.log(reducedImage)
      resolve(reducedImage);
    }

  })

}