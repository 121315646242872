import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'reactstrap';
import TwoDimensionalImageContext from '../TwoDimensionalImage/twoDimensionalImageContext';
// import OptionItem from './OptionItem/OptionItem.jsx';
// import 'bootstrap/dist/css/bootstrap.css';
import '../../../../../../../../assets/css/twoDimensionalImageBoostrap.css';
import './optionList.scss';

import { MenuItem, TextField} from "@material-ui/core";

const OptionList = ({
	className,
	annotationName,
	ancestorOptionIds,
	selectedOptions,
	level,
}) => {
	// const [value, setValue] = useState('');
	const twoDimensionalImageContext = useContext(TwoDimensionalImageContext);
	const {
		entities: { options, annotations },
		// isDynamicOptionsEnable,
		// onOptionCustomizedInputFocus,
		// onOptionCustomizedInputBlur,
		// onOptionCustomizedFormSubmit,
	} = twoDimensionalImageContext;

    console.log('optionsList options', options)

	const parentOptionId = ancestorOptionIds[ancestorOptionIds.length - 1];
	console.log('parentOptionId', parentOptionId)
	
	/*
	const itemListUI = options[parentOptionId].children.map((optionId) => {
		const childrenOptionIds = options[optionId].children;
		return (
			<OptionItem
				key={ optionId }
				level={ level }
				ancestorOptionIds={ [...ancestorOptionIds, optionId] }
				optionId={ optionId }
				childrenOptionIds={ childrenOptionIds }
				annotationName={ annotationName }
				selectedOptions={ selectedOptions }
			/>
		);
	});
	*/
	



	const SelectLabelDropdown = 
	React.memo(
		({
			annotationName,
			optionIds,
			options,
			annotations,
			twoDimensionalImageContext
		}) => {

			const {
			onOptionSelect,
			// onOptionCustomizedFormSubmit,
				isViewOnlyMode
			} = twoDimensionalImageContext;
	
			const [optionsArray, setOptionsArray] = useState([]);
			const [selectedValue, setSelectedValue] = useState("");
	
			useEffect(()=>{
				const optionsArrayToUpdate = [];
				for (let index = 0; index < Object.keys(options).length; index++) {
					
					//** @type {number} */
					const objectId = Object.keys(options)[index];
					
					// const object = options[index];
					const object = options[objectId];				
	
	
					if (object.id === '0') {
						continue;
					}
					optionsArrayToUpdate.push(object);
				}
				setOptionsArray(optionsArrayToUpdate);
			}, [options])
	
			useEffect(()=>{
				if (Object.keys(annotations).length>0) {
					for (let index = 0; index < Object.keys(annotations).length; index++) {
						const annotationObject = annotations[Object.keys(annotations)[index]];
						if (annotationObject.name === annotationName) {
							for (const selectedOption of annotationObject.selectedOptions) {
								if (selectedOption.id === "0") {
									continue;
								}
								setSelectedValue(selectedOption.id)
							}
						}
					}
				}
			}, [annotations, annotationName])
	
			return (
				<TextField
				id="standard-select-currency"
				select
				label="Select"
				value={selectedValue}
				disabled={isViewOnlyMode}
				onChange={(event)=>{
					const value = event.target.value;
	
					// const option = optionsArray.filter(option.id === value)[0];
					// console.log('option', option)
	
					// console.log('parentOptionId',parentOptionId); 
					// onOptionCustomizedFormSubmit(event, parentOptionId, value);
					onOptionSelect(annotationName, ["0", value])
				}}
				helperText="Please select annotation label"
			>
				{
				optionsArray
				.map((option) => (
					<MenuItem key={option.id} value={option.id}>
						{option.value}
					</MenuItem>
				))}
				
			</TextField>
			
			);
		}
	);

/*
	const customizedOptionUI = isDynamicOptionsEnable ? (
		<ListGroupItem key={ `customized-${parentOptionId}` } style={ { paddingLeft: 30 * level } }>
			<Form inline 
				onSubmit={ (e) => { 
				onOptionCustomizedFormSubmit(e, parentOptionId, value); 
				} }
			>
				<Input
					className='mr-sm-2'
					type='text'
					name={ parentOptionId }
					value={ value }
					onFocus={ onOptionCustomizedInputFocus }
					onBlur={ onOptionCustomizedInputBlur }
					onChange={ e => setValue(e.target.value) }
				/>
				<Input type='submit' value='Submit' className='my-2 my-sm-0' />
			</Form>
		</ListGroupItem>
	) : null;
*/
	const rootClassName = `option-list${className ? ` ${className}` : ''}`;
	return (
		<ListGroup className={ rootClassName }>
			{/* { itemListUI }  */}
			 {/* { customizedOptionUI } */}
			 {/* {
				 options &&
				 <SelectLabelDropdown 
					 annotationName={annotationName}
					 optionIds={[...ancestorOptionIds, parentOptionId]}
					 options={options}
					 annotations={annotations}
					 twoDimensionalImageContext={twoDimensionalImageContext}
				 /> 
			 } */}
		</ListGroup>
	);
};

OptionList.propTypes = {
	className: PropTypes.string,
	annotationName: PropTypes.string,
	level: PropTypes.number,
	ancestorOptionIds: PropTypes.arrayOf(PropTypes.string),
	selectedOptions: PropTypes.arrayOf(PropTypes.object),
};
OptionList.defaultProps = {
	className: '',
	annotationName: '',
	level: 1,
	ancestorOptionIds: [],
	selectedOptions: [],
};
export default React.memo(OptionList);
