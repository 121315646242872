import axios, { AxiosInstance, AxiosResponse } from 'axios';

const BASE_URL = process.env.REACT_APP_API;

export class API {
    api: AxiosInstance;
    constructor() {
        let headers = {
            'Content-type': 'application/json; charset=utf-8'
        };
        this.api = axios.create({
            baseURL: BASE_URL,
            headers: headers,
            withCredentials: false
        });
    }
}
