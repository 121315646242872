import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, ListGroupItem } from 'reactstrap';
import { MdDelete } from 'react-icons/md';
import TwoDimensionalImageContext from '../../TwoDimensionalImage/twoDimensionalImageContext';
import OptionList from '../../OptionList/OptionList.jsx';
import './annotationItem.scss';

const AnnotationItem = ({
	className,
	itemData,
}) => {
//   console.log('itemData', itemData)
	const twoDimensionalImageContext = useContext(TwoDimensionalImageContext);
	const {
		focusedName,
		onAnnotationClick,
		onAnnotationDeleteClick,
		rootOptionId,
		isViewOnlyMode
	} = twoDimensionalImageContext;
	const {
		name,
		displayName,
		groupAnnotationId,
		selectedOptions,
		color,
		isClosed,
	} = itemData;
	const { t } = useTranslation('twoDimensionalImage');

	
	useEffect(() => {
		
		if (focusedName) {
			try {
				function isInViewport(el) {
					const rect = el.getBoundingClientRect();
					return (
						rect.top >= 0.1*(window.innerHeight || document.documentElement.clientHeight) &&
						rect.left >= 0 &&
						rect.bottom <= (0.95*(window.innerHeight || document.documentElement.clientHeight))-80 &&
						rect.right <= 0.9*(window.innerWidth || document.documentElement.clientWidth)
				
					);
				}
				const box = document.querySelector(`.annotation-item--highlight`);
				const isInViewportBool = isInViewport(box);
				// console.log("is in viewport : ", isInViewportBool);
				const element = document.getElementById(focusedName);
				if(element && !isInViewportBool){
					// element.scrollIntoView({ block: 'center', inline: 'nearest', behavior: "smooth" });
					// element.scrollIntoViewIfNeeded({centerIfNeeded : true, behavior: "smooth" });
				}
				
			} catch (error) {
				console.warn(error.message)	
			}
		}
	}, [focusedName]);

	let rootClassName = `annotation-item${className ? ` ${className}` : ''}`;
	if (name !== focusedName) {
		return (
			<ListGroupItem className={ rootClassName } id = {name} name={ name } onClick={ () => onAnnotationClick(name) } action>
				<div className='d-flex w-100 justify-content-between align-items-center'>
					<div>
						{/* {selectedOptions.length > 0 ? 
						`${selectedOptions[selectedOptions.length - 1].value}` 
						: 
						t('optionNotSelected') 
						} */}
						{displayName}
						{/* <small className='pl-1' style={ { color: '#545454' } }><mark>{
							//isClosed ? 
							'polygon' 
							//: 'line'
							}</mark></small> */}
					</div>
				</div>
			</ListGroupItem>
		);
	}

	rootClassName = `${rootClassName} annotation-item--highlight`;
	return (
		<ListGroupItem className={ rootClassName } id = {name} name={ name } style={ { borderColor: color.replace(/,1\)/, ',.3)') } }>
			<div className='d-flex align-items-center'>
				<h5 className='annotation-item__title mr-auto' onClick={ () => onAnnotationClick(name) }>
					{displayName.length > 0 ? displayName : t('optionNotSelected') }
					{/* <small className='pl-1' style={ { color: '#545454' } }><mark>{
							//isClosed ? 
							'polygon' 
							//: 'line'
							}</mark></small> */}
				</h5>
				{/* <Button color='primary'>
					Change Name
				</Button> */}
				{
					!isViewOnlyMode &&
					<Button className='d-flex align-items-center annotation-item__delete-button' color='link' onClick={ () => { onAnnotationDeleteClick(name, groupAnnotationId); } }>
						<MdDelete />
					</Button>
				}
			</div>
			{/* <OptionList annotationName={ name } ancestorOptionIds={ [rootOptionId] } selectedOptions={ selectedOptions } /> */}
		</ListGroupItem>
	);
};

AnnotationItem.propTypes = {
	className: PropTypes.string,
    itemData: PropTypes.object,
};
AnnotationItem.defaultProps = {
	className: '',
	itemData: {},
};
export default AnnotationItem;
