// @ts-check

import React, { useRef, useState, useContext, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
	Stage, Layer, Rect, Group, Line, Text, Circle, Image, Label, Tag
} from 'react-konva';
import TwoDimensionalImageContext from '../TwoDimensionalImage/twoDimensionalImageContext';
import './canvas.scss';


const SCALE_LIMIT_FOR_LOW_QUALITY_IMAGE = 8;
const magnifierPower=2;
const magnifierWidth=32;
const magnifierHeight=52;

const CONST = {
	// DOT_LENGTH: 5,
	MAGNIFIER_LENGTH: 200,
};

const handleMouseLeave = (isAdding) => {
	// document.body.style.cursor = isAdding ? 'crosshair' : 'default';
	document.body.style.cursor = 'default';
};

const handleMouseOut = (isAdding) => {
	// document.body.style.cursor = isAdding ? 'crosshair' : 'default';
	document.body.style.cursor = 'default';
	console.log("mouse out")
	
};

const handleMouseOver = (isAdding) => {
	if (isAdding) return;
	document.body.style.cursor = 'pointer';
};

const handleFirstVertexMouseOver = () => {
	document.body.style.cursor = 'cell';
};

const handleVertexMouseOver = () => {
	document.body.style.cursor = 'move';
};

const handleVertexDragMove = (e,setCursorPosition,updateVertexPosition,magnifierPosition,setMagnifierPosition,width,magnifierWindowSize) => {

    if(updateVertexPosition){
		const stage = e.target.getStage();
        const position = stage.getPointerPosition();
		if(position.x+(magnifierWindowSize.width)>=magnifierPosition.x && position.y+(magnifierWindowSize.height)>=magnifierPosition.y && magnifierPosition.position==="topRight"){
		    	setMagnifierPosition((prev)=>{
					const updatedPosition=5+magnifierWindowSize.width
					if(prev.x!==updatedPosition){
						return {...prev,x:updatedPosition,position:"topLeft"}
					}
				})
		}

		if(position.x<=magnifierPosition.x+magnifierWindowSize.width && position.y+(magnifierWindowSize.height)>=magnifierPosition.y && magnifierPosition.position==="topLeft"){
			setMagnifierPosition((prev)=>{
				const updatedPosition=(width-magnifierWindowSize.width)-5;
				if(prev.x!==updatedPosition){
					return {...prev,x:updatedPosition,position:"topRight"}
				}
			})
		}
	    setCursorPosition({ x: position.x, y: position.y });
		updateVertexPosition(e)
	}
	
	// if (isAdding) return;
	// document.body.style.cursor = 'move';
	// const activeVertex = e.target;
	// const group = activeVertex.getParent();
	// const annotationId = Object.keys(entities.annotations)[0]
	// // console.log("group is  :: ",group)
	// const line = group.get ? group.get('Line')[0] : null;
	// const linePoints = [];
	// // console.log("entities.annotations[group.name()].vertices",  entities.annotations[group.name()], entities.annotations, entities, entities.annotations.id)
	// // entities.annotations[annotationId].vertices.forEach((v) => {
	// entities.annotations[group.name()].vertices.forEach((v) => {
	// 	if (v.name !== activeVertex.name()) {
	// 		linePoints.push(v.x); linePoints.push(v.y);
	// 		return;
	// 	}
	// 	linePoints.push(activeVertex.x()); linePoints.push(activeVertex.y());
	// });
	// // if(entities.annotations.vertices){
	// // for(let i = 0; i < entities.annotations.vertices.length; i++) {
	// // 	console.log("vertices are ::: ", entities.annotations.vertices[i])
	// // 	if (entities.annotations.vertices[i].name !== activeVertex.name()) {
	// // 		linePoints.push(entities.annotations.vertices[i].x); linePoints.push(entities.annotations.vertices[i].y);
	// // 		return;
	// // 	}
	// // 	linePoints.push(activeVertex.x()); linePoints.push(activeVertex.y());
	// // }}
	// if (line) {
	// 	line.points(linePoints);
	// }
};

export const annotationcanvasId = "imageAnnotationCanvas";
const Canvas = ({
	className,
}) => {
	const imgRef = useRef(null);
	const stageRef = useRef(null);
	const layerRef = useRef(null);

	const downloadingHighResolutionImageStageRef = useRef(null);

	if (layerRef.current) {
		/**
		 * for showing image in pixel view when zoomed a lot
		 */
		layerRef.current.getContext()._context.imageSmoothingEnabled = false
		layerRef.current.getContext()._context.mozImageSmoothingEnabled = false;
		layerRef.current.getContext()._context.webkitImageSmoothingEnabled = false;
		layerRef.current.getContext()._context.msImageSmoothingEnabled = false;
	}

	const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
	const [magnifierPosition, setMagnifierPosition] = useState({position:"topRight",x:0,y:0})
	const [magnifierWindowSize, setmagnifierWindowSize] = useState({width:32,height:54});
	const twoDimensionalImageContext = useContext(TwoDimensionalImageContext);
	const {
		url,
		width,
		settingManuallyCanvasWidth,
		height,
		annotations,
		entities,
		magnifyingPower,
		isLabelOn,
		isAdding,
		focusedName,
		onCanvasImgLoad: onImgLoad,
		onCanvasStageMouseDown: onStageMouseDown,
		onCanvasVertexMouseDown: onVertexMouseDown,
		onCanvasVertexDragEnd: onVertexDragEnd,
		onCanvasLabelMouseDown: onLabelMouseDown,
		onCanvasLineMouseDown: onLineMouseDown,
		updateCanvasContextMenuState,
		toggleShowMagnifier,
		isViewOnlyMode,
		stageScale,
		setStageScale,
		isShowingCompressedImageBecauseOriginalImageIsDownloading,
		isZoomFeatureEnabled,
		scenario,
		setMouseAnnotationCoordinates,
		imageScaleFactor,
		originalImageWidth,
		originalImageHeight,
		naturalWidth,
		naturalHeight,
		isFullScreenMode,
		maxImageHeightAllowed,
		isShowMangnifier,
		updateVertexPosition
	} = twoDimensionalImageContext;

	const areAllItemsAvailableToCenterImage = (() => {
		if (stageRef.current && width) {
			return true
		}
		return false;
	})();

	// console.log("width and height coming to canvas are :: ", width, height);

	const centerAlignImage = useCallback(
	  () => {
		  if (areAllItemsAvailableToCenterImage && stageRef.current) {
			  const stageWidth = stageRef.current.width();
			  const imageWidth = width;
			  const stageNewXAxis = (stageWidth / 2) - (imageWidth / 2);
			  if (stageNewXAxis && stageNewXAxis>=0) {
				  stageRef.current.setAttr("x", stageNewXAxis);
				} else {
				  stageRef.current.setAttr("x", 0);
			  }
		  }
	  },
	  [areAllItemsAvailableToCenterImage, width, stageRef.current],
	);
	const centerAlignImageRef = useRef(centerAlignImage);
	centerAlignImageRef.current = centerAlignImage;

	useEffect(() => {
		if (areAllItemsAvailableToCenterImage) {
			centerAlignImageRef.current();				
		}
	}, [areAllItemsAvailableToCenterImage, width])
	
		const dotLength = 8/stageScale;
		console.log(dotLength, stageScale,"dotlength and stageScale")

	function get_polygon_centroid(pts) {
		if(pts.length){
			const x0 = pts[0].x , y0 = pts[0].y;

			let x = 0, y = 0, twiceArea = 0;

			let prev = pts[pts.length - 1];
			for (const next of pts)
			{
				const x1 = prev.x - x0, y1 = prev.y - y0,
					x2 = next.x - x0, y2 = next.y - y0,
					a  = x1 * y2 - x2 * y1;

				twiceArea += a;
				x += (x1 + x2) * a;
				y += (y1 + y2) * a;

				prev = next;
			}

			const factor = 3 * twiceArea;  // 6 * twiceArea/2
			x /= factor;
			y /= factor;

			return { x: (x + x0) | 0, y: (y + y0) | 0 };
		}
		return {}
	}

    useEffect(() => {
       setMagnifierPosition({...magnifierPosition,x:(width-magnifierWindowSize.width)-5,y:magnifierWindowSize.height+5})
	}, [width,height])
	
	// useEffect(()=>{
    //   if(width>height){
    //       setmagnifierWindowSize({width:28,height:48})
	//   }else{
    //       setmagnifierWindowSize({width:25,height:48})
	//   }
	// },[])
	
	const annotationsUI = annotations.map((annotationId) => {
		const { color, name, displayName, selectedOptions, isClosed, vertices } = entities.annotations[annotationId];
		// console.log(annotationId, entities)
		const colorWithOpacity = color.replace(/,1\)/, ',.15)');

		const verticesUI = [];
		const linePoints = [];
		let startPoint = {};

		const circleRadius = (()=>{
			if (stageScale > 200) {
				return 0.02
			}
			if (stageScale > 15) {
				return 0.05
			}
			return dotLength * 1.1;
		})();

		const circleStrokeWidth = (() => {
			if (stageScale > 200) {
				return 0.2
			}
			if (stageScale > 89) {
				return 0.5
			}
			return 1
		})();

		const verticesPoints = vertices.map((point, i) => {
				const x = point.x | 0
				const y = point.y | 0
				return {x, y}
			})
		if(verticesPoints){
			startPoint = get_polygon_centroid(verticesPoints)
		}
		// console.log("vertex points are :: ",verticesPoints, startPoint);

		vertices.forEach((v, i) => {
			// if (i === 1) {
			// 	startPoint.x = verticesPoints.x; startPoint.y = verticesPoints.y;
			// }
			if (isAdding && focusedName === name && i === 0) {
				verticesUI.push(
					<Circle
						x={ v.x }
						y={ v.y }
						key={ v.name }
						name={ v.name }
						radius={ circleRadius }
						// radius={ 0.01 }
						stroke={ color }
						fill={ colorWithOpacity }
						strokeWidth={ circleStrokeWidth }
						draggable= { true}
						dragOnTop={ false }
						onMouseDown={ onVertexMouseDown }
						onMouseOver={ handleFirstVertexMouseOver }
						onPointerDown={ onVertexMouseDown }
						onPointerOver={ handleFirstVertexMouseOver }
						// onMouseOut={ () => handleMouseOut(isAdding) }
						onFocus={ () => {} }
						onBlur={ () => {} }
						onMouseEnter={e => {
							// style stage container:
							const container = e.target?.getStage()?.container();
							container && (container.style.cursor = "crosshair");
							
						}}
						onMouseLeave={e => {
							const container = e.target.getStage()?.container();
							container && (container.style.cursor = "default");
						}}
						onPointerEnter={e => {
							// style stage container:
							const container = e.target?.getStage()?.container();
							container && (container.style.cursor = "crosshair");
							
						}}
						onPointerLeave={e => {
							const container = e.target.getStage()?.container();
							container && (container.style.cursor = "default");
						}}
					/>,
				);
			} else {
				verticesUI.push(
					<Circle
						// offsetX={ dotLength / 2 }
						// offsetY={ dotLength / 2 }
						x={ v.x }
						y={ v.y }
						key={ v.name }
						name={ v.name }
						stroke={ color }
						fill={ color }
						strokeWidth={ 0 }
						width={ dotLength+2 }
						height={ dotLength+2 }
						draggable= {true}
						dragOnTop={ false }
						onMouseDown={ onVertexMouseDown }
						onMouseOver={ handleVertexMouseOver }
						onPointerDown={ onVertexMouseDown }
						onPointerOver={ handleVertexMouseOver }
						onMouseOut={ () => handleMouseOut(isAdding) }
						onDragEnd={ onVertexDragEnd }
						onDragMove={ e => handleVertexDragMove(e,setCursorPosition,updateVertexPosition,magnifierPosition,setMagnifierPosition,width,magnifierWindowSize) }
						onFocus={ () => {} }
						onBlur={ () => {} }
						onMouseEnter={e => {
							// style stage container:
							const container = e.target?.getStage()?.container();
							container && (container.style.cursor = "crosshair");
						}}
						onMouseLeave={e => {
							const container = e.target?.getStage()?.container();
							container && (container.style.cursor = "default");
                            // const touch = e?.evt?.touches[0];
							// const { clientX, clientY } = touch;
							// const timer = setTimeout(() => {
							// 	updateCanvasContextMenuState({
							// 	isOpen: true,
							// 	xCoordinate: clientX - 2,
							// 	yCoordinate: clientY - 4,
							// 	event: e
							//     }) 
							// // setContextMenuVisible(true);
							// // setContextMenuPosition({ x: clientX, y: clientY });
							// }, 800);    // Adjust the duration as needed for long tap recognition

							// const handleTouchEnd = () => {
							// clearTimeout(timer);
							// };
                            // document.addEventListener('touchend', handleTouchEnd, { once: true });
						}}
						onPointerEnter={e => {
							// style stage container:
							const container = e.target?.getStage()?.container();
							container && (container.style.cursor = "crosshair");
						}}
						onPointerLeave={e => {
							const container = e.target?.getStage()?.container();
							container && (container.style.cursor = "default");
						}}
						onContextMenu={(e)=>{

							if (isViewOnlyMode || isShowMangnifier) {
								return;
							}
                              
							updateCanvasContextMenuState({
								isOpen: true,
								xCoordinate: e.evt.clientX - 2,
								yCoordinate: e.evt.clientY - 4,
								event: e
							})
						}}
						onTouchStart={(event) => {
							event.evt.preventDefault();
							const touch = event.evt.touches[0];
							const { clientX, clientY } = touch;
							if(isShowMangnifier || !isViewOnlyMode)
							    return ;

							// const timer = setTimeout(() => {
							// 	updateCanvasContextMenuState({
							// 	isOpen: true,
							// 	xCoordinate: clientX - 2,
							// 	yCoordinate: clientY - 4,
							// 	event: event
							//     }) 
							// // setContextMenuVisible(true);
							// // setContextMenuPosition({ x: clientX, y: clientY });
							// }, 800);    // Adjust the duration as needed for long tap recognition

							// const handleTouchEnd = () => {
							// clearTimeout(timer);
							// };
                            // document.addEventListener('touchend', handleTouchEnd, { once: true });
							
						}}
						// contextMenu={(e)=>{

						// 	if (isViewOnlyMode) {
						// 		return;
						// 	}

						// 	updateCanvasContextMenuState({
						// 		isOpen: true,
						// 		xCoordinate: e.evt.clientX - 2,
						// 		yCoordinate: e.evt.clientY - 4,
						// 		event: e
						// 	})
						// }}
					/>,
				);
			}
			linePoints.push(v.x); linePoints.push(v.y);
		});

		const labelUI = isLabelOn ? (
			<Label
				offsetY={ 10 }
				x={ startPoint.x }
				y={ startPoint.y }
				onMouseDown={ onLabelMouseDown }
				onMouseOver={ () => handleMouseOver(isAdding) }
				// onMouseLeave={ () => handleMouseLeave(isAdding) }
				// onMouseOut={ () => handleMouseOut(isAdding) }
				onFocus={ () => {} }
				onBlur={ () => {} }
			>
				<Tag
					// name={ name }
					name = { displayName.length > 0 ? displayName : 'Not selected' }
					fill='#000'
					opacity={ 0.5 }
					pointerDirection='left'
					pointerWidth={5 }
					pointerHeight={ 5 }
					lineJoin='round'
					cornerRadius={ 7 }
				/>
				<Text
					name={ name }
					padding={ 5 }
					fontFamily='Calibri'
					text={ displayName.length > 0 ? displayName : 'Not selected' }
					fontSize={ 16 }
					lineHeight={ 1.2 }
					fill='#fff'
				/>
			</Label>
		) : null;
		const lineUI = (
			<Line
				name={ name }
				points={ linePoints }
				closed={ true }
				// closed={ isClosed }
				fill={ focusedName === name ? colorWithOpacity : '' }
				stroke={ color }
				// strokeWidth={ 1 }
				strokeWidth={ 1/stageScale }
				lineCap='round'
				lineJoin='round'
				onMouseDown={ onLineMouseDown }
				onMouseOver={ () => handleMouseOver(isAdding) }
				onMouseLeave={ () => handleMouseLeave(isAdding) }
				onMouseOut={ () => handleMouseOut(isAdding) }
				onPointerDown={ onLineMouseDown }
				onPointerOver={ () => handleMouseOver(isAdding) }
				onPointerLeave={ () => handleMouseLeave(isAdding) }
				onPointerOut={ () => handleMouseOut(isAdding) }
				onFocus={ () => {} }
				onBlur={ () => {} }
			/>
		);
		return (
			<Group key={ name } name={ name }>
				{lineUI}
				{verticesUI}
				{labelUI}
			</Group>
		);
	});

	const normalizedLength = CONST.MAGNIFIER_LENGTH / magnifierPower;
	const rootClassName = `canvas${className ? ` ${className}` : ''}`;

	function zoomStage(event) {

		if (!isZoomFeatureEnabled) {
			return;
		}

		// const scaleBy = 1.01;
		event.evt.preventDefault();
		if (stageRef.current !== null) {
		  const stage = stageRef.current;
		  const oldScale = stage.scaleX();
		  const scaleBy = (()=>{
			  if (oldScale < 10) {
				  /**
				   * since in the beginning, zooming happens slowly, so kept scaleBy to a larger value
				   * in the beginning
				   */
				  return 1.1;
			  } else {
				  return 1.01
			  }
		  })();
		  const { x: pointerX, y: pointerY } = stage.getPointerPosition();
		  const mousePointTo = {
			x: (pointerX - stage.x()) / oldScale,
			y: (pointerY - stage.y()) / oldScale,
		  };
		  const newScale = event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

		//   stage.scale({ x: newScale, y: newScale });
		  setStageScale(newScale);
		  const newPos = {
			x: pointerX - mousePointTo.x * newScale,
			y: pointerY - mousePointTo.y * newScale,
		  }
		  stage.position(newPos);
		  stage.batchDraw();
		}
	  }
	useEffect(() => {
	  if (stageScale === 1 || scenario === "coPilotActivePageReadOnlyAnnotation") {
		if (stageRef.current) {

			/**
			 * Resetting the position to initial position which was when stage
			 * scale was at 1
			 */

			if (stageScale !== 1) {
				setStageScale(1);
			}

			stageRef.current.position(1, 1);
			centerAlignImageRef.current()
			stageRef.current.batchDraw();
		}
	  }
	}, [stageScale, scenario])

	const hasUserReachedStageScaleLimitForLowQualityImage = (()=>{
		if (isShowingCompressedImageBecauseOriginalImageIsDownloading && stageScale > SCALE_LIMIT_FOR_LOW_QUALITY_IMAGE) {
			return true;
		}
		return false;
	})();

	const stageWidth = (()=>{
		return settingManuallyCanvasWidth && settingManuallyCanvasWidth > 0 ? settingManuallyCanvasWidth : width
	})();
	
	// console.log('width in canvas is :: ', width, imageScaleFactor)
	return (
		<div className={ rootClassName } id={annotationcanvasId}>
			<img
				className='canvas__img'
				ref={ imgRef }
				key={width}
				width={ width }
				style={ { visibility: 'hidden' } }
				onLoad={ onImgLoad }
				src={ url }
				alt=''
			/>

			{
				hasUserReachedStageScaleLimitForLowQualityImage &&
				<Stage
					ref={downloadingHighResolutionImageStageRef}
					className='konva-wrapper'
					width={ stageWidth }
					height={height}
					onMouseEnter={()=>{
						downloadingHighResolutionImageStageRef.current.container().style.cursor="pointer"
					}}
					onMouseLeave={()=>{
						downloadingHighResolutionImageStageRef.current.container().style.cursor="default"
					}}	
					onPointerEnter={()=>{
						downloadingHighResolutionImageStageRef.current.container().style.cursor="pointer"
					}}
					onPointerLeave={()=>{
						downloadingHighResolutionImageStageRef.current.container().style.cursor="default"
					}}					
					onClick={()=>{
						setStageScale(1)
					}}
					>
					<Layer
						fill="green"
					>
						<Text
						width={width}
						height={height}
						shadowColor="green"
							text="Downloading high resolution image..."
							// x={1}
							// y={1}
							// offsetX={1}
							// offsetY={1}
							fontSize={20}
							fill="red"
							// padding={20}
							align="center"
							verticalAlign="middle"
						/>
					</Layer>

				</Stage>
			}

			{
				!hasUserReachedStageScaleLimitForLowQualityImage &&
				<Stage
					className='konva-wrapper'
					width={ stageWidth }
					height={ height }
					onMouseDown={ onStageMouseDown }
					onMouseMove={ (e) => {
						const stage = e.target.getStage();
						const position = stage.getPointerPosition();
						setCursorPosition({ x: position.x, y: position.y });
						let { x: relativeX, y: relativeY } = stage.getRelativePointerPosition();

						let xAxis = relativeX/imageScaleFactor;
						
						if (isShowingCompressedImageBecauseOriginalImageIsDownloading) {
							xAxis = (xAxis)*(originalImageWidth/naturalWidth)
						}

						if (xAxis < 0) {
							xAxis = 0
						}
						
						let yAxis = relativeY/imageScaleFactor;

						if (isShowingCompressedImageBecauseOriginalImageIsDownloading) {
							yAxis = yAxis*(originalImageHeight/naturalHeight)
						}

						if (yAxis < 0) {
							yAxis = 0
						}
						
						setMouseAnnotationCoordinates( xAxis, yAxis);
					} }
					onPointerDown={ onStageMouseDown }
					onPointerMove={ (e) => {
						const stage = e.target.getStage();
						const position = stage.getPointerPosition();
						setCursorPosition({ x: position.x, y: position.y });
					} }
					onFocus={ () => {} }
					draggable={isZoomFeatureEnabled ? true: false}
					onWheel={zoomStage}
					ref={stageRef}
					scaleX={stageScale}
					scaleY={stageScale}
				>
					<Layer ref={layerRef}>
						<Image cornerRadius={10} image={ imgRef.current } width={ width } height={ height } style={{borderRadius : '10px'}}/>
						{annotationsUI}
					</Layer>
					{!isViewOnlyMode &&isShowMangnifier && (
						<Layer>
							<Group>
								<Rect
									x={ magnifierPosition.x }
									y={ magnifierPosition.y }
									offsetX={ magnifierWindowSize.width * magnifierPower / 2 }
									offsetY={ magnifierWindowSize.height * magnifierPower / 2 }
									width={ magnifierWindowSize.width*magnifierPower}
									height={ magnifierWindowSize.height*magnifierPower }
									stroke='#DAD3CF'
									strokeWidth={ 3 }
								/>
								<Group
									x={ magnifierPosition.x}
									y={ magnifierPosition.y}
									offsetX={ cursorPosition.x }
									offsetY={ cursorPosition.y }
									clipX={ (cursorPosition.x - magnifierWindowSize.width/ 2)}
									clipY={ (cursorPosition.y - magnifierWindowSize.height / 2)}
									clipWidth={magnifierWindowSize.width }
									clipHeight={magnifierWindowSize.height}
									scaleX={ magnifierPower}
									scaleY={ magnifierPower }
								>
									<Image image={ imgRef.current } width={ width } height={ height } />
									{annotationsUI}
								</Group>
							</Group>
						</Layer>
					)}
				</Stage>
			}
			

		</div>
	);
};

Canvas.propTypes = {
	className: PropTypes.string,
};
Canvas.defaultProps = {
	className: '',
};

export default Canvas;
